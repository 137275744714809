import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LogoutIcon from "@mui/icons-material/Logout";
import LuggageIcon from "@mui/icons-material/Luggage";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import {
  Backdrop,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme
} from "@mui/material";
import { signOut } from "aws-amplify/auth";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  setChannel,
  setLogin,
  setSelectedAddress
} from "../../state/index";
import Language from "../Language/Language";
import "./sidebar.css";

function NavCatg() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const user: any = useSelector((state: any) => state.user);
  const selectedConcept = useSelector((state: any) => state.selectedConcept);
  const channel = useSelector((state: any) => state.channel);
  const concept = useSelector((state: any) => state.concept);
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const sidebarRef = useRef(null);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  const handleLogOut = async () => {
    if (!user) {
      return;
    }
    dispatch(setLogin({ user: null }));
    // updateOfflineCart([] , 0);
    dispatch(setSelectedAddress(null));
    localStorage.removeItem("user");
    await signOut();
    if (user.group === "Cognito") {
      navigate("/login");
    }
  };

  const sidebarRef = useRef<HTMLDivElement>(null);

  const handleChannelChange = (event: any) => {
    dispatch(setChannel(event.target.value));
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    }

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Grid
      container
      justifyContent="space-between"
      ref={sidebarRef}
      sx={{
        direction: i18n.language === "Arabic" ? "rtl" : "ltr",
        overflow: "auto",
        backgroundColor: theme.palette.background.default,
        padding: "0.5rem",
        width: "100%",
        position: "sticky",
        top: "3.5rem",
        zIndex: 199,
      }}
    >
      <Box>
        <IconButton
          onClick={toggleMenu}
          sx={{
            color: theme.palette.primary.main,
            boxShadow: "none",
            transition: "box-shadow 0.3s",
            fontSize: "25px",
          }}
        >
          <MenuIcon />
        </IconButton>
        <Backdrop
          open={isMenuOpen}
          onClick={closeMenu}
          sx={{
            zIndex: 1200,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            className={`side-menu ${isMenuOpen ? "open" : ""} ${
              i18n.language === "Arabic" ? "ar" : ""
            }`}
            sx={{
              backgroundColor: theme.palette.background.default,
              direction: i18n.language === "Arabic" ? "rtl" : "ltr",
            }}
          >
            <Box
              display="flex"
              justifyContent="flex-end"
              sx={{ mt: "10px", mx: "10px" }}
            >
              <IconButton
                onClick={closeMenu}
                className="close-btn"
                sx={{
                  color: theme.palette.primary.main,
                  boxShadow: "none",
                  transition: "box-shadow 0.3s",
                  fontSize: "25px",
                  "&:hover": {
                    boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                    backgroundColor: "#F07EAA",
                  },
                }}
              >
                <ArrowBackIosNewIcon
                  sx={{
                    color: "#33BDE8",
                    fontSize: "1rem",
                    transform:
                      i18n.language === "Arabic" ? "rotate(180deg)" : "none",
                  }}
                />
              </IconButton>
            </Box>
            {/* /profile */}
            <Box display="flex" flexDirection="column" width={"100%"}>
              <Link
                to={user === null ? "/login" : "/profile"}
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 20,
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                    fontFamily: `bold${i18n.language}`,
                  }}
                >
                  <AccountCircleIcon
                    className={`icons ${
                      i18n.language === "Arabic" ? "ar" : ""
                    }`}
                  />
                  {t("profile")}
                </Typography>
              </Link>
            </Box>
            <Divider
              sx={{
                width: "100%",
                borderWidth: "0.1rem",
                backgroundColor: "#9B9B9B",
              }}
            />

            {/* /address */}
            <Box width={"100%"}>
              <Link
                to={user === "" ? "" : "/address"}
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 20,
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                    fontFamily: `bold${i18n.language}`,
                  }}
                >
                  <LocationOnIcon
                    className={`icons ${
                      i18n.language === "Arabic" ? "ar" : ""
                    }`}
                    sx={{ color: "#33BDE8" }}
                  />
                  {t("address")}
                </Typography>
              </Link>
            </Box>
            <Divider
              sx={{
                width: "100%",
                borderWidth: "0.1rem",
                backgroundColor: "#9B9B9B",
              }}
            />

            {/* /orders */}
            <Box width={"100%"}>
              <Link
                to="/orders"
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 20,
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                    fontFamily: `bold${i18n.language}`,
                  }}
                >
                  <LuggageIcon
                    className={`icons ${
                      i18n.language === "Arabic" ? "ar" : ""
                    }`}
                  />
                  {t("orders")}
                </Typography>
              </Link>
            </Box>

            {user && (
              <>
                <Divider
                  sx={{
                    width: "100%",
                    borderWidth: "0.1rem",
                    backgroundColor: "#9B9B9B",
                  }}
                />

                {/* /loyalty */}
                <Box width={"100%"}>
                  <Link
                    to="/rewards"
                    style={{
                      textDecoration: "none",
                      color: theme.palette.primary.light,
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: 20,
                        padding: "1rem",
                        display: "flex",
                        alignItems: "center",
                        fontFamily: `bold${i18n.language}`,
                      }}
                    >
                      <RedeemOutlinedIcon
                        className={`icons ${
                          i18n.language === "Arabic" ? "ar" : ""
                        }`}
                      />
                      {t("Rewards")}
                    </Typography>
                  </Link>
                </Box>
              </>
            )}

            <Divider
              sx={{
                width: "100%",
                borderWidth: "0.1rem",
                backgroundColor: "#9B9B9B",
              }}
            />
            {/* /Menu */}
            <Box width={"100%"}>
              <Link
                to="/menu"
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 20,
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                    fontFamily: `bold${i18n.language}`,
                  }}
                >
                  <MenuOpenIcon
                    className={`icons ${
                      i18n.language === "Arabic" ? "ar" : ""
                    }`}
                    style={{
                      color: "#33BDE8",
                    }}
                  />
                  {t("menu")}
                </Typography>
              </Link>
            </Box>

            <Divider
              sx={{
                width: "100%",
                borderWidth: "0.1rem",
                backgroundColor: "#9B9B9B",
              }}
            />
            {/* <Box width={"100%"}>
              <Link
                to="/about"
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 20,
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                    fontFamily: `bold${i18n.language}`
                  }}
                >
                  <GroupsIcon  style={{
                      color: "#F07EAA",
                      marginRight: "0.5rem",
                      width: "24px",
                      height: "24px",
                    }}></GroupsIcon>
                   {t("about")}
                   
                </Typography>
              </Link>
            </Box> */}

            {/* <Divider
              sx={{
                width: "100%",
                borderWidth: "0.1rem",
                backgroundColor: "#9B9B9B",
              }}
            /> */}

            {/* /contact */}
            {/* <Box width={"100%"}>
              <Link
                to="/contact"
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.light,
                  cursor: "pointer",
                }}
              >
                <Typography
                  style={{
                    color: theme.palette.primary.main,
                    fontSize: 20,
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                    fontFamily: `bold${i18n.language}`
                  }}
                >

                  <SettingsPhoneIcon style={{
                    marginRight: "0.5rem",
                    width: "24px",
                    height: "24px",
                    color: "#33BDE8",
                  }} />
                      {t("contact")}
           
                </Typography>
              </Link>
            </Box> */}

            {/* /about */}
            {/* 
            <Divider
                  sx={{
                    width: "100%",
                    borderWidth: "0.1rem",
                    backgroundColor: "#9B9B9B",
                  }}
                /> */}

            <Language />

            {user && (
              <>
                <Divider
                  sx={{
                    width: "100%",
                    borderWidth: "0.1rem",
                    backgroundColor: "#9B9B9B",
                  }}
                />

                <Box
                  onClick={() => {
                    handleLogOut();
                  }}
                  width={"100%"}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      fontSize: 20,
                      padding: "1rem",
                      display: "flex",
                      alignItems: "center",
                      fontFamily: `bold${i18n.language}`,
                    }}
                  >
                    <LogoutIcon
                      className={`icons ${
                        i18n.language === "Arabic" ? "ar" : ""
                      }`}
                      sx={{
                        color: "#33BDE8",
                      }}
                    />
                    {t("logout")}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Backdrop>
      </Box>

      {/* <Grid item sx={{ minWidth: '100%' }}>
    <FormControl focused={false} fullWidth>
    <InputLabel style={{color:theme.palette.primary.main}} id="demo-simple-select-label">{t("channel")}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      label="Channel"
      value={channel}      
      onChange={handleChannelChange}      
      sx={{
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.dark}`,
        '& .MuiSelect-icon': {
          color: theme.palette.primary.main
        },
      }}
    MenuProps={{
        PaperProps: {
          style: {
            backgroundColor: theme.palette.primary.dark, 
          },
        },
      }}
    >
            <MenuItem value={'delivery'}>{t("delivery")}</MenuItem>
            <MenuItem value={'pick-up'}>{t("Pick-up")}</MenuItem>
    </Select>
  </FormControl>
</Grid> */}

      <Grid item>
        <Link
          to="/"
          style={{ textDecoration: "none", color: "white", cursor: "pointer" }}
        >
          <IconButton
            sx={{
              color: theme.palette.primary.main,
              boxShadow: "none",
              transition: "box-shadow 0.3s",
              fontSize: "25px",
              "&:hover": {
                boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                backgroundColor: "#F07EAA",
              },
            }}
          >
            <img
              src="assets/favicon.png "
              style={{ width: "3rem", height: "3rem" }}
            />
          </IconButton>
        </Link>
      </Grid>
      <Grid item>
        {user && (
          <Link
            to="/rewards"
            style={{
              textDecoration: "none",
              color: theme.palette.primary.light,
              cursor: "pointer",
            }}
          >
            <RedeemOutlinedIcon
              style={{
                marginRight: "0.5rem",
                marginTop: "0.5rem",
                width: "24px",
                height: "24px",
                color: "#F07EAA",
              }}
            />
          </Link>
        )}
      </Grid>

      {/* <Grid item>
        <Link
          to="/notifications"
          style={{ textDecoration: "none", color: "white", cursor: "pointer" }}
        >
          <IconButton
            sx={{
              visibility:"hidden",
              color: theme.palette.primary.main,
              boxShadow: "none",
              transition: "box-shadow 0.3s",
              fontSize: "25px",
              "&:hover": {
                boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                backgroundColor: "#F07EAA",
              },
            }}
          >
            <NotificationsIcon />
          </IconButton>
        </Link>
      </Grid> */}
    </Grid>
  );
}

export default NavCatg;
