import { EndPoints, LambdaFunctions } from "../constants/Enums";
import invokeLambda from "./invokeLambda";

async function validatePromocode(
  promocodeName: string,
  userID: string,
  orderValue: number,
  subTaxTotal: number,
  deliveryFee: number,
  orderItemsQty: number,
  tender: string,
  channelName: string
) {
  try {
    const operationId = 19;

    const requestBody = {
      userID,
      promocodeName,
      orderValue,
      orderValueBeforeTax:subTaxTotal,
      deliveryFee,
      orderItemsQty,
      tender,
      channelName,
      operationId,
    };
    return await invokeLambda(
      LambdaFunctions.callCenterOperations,
      requestBody
    );
  } catch (error) {
    console.error("Error validating promocode :", error);
    throw error;
  }
}

async function applyPromocode(
  promocodeName: string,
  userID: string,
  orderValue: number,
  subTaxTotal: number,
  deliveryFee: number,
  orderItemsQty: number,
  tender: string,
  channelName: string
) {
  try {
    const operationId = 20;

    const requestBody = {
      userID,
      promocodeName,
      orderValue,
      orderValueBeforeTax:subTaxTotal,
      deliveryFee,
      orderItemsQty,
      tender,
      channelName,
      operationId,
    };
    return await invokeLambda(
      LambdaFunctions.callCenterOperations,
      requestBody
    );
  } catch (error) {
    console.error("Error applying promocode :", error);
    throw error;
  }
}

export { validatePromocode, applyPromocode };
