import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {
  SignUpInput,
  fetchUserAttributes,
  getCurrentUser,
  signIn,
  signUp,
} from "aws-amplify/auth";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import getUser from "../../services/getUser";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { LoadingButton } from "@mui/lab";
import { isValidEnglish } from "../../constants/helperFunction";
import withAuth from "../../HOC/WithAuth";
import createUser from "../../services/createUser";
import { getUserByPhoneNumber } from "../../services/getOperation";
import { sendOtpViaSMS } from "../../services/sendOTP";
import updateNewUser from "../../services/updateNewUser";
import {
  calculateMaxDate,
  calculateMinDate,
} from "../../services/userPageUtils";
import { setLogin } from "../../state";
import OTP from "../Modals/OTP";

type RegisterForm = {
  name: string;
  phoneNumber: string;
  birthDate: string;
  email: string;
  password: string;
  confirmPassword: string;
};

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    control,
  } = useForm<RegisterForm>({});
  const [validationWarning, setValidationWarning] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<any>("");
  const [checkCode, setCheckCode] = useState<any>(null);
  const [openOTP, setOpenOTP] = useState<boolean>(false);
  const [data, setDate] = useState<any>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [nameLastInput, setNameLastInput] = useState("");
  const [mobileNumberLastInput, setMobileNumberLastInput] = useState("");
  const [birthDateLastInput, setBirthDateLastInput] = useState("");
  const [emailLastInput, setEmailLastInput] = useState("");

  const handleInputBlur = (fieldName: any) => {
    trigger(fieldName);
  };

  const handleInput = (fieldName: keyof RegisterForm, value: any) => {
    trigger(fieldName);
    setValue(fieldName, value);
  };

  const handlePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const onSubmit = async (data: RegisterForm) => {
    try {
      if (data.password !== data.confirmPassword) {
        setMessage(t("Passwords do not match"));
        setValidationWarning(true);
        return;
      }
      const currentTime = new Date().getTime();
      const lastLocalOtp = localStorage.getItem("lastOtp");
      if (lastLocalOtp) {
        const timeDifference = currentTime - Number(lastLocalOtp);
        if (timeDifference < 30000) {
          setValidationWarning(true);
          setMessage(
            t("Please wait before trying again", {
              time: Math.floor((30000 - timeDifference) / 1000).toString(),
            })
          );
          return;
        }
      }
      setLoading(true);
      setDate(data);

      //prevent user from sending OTP to a registered phone number
      let currentUser: any = await getUserByPhoneNumber(data.phoneNumber);
      if (currentUser.items.length > 0) {
        currentUser = currentUser.items[0];
        if (currentUser.status === "registered") {
          setMessage(t("User with this phone number already exist"));
          setValidationWarning(true);
          setLoading(false);
          return;
        }
      }
      const { instanceId, checkCode } = await sendOtpViaSMS(data.phoneNumber);
      localStorage.setItem("lastOtp", currentTime.toString());
      setOpenOTP(true);
      setCheckCode(checkCode);

      //skipping OTP for testing
      // await handleSuccessfulOTP();
      // setLoading(false);
    } catch (err) {
      console.log("err");
      setMessage(t("Something went wrong"));
      setValidationWarning(true);
      setLoading(false);
    }
  };

  const handleSuccessfulOTP = async () => {
    try {
      setLoading(true);
      let currentUser: any = await getUserByPhoneNumber(data.phoneNumber);
      currentUser = currentUser.items[0];
      if (currentUser) {
        if (!currentUser.status) {
          let input: SignUpInput = {
            username: "+2" + data.phoneNumber,
            password: data.password,
            options: {
              userAttributes: {
                email: data.email,
                name: data.name,
              },
              autoSignIn: true,
            },
          };
          const user = await signUp(input);

          await signIn(input);
          const loggedInUser = await fetchUserAttributes();
          currentUser.group = "Cognito";
          dispatch(setLogin({ user: currentUser }));
          let newUser = await updateNewUser({
            userID: currentUser.id,
            version: currentUser._version,
            email: data.email,
            name: data.name,
            phone_number: currentUser.phone_number,
            address: currentUser.address,
            status: "registered",
            birthdate: data.birthdate,
            gender: currentUser.gender,
            picture: currentUser.picture,
            pictureCrop: currentUser.pictureCrop,
            phones: currentUser.phones,
            deleted: currentUser.deleted,
            createdAt: currentUser.createdAt,
            email_verified: true,
            phone_number_verified: true,
            resource: currentUser,
            sub: loggedInUser.sub,
          });
          newUser.group = "Cognito";
          localStorage.setItem("user", "true");
          dispatch(setLogin({ user: newUser }));
          navigate("/");
        } else {
          setMessage(t("User with this phone number already exist"));
          setValidationWarning(true);
          return;
        }
      } else {
        let input: SignUpInput = {
          username: "+2" + data.phoneNumber,
          password: data.password,
          options: {
            userAttributes: {
              email: data.email,
              name: data.name,
            },
            autoSignIn: true,
          },
        };
        const user = await signUp(input);

        await signIn(input);
        // const loggedInUser = await fetchUserAttributes();
        const [loggedInUser, userDetails] = await Promise.all([
          fetchUserAttributes(),
          getCurrentUser(),
        ]);
        let newUser = await createUser(
          { ...loggedInUser, birthdate: data.birthDate },
          "Cognito",
          "+2" + data.phoneNumber,
          true,
          true,
          {
            cognitoUsername: userDetails.username,
            updated: "false",
          }
        );
        localStorage.setItem("user", "true");
        dispatch(setLogin({ user: newUser }));
        navigate("/");
      }
    } catch (error: any) {
      setMessage(error.message);
      setValidationWarning(true);
    } finally {
      setLoading(false);
    }
  };
  console.log({ errors });
  return (
    <>
      <OTP
        open={openOTP}
        phoneNumber={data?.phoneNumber}
        checkCode={checkCode}
        handleSuccessfulOTP={handleSuccessfulOTP}
        key={checkCode}
        onClose={() => {
          setOpenOTP(false);
        }}
      ></OTP>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-end"
          sx={{
            backgroundColor: "#1f1c2b",
            padding: "1rem",
            marginTop: "3rem",
          }}
        >
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={validationWarning}
            autoHideDuration={3000}
            onClose={() => {
              setValidationWarning(false);
            }}
          >
            <Alert
              onClose={() => {
                setValidationWarning(false);
              }}
              severity="warning"
              sx={{
                position: "fixed",
                top: "16px",
                right: "16px",
              }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => {
                    setValidationWarning(false);
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              {message}
            </Alert>
          </Snackbar>
          <Link to="/" replace>
            <Button
              sx={{
                position: "fixed",
                top: "16px",
                [i18n.language === "Arabic" ? "right" : "left"]: "16px",
                backgroundColor: "rgba(169, 169, 169, 0.55)",
                color: "rgba(64, 64, 64, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: " 2rem",
                height: "2rem",
                borderRadius: "0.5rem",
                marginBottom: ".5rem",
                "&:hover": {
                  backgroundColor: "#33bde8",
                },
              }}
            >
              <ArrowBackIosNewIcon
                sx={{
                  color: "#33BDE8",
                  fontSize: "1rem",
                  transform:
                    i18n.language === "Arabic" ? "rotate(180deg)" : "none",
                  zIndex: 9999, // Set a high value for z-index to ensure it's on top
                }}
              />
            </Button>
          </Link>
          <Typography
            style={{
              fontSize: 30,
              fontWeight: 700,
              fontFamily: `bold${i18n.language}`,
              color: "white",
            }}
          >
            {t("SIGNUP")}
          </Typography>
          <Grid
            item
            sm={12}
            xs={12}
            sx={{
              margin: "1rem auto",
            }}
          >
            <TextField
              variant="outlined"
              placeholder={t("Name")}
              label={t("Name")}
              fullWidth
              {...register("name", {
                required: t("NameRequired"),
                minLength: {
                  value: 3,
                  message: t("Name3characters"),
                },
              })}
              onChange={(e) => {
                const isValid = isValidEnglish(e.target.value);
                if (isValid === true) {
                  handleInput("name", e.target.value);
                  setNameLastInput(e.target.value);
                  setMessage("");
                  setValidationWarning(false);
                } else {
                  e.preventDefault();
                  handleInput("name", nameLastInput);
                  setValidationWarning(true);
                  setMessage(t("Only English letters and numbers are allowed"));
                }
              }}
              onBlur={() => handleInputBlur("name")}
              InputProps={{
                sx: {
                  backgroundColor: "rgb(255,255,255,0.7)",
                  color: "black",
                  borderRadius: "1rem",
                  width: "100%",
                  fontFamily: `regular${i18n.language}`,
                  borderColor: "#ffffff",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontFamily: `regular${i18n.language}`,
                  color: "white",
                  mb: 2,
                },
              }}
            />
            {errors.name && (
              <span style={{ color: "red", fontSize: 12 }}>
                {errors.name.message}
              </span>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            sx={{
              margin: "1rem auto",
            }}
          >
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: t("PhonRequired"),
                pattern: {
                  value: /^[0-9]{11}$/,
                  message: t("InvalidPhoneFormat"),
                },
              }}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  placeholder={t("phoneNumber")}
                  label={t("phoneNumber")}
                  type="number"
                  fullWidth
                  onChange={(e) => {
                    const isValid = isValidEnglish(e.target.value);
                    if (isValid === true) {
                      field.onChange(e.target.value);
                      setMobileNumberLastInput(e.target.value);
                      setMessage("");
                      setValidationWarning(false);
                    } else {
                      e.preventDefault();
                      field.onChange(mobileNumberLastInput);
                      setValidationWarning(true);
                      setMessage(
                        t("Only English letters and numbers are allowed")
                      );
                    }
                  }}
                  onBlur={() => handleInputBlur("phoneNumber")}
                  InputProps={{
                    sx: {
                      backgroundColor: "rgb(255,255,255,0.7)",
                      color: "black",
                      borderRadius: "1rem",
                      width: "100%",
                      fontFamily: `regular${i18n.language}`,
                      borderColor: "#ffffff",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: `regular${i18n.language}`,
                      color: "white",
                    },
                  }}
                />
              )}
            />

            {errors.phoneNumber && (
              <span style={{ color: "red", fontSize: 12 }}>
                {errors.phoneNumber.message}
              </span>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            sx={{
              margin: "1rem auto",
            }}
          >
            <Controller
              name="birthDate"
              control={control}
              rules={{
                required: t("BirthRequired"),
              }}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  placeholder={t("BirthDate")}
                  label={t("BirthDate")}
                  // defaultValue={new Date().toISOString().split('T')[0] }
                  defaultValue={""}
                  fullWidth
                  type={"date"}
                  onChange={(e) => {
                    const isValid = isValidEnglish(e.target.value);
                    if (isValid === true) {
                      field.onChange(e);
                      setBirthDateLastInput(e.target.value);
                      setMessage("");
                      setValidationWarning(false);
                    } else {
                      e.preventDefault();
                      field.onChange(birthDateLastInput);
                      setValidationWarning(true);
                      setMessage(
                        t("Only English letters and numbers are allowed")
                      );
                    }
                  }}
                  onBlur={() => handleInputBlur("phobirthDateneNumber")}
                  inputProps={{
                    min: calculateMinDate(),
                    max: calculateMaxDate(),
                  }}
                  InputProps={{
                    sx: {
                      backgroundColor: "rgb(255,255,255,0.7)",
                      color: "black",
                      borderRadius: "1rem",
                      width: "100%",
                      fontFamily: `regular${i18n.language}`,
                      borderColor: "#ffffff",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: `regular${i18n.language}`,
                      color: "white",
                    },
                    shrink: true,
                  }}
                />
              )}
            />
            {errors.birthDate && (
              <span style={{ color: "red", fontSize: 12 }}>
                {errors.birthDate.message}
              </span>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            sx={{
              margin: "1rem auto",
            }}
          >
            <Controller
              name="email"
              control={control}
              rules={{
                required: t("emailRequired"),
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/,
                  message: t("InvalidFormat"),
                },
              }}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  placeholder={t("Email")}
                  label={t("Email")}
                  fullWidth
                  type="text"
                  onChange={(e) => {
                    const isValid = isValidEnglish(e.target.value);
                    if (isValid === true) {
                      field.onChange(e.target.value);
                      setEmailLastInput(e.target.value);
                      setMessage("");
                      setValidationWarning(false);
                    } else {
                      e.preventDefault();
                      field.onChange(emailLastInput);
                      setValidationWarning(true);
                      setMessage(
                        t("Only English letters and numbers are allowed")
                      );
                    }
                  }}
                  onBlur={field.onBlur}
                  InputProps={{
                    sx: {
                      backgroundColor: "rgb(255,255,255,0.7)",
                      color: "black",
                      borderRadius: "1rem",
                      width: "100%",
                      fontFamily: `regular${i18n.language}`,
                      borderColor: "#ffffff",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: `regular${i18n.language}`,
                      color: "white",
                    },
                  }}
                />
              )}
            />
            {errors.email && (
              <span style={{ color: "red", fontSize: 12 }}>
                {errors.email.message}
              </span>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            sx={{
              margin: "1rem auto",
            }}
          >
            <TextField
              variant="outlined"
              placeholder={t("password")}
              label={t("password")}
              fullWidth
              type={showPassword ? "text" : "password"}
              //  onBlur={() => handleInputBlur("password")}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handlePasswordVisibility}>
                      {!showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: "rgb(255,255,255,0.7)",
                  color: "black",
                  borderRadius: "1rem",
                  width: "100%",
                  fontFamily: `regular${i18n.language}`,
                  borderColor: "#ffffff",
                },
              }}
              {...register("password", {
                required: t("PasswordRequired"),
                minLength: {
                  value: 8,
                  message: t("Password8characters"),
                },
              })}
              InputLabelProps={{
                sx: {
                  fontFamily: `regular${i18n.language}`,
                  color: "white",
                },
              }}
            />
            {errors.password && (
              <span style={{ color: "red", fontSize: 12 }}>
                {errors.password.message}
              </span>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            sx={{
              margin: "1rem auto",
            }}
          >
            <TextField
              variant="outlined"
              placeholder={t("confirmPassword")}
              label={t("confirmPassword")}
              fullWidth
              type={showConfirmPassword ? "text" : "password"}
              //   onBlur={() => handleInputBlur("confirmPassword")}

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleConfirmPasswordVisibility}>
                      {!showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: "rgb(255,255,255,0.7)",
                  color: "black",
                  borderRadius: "1rem",
                  width: "100%",
                  fontFamily: `regular${i18n.language}`,
                  borderColor: "#ffffff",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontFamily: `regular${i18n.language}`,
                  color: "white",
                },
              }}
              {...register("confirmPassword", {
                required: t("confirmRequired"),
                minLength: {
                  value: 8,
                  message: t("Password8characters"),
                },
              })}
            />
            {errors.confirmPassword && (
              <span style={{ color: "red", fontSize: 12 }}>
                {errors.confirmPassword.message}
              </span>
            )}
          </Grid>
          <Grid
            container
            item
            justifyContent="center"
            sm={12}
            xs={12}
            sx={{ marginY: "1rem" }}
          >
            <LoadingButton
              variant="contained"
              type="submit"
              loading={loading}
              sx={{
                font: "1rem",
                width: "100%",
                color: "#FFFFFF",
                background:
                  "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
                boxShadow: "none",
                transition: "box-shadow 0.3s",
                borderRadius: "1rem",
                padding: "1rem",
                fontFamily: `bold${i18n.language}`,
                fontWeight: 600,
                "&:hover": {
                  boxShadow: "0px 0px 5px 2px rgb(240,126,165,0.7)",
                  background:
                    "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
                },
              }}
            >
              {t("REGISTER")}
            </LoadingButton>

            <Typography
              style={{
                color: "white",
                marginTop: "0.5rem",
                fontFamily: `regular${i18n.language}`,
              }}
            >
              {t("AlreadyAccount?")}
              <Link
                to="/login"
                style={{
                  textDecoration: "none",
                  color: "#F07EAA",
                  marginRight: ".5rem",
                  marginLeft: ".5rem",
                }}
              >
                {t("LoginNow")}
              </Link>
            </Typography>
          </Grid>
        </Grid>
        {/* {error && <p style={{ color: 'red' }}>{error}</p>} Display error message */}
      </form>
    </>
  );
}

export default withAuth(Register);
