import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getAvailableConcepts,
  getNearestConcept,
} from "../../services/conceptUtils";
import { setSelectedAddress } from "../../state";

function AdressPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user);
  const selectedAddress = useSelector((state: any) => state.selectedAddress);
  const [prevSlug, setPrevSlug] = useState("/");
  const themeMode = useSelector((state: any) => state.mode);
  const Concept = useSelector((state: any) => state.concept);
  const zonesListing = useSelector((state: any) => state.zonesListing);

  const [hasAddress, setHasAddress] = useState(false);

  useEffect(() => {
    if (user) return;
    navigate("/");
  }, []);

  useEffect(() => {
    if (selectedAddress !== false) {
      if (user.address) {
        const userAddressesAll = JSON.parse(user.address);

        const userAddresses = userAddressesAll.filter((address: any) => {
          // Find the corresponding zone for the address
          const zone = zonesListing.find(
            (zone: any) => zone?.id === address?.zoneID
          );

          // If the zone exists and has zoneConcepts, find the available concepts
          if (zone?.concepts?.length) {
            const availableConcepts = getAvailableConcepts(Concept.listing);
            const nearestConcept = getNearestConcept(
              zone.concepts,
              availableConcepts
            );

            // Return true if there is a matching concept in the zone
            return nearestConcept !== undefined;
          }
          return false;
        });

        const defaultAddress = userAddresses.find((address: any) => {
          return address.default === true;
        });
        dispatch(
          setSelectedAddress({ address: defaultAddress, index: undefined })
        );
      } else {
        dispatch(setSelectedAddress(null));
      }
    }
    if (user?.address) {
      setHasAddress(true);
    } else {
      setHasAddress(false);
    }
  }, [user]);

  useEffect(() => {
    if (location.state?.prevSlug) {
      setPrevSlug(location.state.prevSlug);
    }
  }, []);

  return (
    <Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: "1rem" }}
      >
        <Grid item sm={12} lg={12}>
          <Link to={prevSlug}>
            <Button
              sx={{
                backgroundColor: "rgba(169, 169, 169, 0.55)",
                color: "rgba(64, 64, 64, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: " 2rem",
                height: "2rem",
                borderRadius: "0.5rem",
                marginBottom: "1rem",
              }}
            >
              <ArrowBackIosNewIcon
                sx={{
                  color: "#33BDE8",
                  fontSize: "1rem",
                  transform:
                    i18n.language === "Arabic" ? "rotate(180deg)" : "none",
                }}
              />
            </Button>
          </Link>
        </Grid>
        <Grid
          container
          item
          sm={12}
          lg={12}
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontSize: 24,
              fontWeight: "600",
              letterSpacing: 0.96,
            }}
          >
            {t("Address")}
          </Typography>

          {user && (
            <Link
              to="/newAddress"
              state={{ prevSlug }}
              style={{ textDecoration: "none", color: "#33BDE8", fontSize: 12 }}
              onClick={() => {
                dispatch(setSelectedAddress(null));
              }}
            >
              {t("AddNew")}
            </Link>
          )}
        </Grid>

        <Grid item xs={12} sm={12}>
          {user ? (
            hasAddress ? (
              JSON.parse(user.address)
                .filter((address) => {
                  // Find the corresponding zone for the address
                  const zone = zonesListing.find(
                    (zone: any) => zone?.id === address?.zoneID
                  );

                  // If the zone exists and has zoneConcepts, find the available concepts
                  if (zone?.concepts?.length) {
                    const availableConcepts = getAvailableConcepts(
                      Concept.listing
                    );
                    const nearestConcept = getNearestConcept(
                      zone.concepts,
                      availableConcepts
                    );

                    // Return true if there is a matching concept in the zone
                    return nearestConcept !== undefined;
                  }
                  return false;
                })
                .map((address: any, index: any) => {
                  return (
                    <Grid
                      container
                      item
                      alignItems="center"
                      sm={12}
                      xs={12}
                      sx={{ marginY: "2rem" }}
                      key={index}
                    >
                      <Grid item sm={2} xs={2}>
                        <IconButton
                          sx={{
                            backgroundColor:
                              themeMode === "dark"
                                ? "#393744"
                                : theme.palette.primary.light,
                          }}
                        >
                          <LocationOnIcon
                            sx={{ color: "gray" }}
                          ></LocationOnIcon>
                        </IconButton>
                      </Grid>
                      <Grid item sm={8} xs={8}>
                        <Box>
                          <Typography
                            sx={{
                              color: theme.palette.primary.main,
                              fontSize: 14,
                            }}
                          >
                            {address.streetName + "," + address.buildingNumber}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#9D9D9D",
                              fontSize: 10,
                            }}
                          >
                            {address.AddressLabel}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item sm={1} xs={1} sx={{ marginLeft: "1rem" }}>
                        <Link
                          to={
                            selectedAddress === false ? "/cart" : "/newAddress"
                          }
                          style={{
                            textDecoration: "none",
                            color: "#33BDE8",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            dispatch(
                              setSelectedAddress({
                                address: address,
                                index: index,
                              })
                            );
                          }}
                        >
                          <ArrowForwardIosIcon
                            sx={{
                              transform:
                                i18n.language === "Arabic"
                                  ? "rotate(180deg)"
                                  : "none",
                            }}
                          />
                        </Link>
                      </Grid>
                    </Grid>
                  );
                })
            ) : (
              <Typography>{t("Add your address here!")}</Typography>
            )
          ) : (
            <Typography>Please log in to view your address.</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AdressPage;
