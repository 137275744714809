import { LambdaFunctions } from "../constants/Enums";
import invokeLambda from "./invokeLambda";

async function listZones() {
  try {    
    const operationId = 22;
    
    const requestBody = {
      operationId,
    };
    return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody);
  } catch (error) {
    console.error("Error creating user :", error);
    throw error;
  }
}

export default listZones;
