import { Button, Grid, Typography } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SmsFailedOutlinedIcon from '@mui/icons-material/SmsFailedOutlined';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

const PaymentStatus = () => {
    const { t, i18n } = useTranslation();
    const themeMode = useSelector((state: any) => state.mode);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const success = searchParams.get("success");

    const handleContinueClick = () => {
        navigate("/");
    }
// Sorry, your payment failed
    return (
     <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '100vh' }} // This ensures the content takes up the entire viewport height
    >
            <Grid item display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'} gap={1}>
                <Typography variant="h5">{success === 'true' ? t("Your payment was successful") : t("Sorry, your payment failed")}</Typography>
                {success === 'true' ? <CheckCircleOutlineOutlinedIcon fontSize="large"/> : <SmsFailedOutlinedIcon fontSize="large" />}
                <Button
                    onClick={handleContinueClick}
                    sx={{
                    font: "0.4rem",
                    marginTop: "0.5rem",
                    padding: "1rem",
                    width: "100%",
                    fontFamily: `regular${i18n.language}`,
                    borderRadius:"10px",
                    backgroundColor:
                        themeMode === "light" ? "#F07EAA" : "#33bde8",
                    "&:hover": {
                        backgroundColor: "#33bde8",
                    },
                    }}
                >
                    Continue
                </Button>
            </Grid>
    </Grid>
)
}

export default PaymentStatus;