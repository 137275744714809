import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  useTheme
} from "@mui/material";
import { signOut } from "aws-amplify/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { sendOtpViaSMS, verifyOtp } from "../../services/sendOTP";
import { setLogin, setSelectedAddress } from "../../state";

type Props = {
  open: boolean;
  checkCode: any;
  handleSuccessfulOTP: any;
  phoneNumber: string;
  slug?: any;
  onClose?: () => void;
};
function OTP({
  open,
  checkCode,
  handleSuccessfulOTP,
  phoneNumber,
  slug,
  onClose,
}: Props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { hash, pathname, search } = location;
  // const [openModel, setOpenModel] = useState(open);
  const [otp, setOtp] = useState("");
  const [validationWarning, setValidationWarning] = useState<boolean>(false);
  const [code, setCode] = useState(checkCode);
  const [message, setMessage] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const selectedConcept = useSelector((state: any) => state.selectedConcept);
  const themeMode = useSelector((state: any) => state.mode);
  const theme = useTheme();

  const inputStyle = {
    background: "#393744",
    boxShadow:
      "0px -2.9085745811462402px 13.573348045349121px rgba(0, 0, 0, 0.10)",
    borderRadius: 10,
    border: "1px rgba(255, 255, 255, 0.40) solid",
    fontFamily: `regular${i18n.language}`,
    color: "white",
    fontSize: 30,
    height: "3rem",
    width: "3rem",
  };

  const handleOTP = async () => {
    try {
      if (otp.length !== 4) return;
      setLoading(true);
      const response = await verifyOtp(otp, code);
      switch (response.data.otpPasscodeStatus) {
        case 1:
          setSuccess(true);
          setValidationWarning(true);
          setMessage(t("Phone number verified succsessfully"));
          // setOpenModel(false);
          onClose?.();
          handleSuccessfulOTP();
          break;
        case 2:
          setValidationWarning(true);
          setMessage(t("Incorrect OTP"));
          break;
        case 3:
          setValidationWarning(true);
          setMessage(t("Expired OTP"));
          break;
        default:
          setValidationWarning(true);
          setMessage(t("Something went wrong"));
          break;
      }
      const currentTime = new Date().getTime();
      localStorage.setItem("lastOtp", currentTime.toString());
    } catch (e: any) {
      console.log(e);
      setValidationWarning(true);
      setMessage(t("Something went wrong"));
    } finally {
      setLoading(false);
    }
  };

  const handleClose = async () => {
    // if(slug==="phoneNumber")
    // {
    // if (!selectedConcept) return;
    if (pathname === "/Profile") {
      setOtp("");
      onClose?.();
    } else {
      setLoading(true);
      await signOut();
      dispatch(setLogin({ user: null }));
      // updateOfflineCart([] , 0,selectedConcept.id);
      dispatch(setSelectedAddress(null));
      localStorage.removeItem("user");
      setLoading(false);
      // }
      setOtp("");
      // setOpenModel(false);
      onClose?.();
    }
  };

  const handleResend = async () => {
    const currentTime = new Date().getTime();
    const lastLocalOtp = localStorage.getItem("lastOtp");
    if (lastLocalOtp) {
      const timeDifference = currentTime - Number(lastLocalOtp);
      if (timeDifference < 30000) {
        setValidationWarning(true);
        setMessage(
          t("Please wait before trying again", {
            time: Math.floor((30000 - timeDifference) / 1000).toString(),
          })
        );
        return;
      }
    }
    try {
      setLoading(true);
      const { instanceId, checkCode } = await sendOtpViaSMS(phoneNumber);
      setCode(checkCode);
      setLoading(false);
      localStorage.setItem("lastOtp", currentTime.toString());
    } catch (e) {
      console.log(e);
      setValidationWarning(true);
      setMessage(t("Something went wrong"));
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (otp: string) => {
    const convertedOTP = Number(otp);
    if (isNaN(convertedOTP)) return;
    setOtp(otp);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationWarning}
        autoHideDuration={1000}
        onClose={() => {
          setValidationWarning(false);
        }}
      >
        <Alert
          onClose={() => {
            setValidationWarning(false);
          }}
          severity={success ? "success" : "error"}
          sx={{
            position: "fixed",
            top: "16px",
            right: "16px",
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setValidationWarning(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          alignItems: "flex-start",
        }}
        open={open}
      >
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            zIndex: 0,
            cursor: "pointer",
          }}
        />
        <Box
          sx={{
            position: "relative",
            top: "50%",
            width: "90%",
            transform: "translateY(-50%)",
            backgroundColor: themeMode === "dark" ? "#393744" : "#D3D3D3",
            padding: ".5rem",
            borderRadius: 5,
          }}
        >
          <Grid
            height={"100%"}
            gap={"1rem"}
            container
            alignContent={"center"}
            alignItems={"center"}
            justifyContent={"center"}
            justifyItems={"center"}
          >
            <Grid item xs={12}>
              <Typography
                style={{
                  textAlign: "center",
                  color: theme.palette.primary.main,
                  fontSize: 16,
                  fontFamily: `regular${i18n.language}`,
                  fontWeight: "400",
                  letterSpacing: 0.64,
                  wordWrap: "break-word",
                }}
              >
                {t("Enter Verification Code")}
              </Typography>
            </Grid>
            <OtpInput
              inputType="tel"
              value={otp}
              onChange={handleOtpChange}
              numInputs={4}
              renderSeparator={<Box width={"1rem"}> </Box>}
              renderInput={(props) => <input {...props} />}
              inputStyle={inputStyle}
              shouldAutoFocus
            />
          </Grid>
          <Box marginTop={"2rem"} style={{ textAlign: "center" }}>
            <span
              style={{
                color: theme.palette.primary.main,
                fontSize: 14,
                fontFamily: `regular${i18n.language}`,
                fontWeight: "200",
                wordWrap: "break-word",
              }}
            >
              {t("If you didn’t receive a code")},{" "}
            </span>
            <span
              onClick={() => {
                handleResend();
              }}
              style={{
                color: loading ? "gray" : "#33BDE8",
                fontSize: 14,
                fontFamily: "Inter",
                fontWeight: "200",
                wordWrap: "break-word",
                cursor: loading ? "not-allowed" : "pointer",
                textTransform: "capitalize",
              }}
            >
              {t("Resend")}
            </span>
          </Box>
          <Grid container columnSpacing={"1rem"}>
            <Grid item xs={6}>
              <Button
                style={{
                  width: "100%",
                  height: "3rem",
                  marginTop: "1rem",
                  background: "#F07EAA",
                  boxShadow:
                    "0px -2.9085745811462402px 13.573348045349121px rgba(255, 255, 255, 0.10)",
                  borderRadius: 12.6,
                }}
                onClick={() => {
                  handleClose();
                }}
                disabled={loading}
              >
                {t("CANCEL")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                style={{
                  width: "100%",
                  height: "3rem",
                  marginTop: "1rem",
                  background:
                    "linear-gradient(270deg, #F07EA5 0%, #33BDE8 59%, #33BDE8 73%)",
                  boxShadow:
                    "0px -2.9085745811462402px 13.573348045349121px rgba(255, 255, 255, 0.10)",
                  borderRadius: 12.6,
                }}
                onClick={() => {
                  if (!loading && otp.length === 4) {
                    handleOTP();
                  }
                }}
                loading={loading}
                disabled={otp.length !== 4}
              >
                {t("SEND")}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Backdrop>
    </>
  );
}
export default OTP;
