import { Box, Button, Grid } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { cloudinaryURL } from "../../constants/Enums";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
// import defaultImage from "../../assets/image1.png"

function MenuItemHeader() {
  const { i18n } = useTranslation();
  const defaultImage = "../../assets/logo.svg";
  const selectedItem = useSelector((state: any) => state.selectedItem || {});

  const { state } = useLocation();
  const EditedcartItem = state?.cartItem;

  // const backgroundImage=selectedItem.image || defaultImage;
  return (
    // {imageNotFound? <img src={require('../../assets/no-image-icon-15.png')} />:

    <Box
      sx={{
        background: "white",
        borderBottomRightRadius: "5px",
        borderBottomLeftRadius: "5px",
      }}
    >
      <img
        style={{
          // objectFit: "contain",
          objectFit: "cover",
          width: "100%",
          // aspectRatio: "3.5/1",
        }}
        src={`${cloudinaryURL}${selectedItem.image}` || defaultImage}
        onError={(e) => {
          const target = e.target as HTMLImageElement;
          target.src = defaultImage; // Fallback to default image on error loading API image
        }}
      />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ position: "absolute", top: 20, width: "100%" }}
      >
        <Grid item>
          <Link to={EditedcartItem ? "/cart" : "/"}>
            <Button
              sx={{
                backgroundColor: "rgba(169, 169, 169, 0.55)",
                color: "rgba(64, 64, 64, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: " 2rem",
                height: "2rem",
                borderRadius: "0.5rem",
                marginBottom: "1rem",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#ffffff",
              }}
            >
              <ArrowBackIosNewIcon
                sx={{
                  color: "#33BDE8",
                  fontSize: "1rem",
                  transform:
                    i18n.language === "Arabic" ? "rotate(180deg)" : "none",
                }}
              />
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MenuItemHeader;
