import { LambdaFunctions } from "../constants/Enums";
import invokeLambda from "./invokeLambda";

async function createOnlineOrder(orderData: any) {
  try {
    const operationId = 4;
    const requestBody = {
      operationId: operationId,
      cartID: orderData.cartID,
      conceptID: orderData.conceptID,
      userID: orderData.userID,
      userName: orderData.userName,
      orderedItems: orderData.orderedItems,
      specialRequest: orderData.specialRequest,
      OrderStatus: orderData.OrderStatus,
      orderType: orderData.orderType,
      orderAddress: orderData.orderAddress,
      channel: orderData.channel,
      orderTime: orderData.orderTime,
      totalPrice: orderData.totalPrice,
      discountedValue: orderData.discountedValue,
      deliveryFee: orderData.deliveryFee,
      tax: orderData.tax,
      subTotal: orderData.subTotal,
      subTaxTotal: orderData.subTaxTotal,
      tableId: orderData.tableId,
      createdAt: orderData.createdAt,
      tableName: orderData.tableName,
      parentOrder: orderData.parentOrder,
      childrenStatus: orderData.childrenStatus,
      paymentType: orderData.paymentType,
      promoCodeID: orderData.promoCodeID,
      promoCodeName: orderData.promoCodeName,
      promoCodeAppliedTo: orderData.promoCodeAppliedTo,
    };
    return await invokeLambda(
      LambdaFunctions.callCenterOperations,
      requestBody
    );
  } catch (error) {
    console.error("Error creating online order :", error);
    throw error;
  }
}

export default createOnlineOrder;
