import { Grid, Paper } from "@mui/material"
import { useSelector } from "react-redux";
import {
    numberTranslation,
} from "../../constants/helperFunction";
import { useTranslation } from "react-i18next";


interface otpComponentProps {
    otp: string[];
}

const OtpComponent = ({otp}: otpComponentProps) => {
    const themeMode = useSelector((state: any) => state.mode);
    const { t, i18n } = useTranslation();

    return (
        <Grid container display={'flex'} flexDirection={'row'} alignItems={'center'} gap={3} justifyContent={'center'}>
            {otp.map((otpNum: string, index: number) => (
                <Paper
                    key={index}
                    sx={{
                    width: '3rem',
                    height: '3rem',
                    borderRadius: '10px',
                    backgroundColor: themeMode === 'light' ? "#393744" : "#D3D3D3",                    
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    border: '2px solid rgba(255, 255, 255, 0.4)',
                }}
            >
               {otpNum}
            </Paper>
            ))}
        </Grid>
    )
}

export default OtpComponent;