import { useState, useRef, useEffect, MouseEvent } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Card,
  useTheme,
  Typography,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";
import "./Redeem.css";
import {
  capitalization,
  numberTranslation,
} from "../../constants/helperFunction";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface EarnProps {
  onClose: () => void;
  onRedeem: () => void;
}

function Earn({ onClose, onRedeem }: EarnProps) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const themeMode = useSelector((state: any) => state.mode);
  const selectedConcept = useSelector((state: any) => state.selectedConcept);
  const overlayRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (event.target.className === "redeem-overlay") {
      onClose();
    }
  };

  const handleRedeemNow = () => {
    onClose();
    onRedeem();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box>
      <div className="redeem-overlay" ref={overlayRef}>
        <Box
          display="flex"
          flexDirection="column"
          className="redeem-container"
          sx={{ backgroundColor: themeMode === 'dark' ?  "#393744" : "#D3D3D3" }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginY: "1rem", marginX:'1rem' }}
          >
            <ArrowBackIosNewIcon
              sx={{ color: "#33BDE8", fontSize: "1rem" }}
              onClick={onClose}
            />

            <Typography
              className="title2"
              style={{
                fontSize: "16",
                fontWeight: "500",
                color: theme.palette.primary.main,
                display: "flex",
                alignItems: "space-between",
                textTransform: "uppercase",
              }}
            >
              {t("how to earn")}
              <img
                style={{
                  marginLeft: "0.3rem",
                  marginRight: "0.3rem",

                  width: "24px",
                  height: "24px",
                  verticalAlign: "middle",
                }}
                src="./assets/gold.svg"
                alt=""
              />
            </Typography>
            <Box>
              <IconButton onClick={onClose}>
                <CloseIcon sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <Typography
              variant="h6"
              color={theme.palette.primary.main}
              fontSize={"16px"}
              fontWeight={"500"}
              mt={"0rem"}
              textAlign={"center"}
            >
              {t("Take a tour on pablo & Abdo Rewards program")}
            </Typography>
            {/* <Typography
                            variant="h6"
                            color={theme.palette.primary.main}
                            fontSize={"17px"}
                            fontWeight={"500"}
                        >
                            All these rewards could be yours😋
                        </Typography> */}
            <Typography
              variant="h6"
              color={theme.palette.primary.main}
              fontSize={"19px"}
              fontWeight={"500"}
              mt={"1rem"}
              sx={{ textAlign: "center", marginX:'2rem' }}
            >
              {/* {t("pointsCalculationRule")} */}
              {/* {`Get ${numberTranslation(selectedConcept.rewardPoints, i18n.language)} points per EGP ${numberTranslation(selectedConcept.moneySpent, i18n.language)} spent on qualifing purchases in-store or when you order ahead.`} */}
              {t("pointsCalculationRule", {
                rewardPoints: numberTranslation(
                  selectedConcept.rewardPoints,
                  i18n.language
                ),
                moneySpent: numberTranslation(
                  selectedConcept.moneySpent,
                  i18n.language
                ),
              })}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" sx={{ marginY: "1rem" }}>
            <img src="./assets/reward.png" alt="" />
          </Box>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "500",
                color: theme.palette.primary.main,
                marginX:'2rem'
              }}
            >
              {t("EARN POINTS EVERY TIME YOU MAKE AN ORDER @ Pablo and Abdo")}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" height={'5rem'}>
            <Button
              variant="contained"
              type="submit"
              onClick={handleRedeemNow}
              sx={{
                marginY: "1rem",
                fontSize: 22,
                width: "70%",
                color: "#FFFFFF",
                background:
                  "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
                boxShadow: "none",
                transition: "box-shadow 0.3s",
                borderRadius: "1rem",
                padding: "1rem",
                "&:hover": {
                  boxShadow: "0px 0px 5px 2px rgb(240,126,165,0.7)",
                  background:
                    "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
                },
              }}
            >
              {`${t("redeem")} ${t("now")}`}
            </Button>
          </Box>
        </Box>
      </div>
    </Box>
  );
}

export default Earn;
