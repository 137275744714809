import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { cloudinaryURL } from "../../constants/Enums";
import { capitalization } from "../../constants/helperFunction";
import { Category } from "../../models/Category";
import { useSelector } from "react-redux";

interface CategoryContainerProps {
  category: Category | null;
}

const CategoryContainer = ({ category }: CategoryContainerProps) => {
  const theme = useTheme();
  const { i18n } = useTranslation();

  const [name, setName] = useState<string>(category.name);
  const [image, setImage] = useState<string>(category.image);
  const mode = useSelector((state: any) => state.mode);

  useEffect(() => {
    // Parse multiLanguages JSON string to a Map
    const multiLanguagesMap = new Map(JSON.parse(category.multiLanguages));

    // Calculate name based on the current language
    const translatedName: string = multiLanguagesMap.get(
      `${i18n.language}-name`
    ) as string;
    if (translatedName) {
      setName(capitalization(translatedName) as string);
    } else {
      setName(category.name); // Fallback to default name
    }

    // Determine image based on the current theme and language
    const imageKey = `${i18n.language}-${
      theme.palette.mode === "dark" ? "image" : "darkImage"
    }`;
    const translatedImage = multiLanguagesMap.get(imageKey);
    if (translatedImage) {
      setImage(translatedImage as string);
    } else {
      setImage(category.image); // Fallback to default image
    }
  }, [category, i18n.language]);

  return (
    <Box sx={{ margin: "0px 5px", alignItems: "center", textAlign: "center" }}>
      <img
        src={
          mode === "dark"
            ? `${cloudinaryURL}${category.image}`
            : `${cloudinaryURL}${category.darkImage}`
        }
        alt=""
      />
      <Typography
        sx={{
          // display: "flex",
          // flexDirection: "column",
          // margin: "0px 5px",
          color: "#f8a8c5",
          padding: "8px 0px 8px 0px",
          maxWidth: "100px",
          minWidth: "100px",
          fontSize: "12px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textTransform: "capitalize",
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};

export default CategoryContainer;
