import { Box, Button, IconButton, MobileStepper, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { cloudinaryURL } from "../../constants/Enums";

const VoucherHeader = () => {
  const selectedConcept = useSelector((state: any) => state.selectedConcept);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [startX, setStartX] = useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = (currentImageIndex + 1) % selectedConcept.sliderImages.length;
      setCurrentImageIndex(newIndex);
      handleNext();
    }, 9000);

    return () => clearInterval(interval);
  }, [currentImageIndex, selectedConcept.sliderImages?.length]);

  const goToPreviousImage = () => {
    const newIndex = (currentImageIndex - 1 + selectedConcept.sliderImages.length) % selectedConcept.sliderImages.length;
    setCurrentImageIndex(newIndex);
  };

  const goToNextImage = () => {
    const newIndex = (currentImageIndex + 1) % selectedConcept.sliderImages.length;
    setCurrentImageIndex(newIndex);
  };

  
  const handleTouchStart = (e:any) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e:any) => {
    if (startX !== null) {
      const endX = e.changedTouches[0].clientX;
      const deltaX = endX - startX;
      const swipeThreshold = 50;

      if (deltaX < -swipeThreshold) {
        goToPreviousImage();
        handleNext();
      }
      else if (deltaX > swipeThreshold) {
        goToNextImage();
        handleBack();
      }

      setStartX(null);
    }
  };

  const handleNext = () => {
    if(activeStep===selectedConcept.sliderImages.length-1)
    {
      setActiveStep(0);
    }
    else
    {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if(activeStep===0)
    {
      setActiveStep((prevActiveStep) => selectedConcept.sliderImages.length-1);
    }
    else
    {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  
  return (
    <Box
    sx={{
      position: "relative",
        width: "100%",
      height:"10rem",
      overflow: "hidden",
      borderRadius: '1rem',
    }}
  >
    {/* MobileStepper on top */}
    <MobileStepper
      variant="dots"
      steps={selectedConcept.sliderImages?.length}
      sx={{ maxWidth: 400, flexGrow: 1, zIndex: 1,position:"absolute", background: 'transparent' }}
      activeStep={activeStep}
      nextButton={<Button size="small" hidden disabled></Button>}
      backButton={<Button size="small" disabled hidden></Button>}
    />
  
    {/* Images in the background */}
    <Box
      sx={{
        position: "relative",
        width: "100%",
        whiteSpace: "nowrap",
      }}
    >
      {selectedConcept.sliderImages?.map((image:any, index:any) => (
        <div
          key={index}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          style={{
            top: 0,
            left: 0,
            width: "100%",
            minWidth: "100%",
            display: index === currentImageIndex ? "block" : "none",
          }}
        >
          <img
            key={index}
            style={{
              width: "100%",
              height:"10rem",
              minWidth: "100%",
            }}
            src={`${cloudinaryURL}${image}`}
            alt={`image-${index}`}
          />
        </div>
      ))}
    </Box>
  </Box>
  );
};

export default VoucherHeader;
