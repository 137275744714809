import { Grid, useTheme, Typography, Box, IconButton, Paper } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MuiOtpInput } from 'mui-one-time-password-input'
import OtpComponent from "./otpComponent";
import { useEffect } from "react";

interface RewardOtp {
    onClose: () => void;
    otp: string;
}
// const otp = [1,2,3,4]

const RewardOtp = ({ onClose, otp }: RewardOtp) => { 
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const themeMode = useSelector((state: any) => state.mode);

    
    const handleClickOutside = (event: any):void => {
        if (event.target.className === 'redeem-overlay') {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Box>
            <div className="redeem-overlay">
                <Box display="flex" flexDirection="column" className="redeem-container" sx={{backgroundColor: themeMode === 'light' ?  "#393744" : "#D3D3D3", padding:'2rem', }} >
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginY: "0rem", marginX:'1rem' }}>

                        <ArrowBackIosNewIcon
                            sx={{ color: "#33BDE8", fontSize: "1rem" }}
                            onClick={onClose}
                        />
                        <Box>
                            <IconButton onClick={onClose}>
                                <CloseIcon sx={{ color: theme.palette.primary.main }} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{mb:'0rem'}}>
                        <img src='assets/otp.png' alt="otp icon" style={{
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: '20%',
                        }}
                             />
                        <Typography
                            variant="h6"
                            color={theme.palette.primary.main}
                            fontSize={"20px"}
                            fontWeight={"500"}
                            mt={'1rem'}
                            mb={'1rem'}
                            textAlign={'center'}
                        >
                            {t("Show this OTP to counter to verify & redeem your offer")}
                        </Typography>
                        
                        <OtpComponent otp={otp.split('')}/>
                        
                    </Box>
                   
                  
                </Box>
            </div >
        </Box >
    );
}

export default RewardOtp;