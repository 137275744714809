import { EndPoints, LambdaFunctions } from "../constants/Enums";
import { CartItem } from "../models";
import invokeLambda from "./invokeLambda";

interface PaymentData {
  amount_cents: string;
  conceptID: string;
  transactionType: string;
  guestName: string;
  guestPhone: string;
  guestGroup: string;
  guestID: string;
  email: string;
  orderType: string;
  cartID: string;
  orderedItems: CartItem[];
  specialRequest: string;
  channel: string;
  orderTime: string;
  orderAddress: string;
  totalPrice: number;
  deliveryFee: number;
  tax: number;
  subTotal: number;
  subTaxTotal: number;
  tableID: string;
  createdAt: string;
  tableName: string;
  parentOrder: string;
  childrenStatus: any;
  promoCodeID: string;
  promoCodeName: string;
  promoCodeAppliedTo: string;
  OrderStatus: string;
  paymentType: string;
  checkNumber: string;
}

async function createPaymentURL(paymentData: PaymentData) {
  try {
    const operationId = 1;
    const requestBody = {
      paymentData,
      operationId,
      conceptID: paymentData.conceptID,
    };
    const response = await invokeLambda(
      LambdaFunctions.paymentOperations,
      requestBody
    );
    return response.paymentUrl;
  } catch (error) {
    console.error("Error getting paymentURL :", error);
    //   throw error;
  }
}

export { createPaymentURL };
export type { PaymentData };
