import { Button, Grid, Typography } from "@mui/material";
import { useSelector , useDispatch} from "react-redux";
import TransactionItem from "./TransactionItem";
import { useEffect, useState } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getUserLoyaltyTransactions } from "../../services/loyaltyServices";
import { setTransactions } from "../../state";


interface TransactionItemType {
  id: string;
  name: string;
  points: number;
  loyaltyItem: any;
  orderID: string;
  createdAt: string;
  channel: string;
  type: string;
  multiLanguages: any;
  items: string;
}

const PastTransactions = () => {
  const themeMode = useSelector((state: any) => state.mode);
  const transactions = useSelector((state: any) => state.transactions);
  const [page,setPage] = useState(2);
  const [limit, setLimit] = useState(50);
  const [fetchedTransactionsLength, setFetchedTransactionsLength] = useState(transactions.length);
  const user = useSelector((state: any) => state.user);
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchMoreTransactions = async () => {
    const fetchedTransactions = await getUserLoyaltyTransactions(user.id, limit, page);
    setPage((prev: number) => prev + 1);
    setFetchedTransactionsLength(fetchedTransactions.length);
    dispatch(setTransactions([...transactions, ...fetchedTransactions]));
  }

  useEffect(() => {
    if (!user || !transactions?.length) navigate('/');
  }, []);

  return (
    <Grid container mt={"1.5rem"} gap={"1rem"}>
      <Grid item ml={3} sm={12} lg={12} sx={{ direction: "ltr" }}>
        <Link to="/rewards">
          <Button
            sx={{
              backgroundColor: "rgba(169, 169, 169, 0.55)",
              color: "rgba(64, 64, 64, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: " 2rem",
              height: "2rem",
              borderRadius: "0.5rem",
              marginBottom: "0.5rem",
              "&:hover": {
                backgroundColor: "#33bde8",
              },
            }}
          >
            <ArrowBackIosNewIcon
              sx={{
                color: "#33BDE8",
                fontSize: "1rem",
                transform:
                  i18n.language === "Arabic" ? "rotate(180deg)" : "none",
              }}
            />
          </Button>
        </Link>
      </Grid>

      <Grid
        item
        ml={3}
        xs={12}
        justifyContent={"space-between"}
        display={"flex"}
        flexDirection={"row"}
      >
        <Typography>{t("Past transactions") }</Typography>
      </Grid>

      <Grid
        item
        mb={9}
        xs={12}
        ml={2}
        mr={2}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={'space-between'}
        sx={{
          backgroundColor: themeMode === "light" ? "#393744" : "#D3D3D3",
          borderRadius: "10px",
          overflow: "hidden",
          padding: "0.7rem",
        }}
      >
        <InfiniteScroll
          dataLength={transactions?.length || 0}
          next={fetchMoreTransactions}
          style={{ display: "flex", flexDirection: "column" }} //To put endMessage and loader to the top.
          inverse={false} //
          hasMore={fetchedTransactionsLength===limit}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
          scrollThreshold={0.8}
        >
        {transactions && transactions.length && transactions.map((transaction: TransactionItemType) => (
          <TransactionItem
            key={transaction.id}
            name={transaction.type === 'redeem' ? transaction.items : 'Online order'}
            orderID={transaction.orderID}
            points={transaction.points}
            channel={transaction.channel}
            type={transaction.type}
            redeemedAt={transaction.createdAt}
            loyaltyMultiLanguages={transaction.loyaltyItem?.multiLanguages}
            multiLanguages={transaction.multiLanguages}
            items={transaction.items}
          />
        ))}
        </InfiniteScroll>

      </Grid>
    </Grid>
  );
};

export default PastTransactions;
