import { memo } from "react";
import { Route, Routes } from "react-router-dom";
import CategoryPage from "../caegorycontainer/CategoryPage";
import { Notifications, AddLocation } from "@mui/icons-material";
import About from "../../components/about/About";
import AdressPage from "../../components/address/AdressPage";
import NewAddress from "../../components/address/NewAddress";
import Contact from "../../components/contact/Contact";
import ForgetPassword from "../../components/forgetPassword/ForgetPassword";
import ResetPassword from "../../components/forgetPassword/ResetPassword";
import LoginPage from "../../components/login/LoginPage";
import LoginWithOTP from "../../components/LoginWithOTP/LoginWithOTP";
import LoginWithoutPassword from "../../components/LoginWithOTP/LoginWithoutPassword";
import PastTransactions from "../../components/loyalty/PastTransactions";
import Rewards from "../../components/loyalty/Rewards";
import MenuPage from "../../components/MenuPage/MenuPage";
import OrderDetails from "../../components/orderDetails/OrderDetails";
import OrderHistory from "../../components/orderHistory/OrderHistory";
import OrderTrack from "../../components/ordertrack/OrderTrack";
import PaymentStatus from "../../components/payment/PaymentStatus";
import Register from "../../components/register/Register";
import UserPage from "../../components/userPage/UserPage";
import Menu from "../menu";
import Cart from "../../components/cart/Cart";

const RoutesContainer = memo(() => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <CategoryPage
          //   changeIsChannelModalOpen={handleOpenChangeChannelModal}
          />
        }
      />
      <Route path="/cart" element={<Cart />} />
      <Route path="/menu/:categoryId/:itemId" element={<Menu />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgetPass" element={<ForgetPassword />} />
      <Route path="/loginWithoutPassword" element={<LoginWithoutPassword />} />
      <Route path="/loginWithOtp" element={<LoginWithOTP />} />
      <Route path="/resetPass" element={<ResetPassword />} />
      <Route path="/profile" element={<UserPage />} />
      <Route path="/about" element={<About />} />
      <Route path="/orders" element={<OrderHistory />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/orderDetails" element={<OrderDetails />} />
      <Route path="/orderTrack" element={<OrderTrack />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/address" element={<AdressPage />} />
      <Route path="/newAddress" element={<NewAddress />} />
      <Route path="/addLocation" element={<AddLocation />} />
      <Route path="/rewards" element={<Rewards />} />
      <Route path="/past-transactions" element={<PastTransactions />} />
      <Route path="/payment-status" element={<PaymentStatus />} />
      <Route path="/menu" element={<MenuPage />} />
    </Routes>
  );
});

export default RoutesContainer;
