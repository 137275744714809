import { EndPoints, LambdaFunctions, account } from "../constants/Enums";
import axios from "axios";
import invokeLambda from "./invokeLambda";

async function updateUser({
  userID,
  version,
  email,
  name,
  email_verified,
  phone_number,
  address,
  status,
  birthdate,
  gender,
  createdAt,
  deleted,
  group,
  picture,
  pictureCrop,
  phones,
  phone_number_verified,
  resource,
  cognitoUsername,
  updated,
}: {
  userID: any;
  version: any;
  email?: any;
  name?: any;
  phone_number?: any;
  address?: any;
  status?: any;
  birthdate?: any;
  gender?: any;
  picture?: any;
  pictureCrop?: any;
  phones?: [any];
  deleted?: any;
  createdAt?: any;
  email_verified?: any;
  phone_number_verified?: any;
  group?: string;
  resource?: any;
  cognitoUsername?: string;
  updated?: "true" | "false";
}) {
  try {
    const operationId = 17;
    const userAttributes = {
      email,
      email_verified,
      name,
      phone_number,
      phone_number_verified,
      address,
      status,
      birthdate,
      gender,
      picture,
      pictureCrop,
      phones,
      deleted,
      createdAt,
      group,
      cognitoUsername,
      updated,
    };

    const requestBody = {
      operationId,
      userID,
      userAttributes: userAttributes,
      resource: resource,
      version,
    };
    return await invokeLambda(
      LambdaFunctions.callCenterOperations,
      requestBody
    );
  } catch (error) {
    console.error("Error updating user :", error);
    throw error;
  }
}

export default updateUser;
