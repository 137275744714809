import {
  IconButton,
  Grid,
  Box,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import UpdateSharpIcon from "@mui/icons-material/UpdateSharp";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import KitchenIcon from "@mui/icons-material/Kitchen";
import RoomServiceOutlinedIcon from "@mui/icons-material/RoomServiceOutlined";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { OrderStatus } from "../../constants/Enums";
import { onUpdateOnlineOrder } from "../../graphql/subscriptions";
import { generateClient } from "aws-amplify/api";
import { setCurrentOrder } from "../../state/index";
import { useSelector } from "react-redux";
import { capitalization, formatDate } from "../../constants/helperFunction";
import { useTranslation } from "react-i18next";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CancelIcon from "@mui/icons-material/Cancel";
import Review from "../Review/Review";
import Navbar from "../Nav/Navbar";

const API = generateClient();

function OrderTrack() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [rating, setRating] = useState(0);
  const dispatch = useDispatch();
  const order = useSelector((state: any) => state.currentOrder);
  const [pending, setPending] = useState(true);
  const [cancelled, setCancelled] = useState(false);
  const [placed, setPlaced] = useState(false);
  const [outForDelivery, setOutForDelivery] = useState(false);
  const [delivered, setDelivered] = useState(false);
  const [readyForPickup, setReadyForPickup] = useState(false);
  const [pickedUp, setPickedUp] = useState(false);
  const [showReview, setShowReview] = useState(false);

  function updateTrack(order: any) {
    switch (order.status) {
      case OrderStatus.pending: {
        setPending(true);
        setCancelled(false);
        setPlaced(false);
        setDelivered(false);
        setOutForDelivery(false);
        setReadyForPickup(false);
        setPickedUp(false);
        break;
      }
      case OrderStatus.cancelled: {
        setPending(true);
        setCancelled(true);
        setPlaced(false);
        setDelivered(false);
        setOutForDelivery(false);
        setReadyForPickup(false);
        setPickedUp(false);
        break;
      }
      case OrderStatus.sentToKitchen: {
        setPending(true);
        setCancelled(false);
        setPlaced(true);
        setDelivered(false);
        setOutForDelivery(false);
        setReadyForPickup(false);
        setPickedUp(false);
        break;
      }
      case OrderStatus.outForDelivery: {
        setPending(true);
        setCancelled(false);
        setPlaced(true);
        setOutForDelivery(true);
        setDelivered(false);
        setReadyForPickup(false);
        setPickedUp(false);
        break;
      }
      case OrderStatus.readyForPickup: {
        setPending(true);
        setCancelled(false);
        setPlaced(true);
        setOutForDelivery(false);
        setDelivered(false);
        setReadyForPickup(true);
        setPickedUp(false);
        break;
      }
      case OrderStatus.deliveredAndSettled: {
        setPending(true);
        setCancelled(false);
        setPlaced(true);
        setDelivered(true);
        setOutForDelivery(true);
        setReadyForPickup(false);
        setPickedUp(false);
        break;
      }
      case OrderStatus.settled: {
        setPending(true);
        setCancelled(false);
        setPlaced(true);
        setOutForDelivery(false);
        setDelivered(false);
        setReadyForPickup(true);
        setPickedUp(true);
        break;
      }
      default: {
        break;
      }
    }
  }

  const closeReviewForm = () => {
    setShowReview(false);
  };

  useEffect(() => {
    if (!order) {
      return;
    }
    updateTrack(order);

    const subscription = API.graphql({ query: onUpdateOnlineOrder });

    if ("subscribe" in subscription) {
      const orderSubscription = subscription.subscribe({
        next: (event: any) => {
          const updatedOrder = event.data.onUpdateOnlineOrder;

          if (updatedOrder?.id === order?.id) {
            dispatch(setCurrentOrder(updatedOrder));
            updateTrack(updatedOrder);
          }
        },
        error: (error: any) => {
          console.error("Subscription error:", error);
        },
      });

      return () => {
        if ("unsubscribe" in orderSubscription) {
          orderSubscription.unsubscribe();
        }
      };
    }
  }, []);

  if (!order) {
    return <Navigate to="/orders" />;
  }

  return (
    <Grid sx={{ padding: "1rem", paddingBottom: "20%" }}>
      <Grid
        item
        sm={12}
        lg={12}
        sx={{ direction: i18n.language === "Arabic" ? "rtl" : "ltr" }}
      >
        <Link to="/orderDetails" state={{ selectedOrder: order }}>
          <Button
            sx={{
              backgroundColor: "rgba(169, 169, 169, 0.55)",
              color: "rgba(64, 64, 64, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: " 2rem",
              height: "2rem",
              borderRadius: "0.5rem",
              marginBottom: ".5rem",
              "&:hover": {
                backgroundColor: "#33bde8",
              },
            }}
          >
            <ArrowBackIosNewIcon
              sx={{
                color: "#33BDE8",
                fontSize: "1rem",
                transform:
                  i18n.language === "Arabic" ? "rotate(180deg)" : "none",
              }}
            />
          </Button>
        </Link>
      </Grid>
      <Box>
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "600",
          }}
        >
          {t("TrackOrder")}
        </Typography>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: "600",
          }}
        >
          {formatDate(new Date(order.createdAt), i18n)}
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: "400",
            color: "#9D9D9D",
          }}
        >
          {t("OrderID")}
          {capitalization(order.id.substring(0, 8))}
        </Typography>
        {order.checkNumber && <Typography
          sx={{
            fontSize: 16,
            fontWeight: "400",
            color: "#9D9D9D",
          }}
        >
          {t("orderNumber")}
          {capitalization(order.checkNumber)}
        </Typography>}
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: "400",
            color: "#9D9D9D",
          }}
        >
          {t("orderType")}:&nbsp;
          {order.orderType === "delivery" ? t("delivery") : t("Pick-up")}
        </Typography>
      </Box>

      {pending && (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "0.3rem",
            marginY: "2rem",
            alignItems: "center",
            padding: "1rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            justifyContent: "space-between",
          }}
        >
          <Grid item sm={9} xs={9}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
              }}
            >
              {t("orderPending")}
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                color: "#9D9D9D",
              }}
            >
              {t("WaitingConfirmation")}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={2}>
            {/* <img src="assets/time.png" alt="" /> */}
            <UpdateSharpIcon fontSize="large" style={{ color: "#9D9D9D" }} />
          </Grid>
        </Grid>
      )}

      {cancelled && (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "0.3rem",
            marginY: "2rem",
            alignItems: "center",
            padding: "1rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            justifyContent: "space-between",
          }}
        >
          <Grid item sm={9} xs={9}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
              }}
            >
              {t("orderCancelled")}
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                color: "#9D9D9D",
              }}
            >
              {t("orderWasCancelled")}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={2}>
            <CancelIcon fontSize="large" style={{ color: "#9D9D9D" }} />
          </Grid>
        </Grid>
      )}

      {placed && (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "0.3rem",
            marginY: "1rem",
            alignItems: "center",
            padding: "1rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            justifyContent: "space-between",
          }}
        >
          <Grid item sm={9} xs={9}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
              }}
            >
              {t("orderPlaced")}
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                color: "#9D9D9D",
              }}
            >
              {t("OrderPrepared")}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={2}>
            {/* <img src="assets/pot (1).png" alt="" /> */}
            <KitchenIcon fontSize="large" style={{ color: "#9D9D9D" }} />
          </Grid>
        </Grid>
      )}

      {outForDelivery && (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "0.3rem",
            marginY: "2rem",
            alignItems: "center",
            padding: "1rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            justifyContent: "space-between",
          }}
        >
          <Grid item sm={9} xs={9}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
              }}
            >
              {t("orderWay")}
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                color: "#9D9D9D",
              }}
            >
              {t("OrderDelivered")}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={2}>
            {/* <img src="assets/truck.png" alt="" /> */}
            <DeliveryDiningOutlinedIcon
              fontSize="large"
              style={{ color: "#9D9D9D" }}
            />
          </Grid>
        </Grid>
      )}

      {delivered && (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "0.3rem",
            marginY: "1rem",
            alignItems: "center",
            padding: "1rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            justifyContent: "space-between",
          }}
        >
          <Grid item sm={9} xs={9}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
              }}
            >
              {t("YourorderDelivered")}
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                color: "#9D9D9D",
              }}
            >
              {t("OrderReceived")}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={2}>
            {/* <img src="assets/pot (1).png" a/lt="" /> */}
            <RoomServiceOutlinedIcon
              fontSize="large"
              style={{ color: "#9D9D9D" }}
            />
          </Grid>
        </Grid>
      )}

      {readyForPickup && (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "0.3rem",
            marginY: "1rem",
            alignItems: "center",
            padding: "1rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            justifyContent: "space-between",
          }}
        >
          <Grid item sm={9} xs={9}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
              }}
            >
              {t("YourorderReadyForPickup")}
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                color: "#9D9D9D",
              }}
            >
              {t("pickupOrder")}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={2}>
            <img
              style={{ width: "2.5rem", height: "2.5rem" }}
              src="assets/readyForPickUp.svg"
              alt=""
            />
          </Grid>
        </Grid>
      )}

      {pickedUp && (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "0.3rem",
            marginY: "1rem",
            alignItems: "center",
            padding: "1rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            justifyContent: "space-between",
          }}
        >
          <Grid item sm={9} xs={9}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
              }}
            >
              {t("YourorderPickedUp")}
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: "400",
                color: "#9D9D9D",
              }}
            >
              {t("OrderReceived")}
            </Typography>
          </Grid>
          <Grid item sm={2} xs={2}>
            {/* <img src="assets/pot (1).png" a/lt="" /> */}
            <RoomServiceOutlinedIcon
              fontSize="large"
              style={{ color: "#9D9D9D" }}
            />
          </Grid>
        </Grid>
      )}

      {(delivered || pickedUp) && (
        <Grid
          container
          sx={{
            backgroundColor: theme.palette.primary.dark,
            borderRadius: "0.3rem",
            marginY: "1rem",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "1rem",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
          }}
        >
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: "400",
            }}
          >
            {t("experience")}
          </Typography>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: "400",
              color: "#9D9D9D",
            }}
          >
            {t("hearAboutIt")}
          </Typography>
          <Button
            onClick={() => {
              if (!order.review) {
                setShowReview(true);
              }
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StarIcon
              sx={{
                color: "#FFD600",
                marginRight: ".3rem",
                marginLeft: ".3rem",
              }}
            ></StarIcon>
            <Typography>
              {order.review
                ? (new Map(JSON.parse(order.review.multiLanguages)).get(
                    `${i18n.language}-satisfaction`
                  ) as string)
                : t("RateOrder")}
            </Typography>
          </Button>
        </Grid>
      )}
      {showReview && (
        <div className="overlay">
          <Review onClose={closeReviewForm} />
        </div>
      )}
      {!showReview && (
        <div style={{ margin: -12 }}>
          <Navbar />
        </div>
      )}
    </Grid>
  );
}

export default OrderTrack;
