import CottageIcon from "@mui/icons-material/Cottage";
import EditLocationAltOutlinedIcon from "@mui/icons-material/EditLocationAltOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setForceSelectConcept,
  setSelectedAddress,
  setSelectedConcept,
} from "../../state";
import "./changeChannelModal.css";
import { Concept, Zone } from "../../models";
import {
  getAvailableConcepts,
  getNearestConcept,
  isConceptAvailable,
  sortConceptsASCByDeliveryFee,
} from "../../services/conceptUtils";

interface ChangeChannelProps {
  onClose: () => void;
  handleChannelStateChange: (event: any) => void;
  handleDisplaySplashScreen: (val: boolean) => void;
}

function ChangeChannelModal({
  onClose,
  handleChannelStateChange,
  handleDisplaySplashScreen,
}: ChangeChannelProps) {
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const themeMode = useSelector((state: any) => state.mode);
  const user = useSelector((state: any) => state.user);
  const selectedAddress = useSelector((state: any) => state.selectedAddress);
  const selectedConcept = useSelector((state: any) => state.selectedConcept);
  const channel = useSelector((state: any) => state.channel);
  const Concept = useSelector((state: any) => state.concept);
  const zonesListing = useSelector((state: any) => state.zonesListing);
  const [addressList, setAddressList] = useState<any[]>(
    user && user.address ? JSON.parse(user.address) : []
  );
  const [selectedAddressZone, setSelectedAddressZone] = useState<Zone>();
  const [selectedChannel, setSelectedChannel] = useState(channel);

  const handleClickOutside = (event: any) => {
    if (event.target.className === "changeChannel-overlay") {
      onClose();
    }
  };

  const handleSelectAddress = (address: any, index: any) => {
    const zone = zonesListing.find((zone: any) => zone?.id === address?.zoneID);
    const newSelectedConcept = Concept.listing.find(
      (concept: Concept) => concept.id === zone.conceptID
    );
    if (!newSelectedConcept) return;
    dispatch(setSelectedAddress({ address, index }));
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    handleChannelStateChange({ target: { value: selectedChannel } });
  }, [selectedChannel]);
  const handleChannelChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setSelectedChannel(newAlignment);
    // dispatch(setChannel(newAlignment));
  };

  useEffect(() => {
    if (!user) return;
    setAddressList(JSON.parse(user.address) ?? []);
  }, [user]);

  const control = {
    value: selectedChannel,
    onChange: handleChannelChange,
    exclusive: true,
  };

  useEffect(() => {
    handleDisplaySplashScreen(true);
  }, []);

  useEffect(() => {
    const selectedZoneId = selectedAddress?.address?.zoneID;
    const zoneSelected = zonesListing.find(
      (zone: Zone) => zone.id === selectedZoneId
    );
    setSelectedAddressZone(zoneSelected);
  }, [selectedAddress, zonesListing]);

  return (
    <Box>
      <div className="changeChannel-overlay">
        <Box
          display="flex"
          flexDirection="column"
          className="changeChannel-container"
          mb={6}
          sx={{
            backgroundColor: themeMode === "dark" ? "#393744" : "#D3D3D3",
          }}
        >
          <Grid
            container
            item
            xs={12}
            height={"5rem"}
            // padding={'1rem'}
            sx={{
              backgroundColor: "#1F1D2B",
              opacity: 0.47,
              width: "100%",
            }}
          >
            <ToggleButtonGroup
              sx={{ width: "100%" }}
              size="large"
              {...control}
              aria-label="Large sizes"
            >
              <ToggleButton
                value="delivery"
                key="delivery"
                sx={{
                  borderBottom:
                    selectedChannel === "delivery"
                      ? "2px solid #33BDE8"
                      : "2px solid rgba(90, 108, 234, 0.07)",
                  fontWeight: "bold",
                  width: "50%",
                  backgroundColor:
                    selectedChannel === "delivery"
                      ? "transparent !important"
                      : null,
                }}
              >
                <Typography color={"#FFFFFF"}>{t("delivery")}</Typography>
              </ToggleButton>
              <ToggleButton
                value="pick-up"
                key="pick-up"
                sx={{
                  borderBottom:
                    selectedChannel === "pick-up"
                      ? "2px solid #33BDE8"
                      : "2px solid rgba(90, 108, 234, 0.07)",
                  fontWeight: "bold",
                  width: "50%",
                  backgroundColor:
                    selectedChannel === "pick-up"
                      ? "transparent !important"
                      : null,
                }}
              >
                <Typography color={"#FFFFFF"}>{t("Pick-up")}</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>

          <Divider
            sx={{
              width: "100%",
              borderWidth: "0.1rem",
              backgroundColor: "#FFFFFF",
              opacity: 0.16, // Adding opacity of 47%
            }}
          />

          <Grid
            container
            item
            xs={12}
            padding={"1rem"}
            paddingLeft={"1.5rem"}
            paddingRight={"1.5rem"}
            sx={{
              width: "100%",
            }}
          >
            {selectedChannel === "delivery" ? (
              // If the channel is delivery, display the addresses
              <>
                {user?.address && addressList?.length > 0 ? (
                  addressList
                    .filter((address) => {
                      // Find the corresponding zone for the address
                      const zone = zonesListing.find(
                        (zone: any) => zone?.id === address?.zoneID
                      );

                      // If the zone exists and has zoneConcepts, find the available concepts
                      if (zone?.concepts?.length) {
                        const availableConcepts = getAvailableConcepts(
                          Concept.listing
                        );
                        const nearestConcept = getNearestConcept(
                          zone.concepts,
                          availableConcepts
                        );

                        // Return true if there is a matching concept in the zone
                        return nearestConcept !== undefined;
                      }
                      return false;
                    })
                    .map((address: any, index: number) => (
                      // Render each address here
                      <React.Fragment key={address.AddressLabel}>
                        <Grid
                          item
                          display={"flex"}
                          flexDirection={"row"}
                          xs={12}
                          justifyContent={"space-between"}
                          onClick={() => {
                            handleSelectAddress(address, undefined);
                          }}
                        >
                          <Grid
                            item
                            display={"flex"}
                            flexDirection={"row"}
                            gap={2}
                          >
                            <IconButton sx={{ backgroundColor: "#33BDE8" }}>
                              <LocationOnIcon
                                sx={{
                                  color: "black",
                                  backgroundColor: "#33BDE8",
                                }}
                              />
                            </IconButton>
                            <Typography
                              mt={1}
                            >{`${address.buildingNumber} - ${address.streetName}`}</Typography>
                          </Grid>
                          {JSON.stringify(selectedAddress?.address ?? "{}") ===
                            JSON.stringify(address) && (
                            <img
                              width="25px"
                              height={"25px"}
                              src={"../../assets/correctMark.svg"}
                              alt={t("Selected address")}
                              style={{ marginTop: "0.5rem" }}
                            />
                          )}
                        </Grid>
                        <Divider
                          key={index}
                          sx={{
                            width: "100%",
                            borderWidth: "0.1rem",
                            backgroundColor: "#FFFFFF",
                            opacity: 0.16, // Adding opacity of 47%
                            mt: 2,
                            mb: 2,
                          }}
                        />
                      </React.Fragment>
                    ))
                ) : (
                  <Typography m={1}></Typography>
                )}
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                  gap={2}
                  onClick={() => {
                    dispatch(setSelectedAddress(false));
                    navigate("/address", {
                      state: { prevSlug: "/" },
                    });
                  }}
                >
                  <IconButton sx={{ backgroundColor: "#33BDE8" }}>
                    <EditLocationAltOutlinedIcon
                      sx={{ color: "black", backgroundColor: "#33BDE8" }}
                    />
                  </IconButton>
                  <Grid item>
                    <Typography>{t("Add new address")}</Typography>
                    <Typography sx={{ opacity: 0.37 }}>
                      {t("Choose location on map")}
                    </Typography>
                  </Grid>
                </Grid>

                {selectedAddressZone &&
                  selectedAddressZone.concepts?.length > 0 && (
                    <>
                      <Divider
                        sx={{
                          width: "100%",
                          borderWidth: "0.1rem",
                          backgroundColor: "#FFFFFF",
                          opacity: 0.16, // Adding opacity of 47%
                          mt: 1,
                          mb: 1,
                        }}
                      />

                      <>
                        {Concept.listing &&
                          JSON.stringify(selectedAddress?.address ?? "{}") &&
                          sortConceptsASCByDeliveryFee(
                            selectedAddressZone?.concepts,
                            Concept.listing
                          ).map((concept: any, index: number) => (
                            // Render each concept here
                            <React.Fragment key={concept.id}>
                              <Grid
                                item
                                display={"flex"}
                                flexDirection={"row"}
                                xs={12}
                                justifyContent={"space-between"}
                                onClick={() => {
                                  // if (isConceptAvailable(concept)) {
                                  dispatch(setSelectedConcept(concept));
                                  dispatch(setForceSelectConcept(true));
                                  // }
                                }}
                                // sx={{
                                //   pointerEvents: !isConceptAvailable(concept)
                                //     ? "none"
                                //     : "auto",
                                //   opacity: !isConceptAvailable(concept)
                                //     ? 0.5
                                //     : 1,
                                // }}
                              >
                                <Grid
                                  item
                                  display={"flex"}
                                  flexDirection={"row"}
                                  gap={2}
                                >
                                  <IconButton
                                    sx={{ backgroundColor: "#33BDE8" }}
                                  >
                                    <CottageIcon
                                      sx={{
                                        color: "black",
                                        backgroundColor: "#33BDE8",
                                      }}
                                    />
                                  </IconButton>
                                  <Typography mt={1}>{concept.name}</Typography>
                                </Grid>
                                {selectedConcept?.id === concept.id && (
                                  <img
                                    width="25px"
                                    height={"25px"}
                                    src={"../../assets/correctMark.svg"}
                                    alt={t("Selected address")}
                                    style={{ marginTop: "0.5rem" }}
                                  />
                                )}
                              </Grid>
                              {index < Concept.listing.length - 1 && (
                                <Divider
                                  key={index}
                                  sx={{
                                    width: "100%",
                                    borderWidth: "0.1rem",
                                    backgroundColor: "#FFFFFF",
                                    opacity: 0.16, // Adding opacity of 47%
                                    mt: 2,
                                    mb: 2,
                                  }}
                                />
                              )}
                            </React.Fragment>
                          ))}
                      </>
                    </>
                  )}
              </>
            ) : (
              // If the channel is pickup, display the concepts
              <>
                {Concept.listing &&
                  Concept.listing.map((concept: any, index: number) => (
                    // Render each concept here
                    <React.Fragment key={concept.id}>
                      <Grid
                        item
                        display={"flex"}
                        flexDirection={"row"}
                        xs={12}
                        justifyContent={"space-between"}
                        onClick={() => {
                          // if (isConceptAvailable(concept)) {
                          dispatch(setSelectedConcept(concept));
                          dispatch(setForceSelectConcept(true));
                          // }
                        }}
                        // sx={{
                        //   pointerEvents: !isConceptAvailable(concept)
                        //     ? "none"
                        //     : "auto",
                        //   opacity: !isConceptAvailable(concept) ? 0.5 : 1,
                        // }}
                      >
                        <Grid
                          item
                          display={"flex"}
                          flexDirection={"row"}
                          gap={2}
                        >
                          <IconButton sx={{ backgroundColor: "#33BDE8" }}>
                            <CottageIcon
                              sx={{
                                color: "black",
                                backgroundColor: "#33BDE8",
                              }}
                            />
                          </IconButton>
                          <Typography mt={1}>{concept.name}</Typography>
                        </Grid>
                        {selectedConcept?.id === concept.id && (
                          <img
                            width="25px"
                            height={"25px"}
                            src={"../../assets/correctMark.svg"}
                            alt={t("Selected address")}
                            style={{ marginTop: "0.5rem" }}
                          />
                        )}
                      </Grid>
                      {index < Concept.listing.length - 1 && (
                        <Divider
                          key={index}
                          sx={{
                            width: "100%",
                            borderWidth: "0.1rem",
                            backgroundColor: "#FFFFFF",
                            opacity: 0.16, // Adding opacity of 47%
                            mt: 2,
                            mb: 2,
                          }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                {!Concept.listing && <Typography m={1}></Typography>}
              </>
            )}
          </Grid>
        </Box>
      </div>
    </Box>
  );
}

export default ChangeChannelModal;
