import { EndPoints, LambdaFunctions } from "../constants/Enums";
import invokeLambda from "./invokeLambda";

async function getCart(userID: string, conceptID: string) {
  try {
    const operationId = 2;

    const requestBody = {
      userID,
      conceptID,
      operationId,
    };
    return await invokeLambda(
      LambdaFunctions.callCenterOperations,
      requestBody
    );
  } catch (error) {
    console.error("Error getting Cart :", error);
    // throw error;
  }
}

async function getCartForUpdateCart(userID: string, conceptID: string) {
  try {
    const operationId = 37;
    const requestBody = {
      userID,
      conceptID,
      operationId,
    };
    return await invokeLambda(
      LambdaFunctions.callCenterOperations,
      requestBody
    );
  } catch (error) {
    console.error("Error getting Cart :", error);
    // throw error;
  }
}

async function createCart(
  conceptID: string,
  userID: string,
  orderedItems: any,
  deliveryFee: number,
  subTotal: number,
  subTaxTotal: number,
  totalPrice: number
) {
  try {
    const operationId = 12;
    const requestBody = {
      operationId,
      conceptID,
      userID,
      orderedItems,
      deliveryFee,
      subTotal,
      subTaxTotal,
      totalPrice,
    };
    return await invokeLambda(
      LambdaFunctions.callCenterOperations,
      requestBody
    );
  } catch (error) {
    console.error("Error creating Cart :", error);
    throw error;
  }
}

async function updateCart(
  cartID: string,
  conceptID: string,
  version: number,
  newOrderedItems: any[],
  totalPrice: any,
  userID: string,
  deliveryFee: number,
  subTotal: number,
  subTaxTotal: number
) {
  try {
    const operationId = 5;

    const requestBody = {
      operationId,
      conceptID,
      cartID,
      version,
      userID,
      newOrderedItems,
      deliveryFee,
      subTotal,
      subTaxTotal,
      totalPrice,
    };
    return invokeLambda(LambdaFunctions.callCenterOperations, requestBody);
  } catch (error) {
    console.error("Error updating Cart :", error);
    throw error;
  }
}

export { getCart, createCart, updateCart, getCartForUpdateCart };
