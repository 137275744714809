import { Button, Grid, Typography } from "@mui/material";
import TransactionItem from "./TransactionItem";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface TransactionItemType {
  id: string;
  name: string;
  points: number;
  createdAt: string;
  channel: string;
  type: string;
  orderID: string;
  loyaltyItem: any;
  multiLanguages: any;
  items: string;
}

const Transactions = () => {
  const themeMode = useSelector((state: any) => state.mode);
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const transactions = useSelector((state: any) => state.transactions);
  const handleViewAll = () => {
    navigate("/past-transactions");
  };

  return (
    <Grid container mt={"1rem"}>
      <Grid
        item
        xs={12}
        justifyContent={"space-between"}
        display={"flex"}
        flexDirection={"row"}
      >
        <Typography>{t("Past transactions")}</Typography>
        <Button
          sx={{
            color: "#33BDE8",
          }}
          size="small"
          onClick={handleViewAll}
        >
          {t("View All")}
        </Button>
      </Grid>

      <Grid
        item
        xs={12}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        sx={{
          backgroundColor: themeMode === "light" ? "#393744" : "#D3D3D3",
          borderRadius: "10px",
          overflow: "hidden",
          padding: "0.7rem",
        }}
      >
        {transactions.length > 0 &&
          transactions
            .slice(0, 4)
            .map((transaction: TransactionItemType) => (
              <TransactionItem
                key={transaction.id}
                name={
                  transaction.type === "redeem"
                    ? transaction.loyaltyItem?.name
                    : "Online order"
                }
                points={transaction.points}
                channel={transaction.channel}
                type={transaction.type}
                redeemedAt={transaction.createdAt}
                multiLanguages={transaction.multiLanguages}
                orderID={transaction.orderID}
                loyaltyMultiLanguages={transaction.loyaltyItem?.multiLanguages}
                items={transaction.items}
              />
            ))}
      </Grid>
    </Grid>
  );
};

export default Transactions;
