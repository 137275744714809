import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { uploadData } from "aws-amplify/storage";
import Compressor from "compressorjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Crop,
  PixelCrop,
  centerCrop,
  makeAspectCrop
} from "react-image-crop";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Concept } from "../../API";
import { cloudinaryURL } from "../../constants/Enums";
import {
  capitalizeSentence,
  isValidEnglish
} from "../../constants/helperFunction";
import { fetchData } from "../../services/categoryPageUtils";
import {
  userExistsByPhoneNumber
} from "../../services/getOperation";
import updateUser from "../../services/updateUser";
import {
  calculateMaxDate,
  calculateMinDate,
} from "../../services/userPageUtils";
import { setLogin, setSplashScreen } from "../../state";
import OTP from "../Modals/OTP";
import SplashScreen from "../splashScreen/SplashScreen";
import { canvasPreview } from "./canvasPreview";
import CropImage from "./CropImage";

interface UserData {
  id?: any;
  name?: any;
  phone_number?: any;
  status?: any;
  birthdate?: any;
  email?: any;
  picture?: any;
  pictureCrop: string;
  // password?: string;
  version?: any;
  address?: any;
  phones?: any;
  gender?: any;
  _version?: any;
  deleted?: any;
  createdAt?: any;
}
type UserForm = {
  name: string;
  phone_number: string;
  birthdate: string;
  email: string;
  password: string;
};
function UserPage() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const user = useSelector((state: { user: UserData }) => state.user);
  const [selectedImage, setSelectedImage] = useState<string | null>(
    user?.picture ?? ""
  );
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    control,
  } = useForm<UserForm>({
    defaultValues: user
      ? {
          phone_number: user?.phone_number || "",
          birthdate: user.birthdate || "",
          name: user.name || "",
          email: user.email || "",
        }
      : { phone_number: "", birthdate: "", name: "", email: "" },
  });

  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [checkCode, setCheckCode] = useState<any>(null);
  const [openOTP, setOpenOTP] = useState<boolean>(false);
  const [message, setMessage] = useState<any>("");
  const [data, setDate] = useState<any>();
  const [success, setSuccess] = useState(true);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [imageUploaded, setImageUploaded] = useState<any>();
  const [crop, setCrop] = useState<Crop>();
  const [nameLastInput, setNameLastInput] = useState(user?.name ?? "");
  const [mobileNumberLastInput, setMobileNumberLastInput] = useState(
    user?.phone_number ?? ""
  );
  const [birthDateLastInput, setBirthDateLastInput] = useState(
    user?.birthdate ?? ""
  );
  const [emailLastInput, setEmailLastInput] = useState(user?.email ?? "");
  const [showCropImg, setShowCropImg] = useState(false);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>({
    unit: "px", // Can be 'px' or '%'
    x: 50,
    y: 50,
    width: 50,
    height: 50,
  });
  const [useCurrentCrop, setUseCurrentCrop] = useState(false);
  const fileInputRef = useRef<any>(null);
  const categories = useSelector(
    (state: any) => state.categoriesState.listing || []
  );
  const selectedConcept = useSelector((state: any) => state.selectedConcept);
  const splashScreen = useSelector((state: any) => state.splashScreen);
  const imgRef = useRef<HTMLImageElement>(null);
  const navigate = useNavigate();

  const handleCloseShowCropImg = () => {
    setShowCropImg(false);
  };

  const handleInput = (fieldName: keyof UserForm, value: any) => {
    trigger(fieldName);
    setValue(fieldName, value);
  };

  const handleCropChange = (newCrop: PixelCrop) => {
    setCompletedCrop(newCrop);
    setUseCurrentCrop(true);
  };

  const onDrop: any = useCallback(async (acceptedFiles: any) => {
    for (let file of acceptedFiles) {
      try {
        setImageLoading(true);
        setImageUploaded(file);
        setImageLoading(false);
      } catch (err: Error | any) {
        setImageLoading(false);
      }
    }
  }, []);

  const handleUploadImage = (callback: (key: string) => void): void => {
    try {
      if (!imageUploaded) {
        console.error("No image uploaded");
        callback("");
        return;
      }
      setImageLoading(true);
      new Compressor(imageUploaded, {
        quality: 0.6,
        success: async (compressedResult: any) => {
          try {
            const result = await uploadData({
              key: `${new Date().getTime()}-${compressedResult.name.replace(
                " ",
                "-"
              )}`,
              data: compressedResult,
            }).result;
            setSelectedImage(result.key);
            setImageLoading(false);
            callback(result.key);
          } catch (err) {
            console.error("Error uploading data:", err);
            setImageLoading(false);
            callback(""); // Pass an empty string to the callback
          }
        },
      });
    } catch (err) {
      console.error("Error handling upload:", err);
      setImageLoading(false);
      callback(""); // Pass an empty string to the callback
    }
  };

  const handleUpload = async () => {
    try {
      const key = await new Promise((resolve, reject) => {
        handleUploadImage((key: string) => {
          if (key) {
            resolve(key);
          } else {
            console.error("Empty key or error occurred during upload");
            reject(new Error("Empty key or error occurred during upload"));
          }
        });
      });
      return key;
    } catch (error: any) {
      console.error("Error during upload:", error.message);
      return ""; // or handle error as needed
    }
  };

  // const onDrop: any = useCallback(async (acceptedFiles: any) => {
  //     for (let file of acceptedFiles) {
  //         try {
  //             setImageLoading(true)
  //           const result = await uploadData({
  //               key:  `${new Date().getTime()}-${file.name.replace(" ", "-")}`,
  //               data:file,
  //             }).result;
  //             setSelectedImage(result.key)
  //             setImageLoading(false)
  //         } catch (err: Error | any) {
  //             setImageLoading(false)
  //         }
  //       }

  //       }, []);
  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "px",
          width: 55,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleUpdateUser = async (inputData: any, phoneChanged?: boolean) => {
    try {
      setLoading(true);
      if (!inputData) {
        inputData = data;
      }
      const updatedData = {
        userID: user.id,
        version: user._version,
        email: inputData.email ? inputData.email : user.email,
        name: inputData.name ? inputData.name : user.name,
        phone_number: inputData.phone_number,
        address: user.address,
        status: user.status,
        birthdate: inputData.birthdate ? inputData.birthdate : user.birthdate,
        gender: user.gender,
        picture: imageUploaded ? await handleUpload() : user.picture,
        pictureCrop: JSON.stringify(completedCrop),
        phones: user.phones,
        deleted: user.deleted,
        createdAt: user.createdAt,
      };
      let newUser = await updateUser({
        userID: updatedData.userID,
        version: updatedData.version,
        email: updatedData.email,
        name: updatedData.name,
        phone_number: updatedData.phone_number,
        address: updatedData.address,
        status: updatedData.status,
        birthdate: updatedData.birthdate,
        gender: updatedData.gender,
        picture: updatedData.picture,
        pictureCrop: updatedData.pictureCrop,
        phones: updatedData.phones,
        deleted: updatedData.deleted,
        createdAt: updatedData.createdAt,
        email_verified: true,
        phone_number_verified: true,
        group: undefined,
        resource: user,
        updated: phoneChanged ? "true" : "false",
      });
      dispatch(setLogin({ user: newUser }));
      setMessage(t("User info was updated succsessfully"));
      setConfirmation(true);
      setLoading(false);
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSuccessfulOTP = async () => {
    setLoading(true);
    // code replaced with a triggered lamda function
    // try {
    //   await updateUserAttribute({
    //     userAttribute: {
    //       attributeKey: "phone_number",
    //       value: "+2" + data.phone_number,
    //     },
    //   });
    // } catch (error: any) {
    //   setConfirmation(true);
    //   setSuccess(false);
    //   setMessage(error.message);
    //   return;
    // }
    await handleUpdateUser(null, true);
  };
  const onSubmit = async (data: UserForm) => {
    if (user) {
      setDate(data);
      if (user.phone_number !== data.phone_number) {
        setLoading(true);
        let isUser = await userExistsByPhoneNumber(data.phone_number);
        if (isUser.isExistingUser) {
          setMessage(t("User with this phone number already exist"));
          setConfirmation(true);
          setSuccess(false);
        } else {
          try {
            // const { instanceId, checkCode } = await sendOtpViaSMS(
            //   data.phone_number
            // );
            setOpenOTP(true);
            // setCheckCode(checkCode);
          } catch (e) {
            setMessage(t("Error sending OTP"));
            setConfirmation(true);
            setSuccess(false);
          }
        }
        setLoading(false);
      } else {
        handleUpdateUser(data);
      }
    }
  };

  const handleEditImageClick = (e: any) => {
    fileInputRef?.current?.click();
  };
  const handleOnImgClick = (e: any) => {
    e.preventDefault();

    if (!(imageUploaded || user.picture)) return;
    setShowCropImg(true);
  };

  const onImageLoad = () => {
    if (!imgRef?.current || !previewCanvasRef?.current || !completedCrop)
      return;
    canvasPreview(
      imgRef.current,
      previewCanvasRef?.current,
      completedCrop,
      1,
      0
    );
  };

  const handleFetchData = async (selectedConcept: Concept) => {
    dispatch(setSplashScreen(true));
    await fetchData(dispatch, selectedConcept.id);
    dispatch(setSplashScreen(false));
  };

  useEffect(() => {
    if (categories?.length > 0 || !selectedConcept) return;
    handleFetchData(selectedConcept);
  }, [selectedConcept]);

  useEffect(() => {
    if (!user?.pictureCrop) return;
    setCompletedCrop(JSON.parse(user.pictureCrop));
    if (!imgRef?.current || !previewCanvasRef?.current) return;
  }, [user]);

  useEffect(() => {
    if (!user) navigate("/");
  }, [user]);
  if (!user || splashScreen) return <SplashScreen />;
  // if (!user) return <></>;

  return (
    <>
      {splashScreen && <SplashScreen />}
      <OTP
        open={openOTP}
        phoneNumber={data?.phoneNumber}
        checkCode={checkCode}
        handleSuccessfulOTP={handleSuccessfulOTP}
        key={checkCode}
        onClose={() => setOpenOTP(false)}
      ></OTP>
      <Grid sx={{ visibility: splashScreen ? "hidden" : "visible" }}>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "1rem", paddingBottom: 0 }}
        >
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={confirmation}
            autoHideDuration={3000}
            onClose={() => {
              setConfirmation(false);
            }}
          >
            <Alert
              onClose={() => {
                setConfirmation(false);
              }}
              severity={success ? "success" : "error"}
              sx={{
                position: "fixed",
                top: "16px",
                right: "16px",
              }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => {
                    setConfirmation(false);
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              {message}
            </Alert>
          </Snackbar>
          <Grid item sm={12} lg={12}>
            <Link to="/">
              <Button
                sx={{
                  backgroundColor: "rgba(169, 169, 169, 0.55)",
                  color: "rgba(64, 64, 64, 0.7)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: " 2rem",
                  height: "2rem",
                  borderRadius: "0.5rem",
                  marginBottom: "1rem",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "#ffffff",
                }}
              >
                <ArrowBackIosNewIcon
                  sx={{
                    color: "#33BDE8",
                    fontSize: "1rem",
                    transform:
                      i18n.language === "Arabic" ? "rotate(180deg)" : "none",
                  }}
                />
              </Button>
            </Link>
          </Grid>
          <Grid item sm={12} lg={12} xs={12}>
            <Typography
              style={{
                color: theme.palette.primary.main,
                fontSize: 24,
                fontWeight: "600",
                letterSpacing: 0.96,
                marginBottom: "1rem",
                fontFamily: `bold${i18n.language}`,
              }}
            >
              {t("profile")}
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* image */}
          <Grid item>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                paddingLeft={"1.5rem"}
                // {...getRootProps({})}
              >
                <label ref={fileInputRef} htmlFor="capture-button">
                  <input
                    style={{ display: "none" }}
                    accept="image/*"
                    id="capture-button"
                    type="file"
                    {...getInputProps()}
                  />
                  <img
                    onClick={handleOnImgClick}
                    src={"assets/shape.png"}
                    alt="Selected"
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      top: "2.8rem",
                      transform: `translate(${
                        i18n.language === "Arabic" ? "12%" : "-24%"
                      })`,
                      scale: ".9",
                    }}
                  />

                  <Button
                    style={{ position: "relative", zIndex: 0 }}
                    variant="contained"
                    component="span"
                    sx={{
                      borderColor:
                        "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
                      clipPath: "polygon(85% 0, 36% 100%, 0 40%)", // Triangle shape
                      color: "#FFFFFF",
                      display: "flex",
                      alignItems: "center",
                      borderStyle: "solid",
                      height: "160px",
                      width: "160px",
                      position: "relative",
                      overflow: "hidden",
                      "& img": {
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      },
                    }}
                  >
                    {selectedImage && !imageUploaded ? (
                      imageLoading ? (
                        <SkeletonTheme
                          baseColor="#c0c0c0"
                          highlightColor="#e0e0e0"
                        >
                          {/* <SkeletonTheme baseColor="#FFDEAD" highlightColor="#F0F8FF"> */}
                          <Grid
                            container
                            justifyContent={"center"}
                            alignContent={"center"}
                          >
                            <Skeleton
                              className="shimmer"
                              circle
                              height={200}
                              width={200}
                            />
                          </Grid>
                        </SkeletonTheme>
                      ) : (
                        //    <img
                        //      src={`${cloudinaryURL}${selectedImage}`}
                        //      alt="Selected"
                        //      style={{
                        //          transition: "opacity 400ms ease 0ms",
                        //         }}
                        //         />
                        <canvas
                          ref={previewCanvasRef}
                          style={{
                            border: "1px solid black",
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 1,
                            left: 0,
                          }}
                        />
                      )
                    ) : imageUploaded ? (
                      <>
                        <canvas
                          ref={previewCanvasRef}
                          style={{
                            border: "1px solid black",
                            objectFit: "cover",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 1,
                            left: 0,
                          }}
                        />
                        {/* <img src={URL.createObjectURL(imageUploaded)} alt="Selected"/> */}
                      </>
                    ) : (
                      <>
                        {imageLoading ? (
                          <SkeletonTheme
                            baseColor="#c0c0c0"
                            highlightColor="#e0e0e0"
                          >
                            {/* <SkeletonTheme baseColor="#FFDEAD" highlightColor="#F0F8FF"> */}
                            <Grid
                              container
                              justifyContent={"center"}
                              alignContent={"center"}
                            >
                              <Skeleton
                                className="shimmer"
                                circle
                                height={200}
                                width={200}
                              />
                            </Grid>
                          </SkeletonTheme>
                        ) : (
                          // <img src={user?.picture ? `${cloudinaryURL}${user.picture}` : "assets/profile.svg"} alt="Selected"
                          //      />
                          <canvas
                            ref={previewCanvasRef}
                            style={{
                              border: "1px solid black",
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              top: 1,
                              left: 0,
                            }}
                          />
                        )}
                      </>
                    )}
                  </Button>
                </label>
              </Box>
              <Box paddingTop={"2rem"}>
                <Button onClick={handleEditImageClick} sx={{ mb: 4 }}>
                  <EditOutlinedIcon />
                </Button>
                <Typography
                  sx={{
                    fontFamily: `regular${i18n.language}`,
                    fontWeight: "500",
                    fontSize: 18,
                  }}
                >
                  {capitalizeSentence(user?.name ?? "User Name") || "User Name"}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* inputs */}
          <Grid item sm={12} sx={{ padding: "1.5rem" }}>
            <Typography sx={{ fontFamily: `regular${i18n.language}` }}>
              {t("Name")}
            </Typography>
            <Controller
              name="name"
              control={control}
              defaultValue={user.name}
              rules={{
                required: t("NameRequired"),
                minLength: {
                  value: 3,
                  message: t("Name3characters"),
                },
              }}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  placeholder={t("Name")}
                  fullWidth
                  defaultValue={user.name}
                  type="text"
                  onChange={(e) => {
                    const value = e.target.value;
                    const isValid = isValidEnglish(value); // Assuming this validation is still required
                    if (isValid) {
                      field.onChange(e); // Update form state
                      setMessage("");
                      setConfirmation(false);
                      setNameLastInput(value);
                    } else {
                      e.preventDefault();
                      field.onChange(nameLastInput);
                      setConfirmation(true);
                      setSuccess(false);
                      setMessage(
                        t("Only English letters and numbers are allowed")
                      );
                    }
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: theme.palette.primary.dark,
                      color: theme.palette.primary.main,
                      width: "100%",
                      margin: "0.5rem auto",
                      borderColor: "#ffffff",
                      fontFamily: `regular${i18n.language}`,
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adding box shadow
                      marginBottom: "1rem",
                    },
                  }}
                />
              )}
            />
            {errors.name && (
              <span style={{ color: "red" }}>{errors.name.message}</span>
            )}
            <Typography sx={{ fontFamily: `regular${i18n.language}` }}>
              {t("Mobile")}
            </Typography>
            <Controller
              name="phone_number"
              control={control}
              rules={{
                required: t("PhonRequired"),
                pattern: {
                  value: /^[0-9]{11}$/,
                  message: t("InvalidPhoneFormat"),
                },
              }}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  placeholder={t("Mobile")}
                  fullWidth
                  type="tel"
                  defaultValue={
                    user?.phone_number && user?.phone_number !== "00"
                      ? user.phone_number
                      : ""
                  }
                  onChange={(e) => {
                    const value = e.target.value;
                    const isValid = /^[0-9]{11}$/.test(value);
                    if (isValid || value === "") {
                      field.onChange(e); // Update form state
                      setMessage("");
                      setConfirmation(false);
                      setSuccess(false);
                    } else {
                      // Handle invalid input
                      field.onChange(e);
                      setMessage(t("InvalidPhoneFormat"));
                      setConfirmation(true);
                      setSuccess(false);
                    }
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: theme.palette.primary.dark,
                      color: theme.palette.primary.main,
                      fontFamily: `regular${i18n.language}`,
                      width: "100%",
                      margin: "0.5rem auto",
                      borderColor: "#ffffff",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      marginBottom: "1rem",
                    },
                  }}
                />
              )}
            />

            {errors.phone_number && (
              <span style={{ color: "red" }}>
                {errors.phone_number.message}
              </span>
            )}

            <Typography sx={{ fontFamily: `regular${i18n.language}` }}>
              {t("birthDate")}
            </Typography>
            <Controller
              name="birthdate"
              control={control}
              defaultValue={user.birthdate ? user.birthdate : ""}
              rules={{
                required: t("BirthRequired"),
              }}
              render={({ field }) => (
                <TextField
                  variant="outlined"
                  placeholder={t("birthDate")}
                  defaultValue={user.birthdate ? user.birthdate : ""}
                  fullWidth
                  type="date"
                  inputProps={{
                    min: calculateMinDate(),
                    max: calculateMaxDate(),
                  }}
                  onChange={(e) => {
                    const isValid = isValidEnglish(e.target.value);
                    if (isValid) {
                      field.onChange(e); // Update form state
                      setMessage("");
                      setConfirmation(false);
                    } else {
                      e.preventDefault();
                      setMessage(
                        t("Only English letters and numbers are allowed")
                      );
                      setConfirmation(true);
                      setSuccess(false);
                    }
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: theme.palette.primary.dark,
                      color: theme.palette.primary.main,
                      width: "100%",
                      margin: "0.5rem auto",
                      borderColor: "#ffffff",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                />
              )}
            />
            {errors.birthdate && (
              <span style={{ color: "red" }}>{errors.birthdate.message}</span>
            )}

            <Typography sx={{ fontFamily: `regular${i18n.language}` }}>
              {t("Email")}
            </Typography>
            <Controller
              name="email"
              control={control}
              defaultValue={user.email || ""}
              rules={{
                required: t("emailRequired"),
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/,
                  message: t("InvalidFormat"),
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  placeholder={t("Email")}
                  fullWidth
                  type="email"
                  onChange={(e) => {
                    const value = e.target.value;
                    const isValid = isValidEnglish(value); // Assuming this validation is still required
                    if (isValid) {
                      field.onChange(e); // Update form state
                      setMessage("");
                      setConfirmation(false);
                      // handleInput("email", value); // Uncomment if needed
                      setEmailLastInput(value); // Uncomment if needed
                    } else {
                      e.preventDefault();
                      // handleInput("email", emailLastInput); // Uncomment if needed
                      // setEmailLastInput(emailLastInput); // Uncomment if needed
                      field.onChange(emailLastInput);
                      setConfirmation(true);
                      setSuccess(false);
                      setMessage(
                        t("Only English letters and numbers are allowed")
                      );
                    }
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: theme.palette.primary.dark,
                      color: theme.palette.primary.main,
                      width: "100%",
                      margin: "0.5rem auto",
                      borderColor: "#ffffff",
                      fontFamily: `regular${i18n.language}`,
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adding box shadow
                      marginBottom: "1rem",
                    },
                  }}
                />
              )}
            />
            {errors.email && (
              <span style={{ color: "red" }}>{errors.email.message}</span>
            )}
            <LoadingButton
              variant="contained"
              type="submit"
              loading={loading}
              sx={{
                fontFamily: `regular${i18n.language}`,
                marginY: "2rem",
                fontSize: 22,
                width: "100%",
                color: "#FFFFFF",
                background:
                  "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
                boxShadow: "none",
                transition: "box-shadow 0.3s",
                borderRadius: "1rem",
                padding: "1rem",
                "&:hover": {
                  boxShadow: "0px 0px 5px 2px rgb(240,126,165,0.7)",
                  background:
                    "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
                },
              }}
            >
              {t("SaveChanges")}
            </LoadingButton>
          </Grid>
        </form>
      </Grid>
      {showCropImg && (
        <div className="overlay">
          <CropImage
            onClose={handleCloseShowCropImg}
            imgSrc={
              imageUploaded
                ? URL.createObjectURL(imageUploaded)
                : `${cloudinaryURL}${user.picture}`
            }
            previewCanvasRef={previewCanvasRef}
            handleCropChanged={handleCropChange}
            currentCrop={completedCrop}
            useCurrentCrop={useCurrentCrop}
          />
        </div>
      )}
      <img
        style={{ display: "none" }}
        width={180}
        height={228}
        ref={imgRef}
        alt="Crop me"
        onLoad={onImageLoad}
        src={
          imageUploaded
            ? URL.createObjectURL(imageUploaded)
            : `${cloudinaryURL}${user.picture}`
        }
      />
    </>
  );
}

export default UserPage;
