import { Concept, ZoneConcept } from "../models";

const isPlaceOpen = (openTime: string, closeTime: string) => {
  if (openTime === closeTime || !openTime || !closeTime) return true;

  let currentDateTime = new Date();
  let openDateTime = new Date(new Date().toDateString() + " " + openTime);
  let closeDateTime = new Date(new Date().toDateString() + " " + closeTime);

  // Adjust openTime if it's on the last day
  if (closeDateTime < openDateTime) {
    openDateTime.setDate(closeDateTime.getDate() - 1);
  }

  // Check if the current time is within the open-close range
  return currentDateTime >= openDateTime && currentDateTime <= closeDateTime;
};

const isConceptAvailable = (concept: Concept) => {
  // Check for open and close times
  if (!isPlaceOpen(concept.openTime, concept.closeTime)) return false;

  // Check for isBusy
  if (concept.isBusy) return false;

  // In case every condition is validated and passed successfully
  return true;
};

const getAvailableConcepts = (concepts: Concept[]) => {
  const availableConcepts: Concept[] = [];
  const fullConceptsList = concepts ?? [];

  fullConceptsList.forEach((concept: Concept) => {
    if (isConceptAvailable(concept)) {
      availableConcepts.push(concept);
    }
  });

  return availableConcepts;
};

const sortConceptsASCByDeliveryFee = (
  zoneConcepts: ZoneConcept[],
  concepts: Concept[]
): Concept[] => {
  if (!zoneConcepts?.length || !concepts?.length) return [];

  // Sort ZoneConcepts by deliveryFee
  const sortedZoneConcepts = structuredClone(zoneConcepts).sort(
    (a, b) => a.deliveryFee - b.deliveryFee
  );

  // Map ZoneConcepts to their corresponding concepts
  return sortedZoneConcepts
    .map((zc) => concepts.find((concept) => concept.id === zc.id))
    .filter((concept): concept is Concept => concept !== undefined);
};

const getNearestConcept = (
  zoneConcepts: ZoneConcept[],
  concepts: Concept[]
): Concept | undefined => {
  const sortedConcepts = sortConceptsASCByDeliveryFee(zoneConcepts, concepts);
  return sortedConcepts[0];
};

const isConceptWithinZone = (zoneConcepts: ZoneConcept[], concept: Concept) => {
  if (!zoneConcepts?.length || !concept) return [];
  const conceptExists = zoneConcepts.find(
    (zoneConcept) => zoneConcept?.id === concept?.id
  );
  return conceptExists ? true : false;
};

export {
  getAvailableConcepts,
  getNearestConcept, isConceptAvailable, isConceptWithinZone, isPlaceOpen, sortConceptsASCByDeliveryFee
};
