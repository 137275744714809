import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Category } from "../../models/Category";
import { cloudinaryURL } from "../../constants/Enums";
import abdo from "../../resources/Abdo.svg";
import pablo from "../../resources/Pablo.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";

export default function MenuPage() {
  const categories = useSelector(
    (state: any) => state.categoriesState.listing || []
  );
  const mode = useSelector((state: any) => state.mode);

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (categories.length === 0) {
      navigate("/");
    }
  }, [categories, navigate]);
  const { t, i18n } = useTranslation();

  function scrollToCategory(categoryId: any) {
    const targetElement = document.getElementById(categoryId);
    if (targetElement) {
      const targetPosition = targetElement.offsetTop - 180;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  }

  const handleCategoryClick = (categoryId) => {
    navigate("/");
    setTimeout(() => {
      scrollToCategory(categoryId);
    }, 300); // delay to ensure navigation completes before scrolling
  };

  const getArabicName = (multiLanguages) => {
    let parsedMultiLanguages = JSON.parse(multiLanguages);
    const arabicNameEntry = parsedMultiLanguages?.find(
      (entry) => entry[0] === "Arabic-name"
    );
    return arabicNameEntry ? arabicNameEntry?.[1] : "";
  };

  return (
    <>
        <Link to="/">
          <Button
            sx={{
              backgroundColor: "rgba(169, 169, 169, 0.55)",
              color: "rgba(64, 64, 64, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: " 2rem",
              height: "2rem",
              mt: 2,
              ml: 2,
              borderRadius: "0.5rem",
              marginBottom: ".5rem",
              "&:hover": {
                backgroundColor: "#33bde8",
              },
            }}
          >
            <ArrowBackIosNewIcon
              sx={{
                color: "#33BDE8",
                fontSize: "1rem",
                transform:
                  i18n.language === "Arabic" ? "rotate(180deg)" : "none",
              }}
            />
          </Button>
        </Link>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "10rem",
        }}
      >
        <Box
          component={"img"}
          src={pablo}
          alt="pablo"
          sx={{
            width: "12rem",
            height: "12rem",
          }}
        />
        <Box
          component={"img"}
          src={abdo}
          alt="abdo"
          sx={{
            width: "10rem",
            height: "10rem",
            mt: 2,
          }}
        />
      </Box>
      <Stack
        direction="column"
        sx={{
          backgroundColor: theme.palette.background.default,
          width: "100%",
          maxWidth: "100%",
          position: "relative",
          padding: "1rem",
        }}
        justifyContent={"start"}
      >
        
        {categories.map((category: Category) => (
          <Box
            key={category.id}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0",
            }}
          >
            <Typography
              sx={{
                color: "#84B2C2",
                fontSize: "14px",
                fontWeight: "bold",
                width: "30%",
                textAlign: "left",
                textDecoration: "underline",
                cursor: "pointer",
                mx: "10px",
                textTransform: "capitalize",
                ":hover": {
                  color: "white",
                },
              }}
              onClick={() => handleCategoryClick(category.id)}
            >
              {category.name}
            </Typography>
            <img
              src={
                mode === "dark"
                  ? `${cloudinaryURL}${category.image}`
                  : `${cloudinaryURL}${category.darkImage}`
              }
              alt=""
              style={{ width: "5rem", height: "5rem" }}
            />
            <Typography
              sx={{
                color: "#84B2C2",
                fontSize: "14px",
                fontWeight: "bold",
                width: "30%",
                textAlign: "right",
                textDecoration: "underline",
                cursor: "pointer",
                textTransform: "capitalize",
                ":hover": {
                  color: "white",
                },
              }}
              onClick={() => handleCategoryClick(category.id)}
            >
              {getArabicName(category.multiLanguages)}
            </Typography>
          </Box>
        ))}
      </Stack>
    </>
  );
}
