import { numberTranslation } from "../constants/helperFunction";
import { setDiscountedValue } from '../state/index';


// deliveryFee must be handled when zones are implemented
function reValidatePromocode (deliveryFee: number,orderValue: number, orderItemsQty: number, 
  promocode: any,dispatch: any ,channelName?: string | undefined, tender?: string | undefined) {
  try {
    if (!promocode) return;
    if (
      promocode.minOrderReq === "Minimum order value" &&
      promocode.minOrderVal > orderValue
      ) {
    throw new Error(
      `order value must be at least ${promocode.minOrderVal}`
      );
  }
  if (
    promocode.minOrderReq === "Minimum quantity of items" &&
    promocode.minOrderQty > orderItemsQty
    ) {
      throw new Error(
        `order items quantity must be at least ${promocode.minOrderQty}`
        );
      }
      if (channelName && !promocode.channelReq.includes(channelName)) {
        throw new Error("Sorry, you can't use this promocode with this channel");
      }
      if (tender && !promocode.tenderReq.includes(tender)) {
        throw new Error("Sorry, you can't use this promocode with this tender");
      }
      
      let totalDiscountedPrice;
      let discountedValue;
      if (promocode.appliesTo === "Whole cart") {
    const orderAmountBefDiscount = orderValue + deliveryFee;
    const discountedTotalPrice =
      orderAmountBefDiscount * (1 - promocode.discountVal / 100);

      totalDiscountedPrice =
      discountedTotalPrice > promocode.discountLimit
      ? promocode.discountLimit
      : discountedTotalPrice;
      
      // handle the case when discountVal is 100
      if (discountedTotalPrice === 0) {
        totalDiscountedPrice = promocode.discountLimit ? promocode.discountLimit : 0;
      }    
      
      discountedValue = orderAmountBefDiscount - totalDiscountedPrice;
    } else if (promocode.appliesTo === "Shipping fees") {
      const orderAmountBefDiscount = orderValue + deliveryFee;
      const discountedDeliveryFee =
      deliveryFee * (1 - promocode.discountVal / 100);
      
      totalDiscountedPrice =
      discountedDeliveryFee > promocode.discountLimit
      ? promocode.discountLimit + orderValue
      : discountedDeliveryFee + orderValue;
      
      // handle the case when discountVal is 100
      if (discountedDeliveryFee === 0) {
        totalDiscountedPrice = promocode.discountLimit ? promocode.discountLimit : 0;
      }    
      
      discountedValue = orderAmountBefDiscount - totalDiscountedPrice;
    }
    
    dispatch(setDiscountedValue(discountedValue));
  } catch (err) {
    console.log(`error validating promocode: ${err}`);
    dispatch(setDiscountedValue(null));
    throw err;
   }
  }
  
  
function constructPromocodeErrorMessage (messageReceived: any,t:any,i18n:any) {
        let errorMessage = messageReceived;
        const errorMessageArr = errorMessage.split(" ");
        const minValOrQty = errorMessageArr[1];
        const message = errorMessageArr.slice(0, -1).join(" ");
        const valOrQty: any = errorMessageArr[errorMessageArr.length - 1];
        if (errorMessage === "Sorry, you can't apply this promocode") {
          errorMessage = t("can't apply promocode");
        } else if (errorMessage === "Sorry, this promocode has expired") {
          errorMessage = t('expired promocode');
        } else if (errorMessage === "Sorry, you can't use this promocode with this channel") {
          errorMessage = t("can't apply promocode with channel");
        } else if (errorMessage === "Sorry, you can't use this promocode with this tender") {
          errorMessage = t("can't apply promocode with tender");
        } else if (minValOrQty === "items") {
          const num = numberTranslation(valOrQty, i18n.language);
          errorMessage = t(message) + ` ${num}`;
        } else if (minValOrQty === "value") {
          const num = numberTranslation(valOrQty, i18n.language);
          errorMessage = t(message) + ` ${num}`;
        } else {
          errorMessage = t('Invalid promocode');
  } 
  console.log(errorMessage)
  return errorMessage;
}
  

  export {
  constructPromocodeErrorMessage, reValidatePromocode
};
