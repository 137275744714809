import { HelipolisConcept } from "../constants/Enums";
import {
  setCategories,
  setConcept,
  setFetchingCategoriesID,
  setSelectedConcept,
  setZonesListing
} from "../state";
import { getCategoryData, getConcepts } from "./getOperation";
import listZones from "./listZones";

const fetchData = async (dispatch: any, conceptID: string) => {
  try {
    dispatch(setFetchingCategoriesID(conceptID));
    const [categories, zones] = await Promise.all([
      getCategoryData(conceptID) as Promise<any[]>,
      listZones(),
    ]);
    [].every;
    const filteredCatgs = categories.map((cat: any) => {
      const filteredMenuItems = cat.menuItems.filter(
        (item: any) => item.prices.length > 0
      );
      return { ...cat, menuItems: filteredMenuItems };
    });
    dispatch(setCategories(filteredCatgs));
    dispatch(setZonesListing(zones));
    // dispatch(setSplashScreen(false));
    return { categories: filteredCatgs, zones };
  } catch (error) {
    console.log("Error fetching getCategoryData:", error);
  }
};

const fetchConcepts = async (dispatch: any, selectedConcept: any) => {
  try {
    const concepts = await getConcepts();
    concepts.items.sort((a: any, b: any) => {
      return a.precedence - b.precedence;
    });
    dispatch(setConcept(concepts.items));
    if (!selectedConcept) {
      const helipolisConcept = concepts.items.find(
        (conceptItem: any) => conceptItem.id === HelipolisConcept.id
      );
      dispatch(
        setSelectedConcept(
          helipolisConcept ? helipolisConcept : concepts.items[0]
        )
      );
    }
  } catch (error: any) {
    console.log("Error fetching concepts: ", error);
  }
};

export { fetchConcepts, fetchData };
