import { LambdaFunctions, account } from "../constants/Enums";
import invokeLambda from "./invokeLambda";

async function createUser(
  user: any,
  group: string,
  phone_number: string,
  email_verified?: any,
  phone_number_verified?: any,
  attr?: {
    cognitoUsername: string;
    updated: "true" | "false";
  }
) {
  try {
    const accountID = account.accountID;
    // console.log(group);
    const operationId = 16;
    const userAttributes = {
      email: user.email,
      email_verified: email_verified,
      name: user.name ? user.name : "user",
      phone_number: phone_number
        ? phone_number.substring(2, phone_number.length + 1)
        : "",
      phone_number_verified: phone_number_verified,
      birthdate: user.birthdate,
      group: group,
      status: "registered",
      createdAt: new Date().toISOString(),
      createdByID: user.sub,
      createdByName: user.email,
      cognitoUsername: attr?.cognitoUsername,
      updated: attr?.updated,
    };
    const requestBody = {
      operationId,
      accountID: accountID,
      userID: user.sub,
      userAttributes: userAttributes,
    };
    return await invokeLambda(
      LambdaFunctions.callCenterOperations,
      requestBody
    );
  } catch (error) {
    console.error("Error creating user :", error);
    throw error;
  }
}

export default createUser;
