import { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import "./CropImage.css";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

interface CropImageProps {
  onClose: () => void;
  imgSrc: string;
  previewCanvasRef: any;
  handleCropChanged: (newCrop: PixelCrop) => void;
  currentCrop: PixelCrop;
  useCurrentCrop: boolean;
}

function CropImage({
  onClose,
  imgSrc,
  previewCanvasRef,
  handleCropChanged,
  currentCrop,
  useCurrentCrop,
}: CropImageProps) {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>(currentCrop);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>(currentCrop);

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 60,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (useCurrentCrop) return;
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  }

  useEffect(() => {
    setCrop(currentCrop);
  }, [useCurrentCrop]);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef?.current
      ) {
        handleCropChanged(completedCrop);

        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef?.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    [completedCrop]
  );

  const handleClickOutside = (event: any) => {
    if (event.target.className === "cropImage-overlay") {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Disable scrolling on the body element
    document.body.style.overflow = "hidden";

    // Add event listener to handle clicks outside the component
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function to re-enable scrolling and remove event listener
    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box>
      <div className="cropImage-overlay">
        <Box
          display="flex"
          flexDirection="column"
          alignItems={"center"}
          justifyContent={"center"}
          className="cropImage-container"
        >
          <ReactCrop
            crop={crop}
            aspect={1}
            maxWidth={180}
            minWidth={100}
            keepSelection
            onChange={(_, percentCrop) => {
              setCrop(percentCrop);
            }}
            onComplete={(c) => {
              return setCompletedCrop(c);
            }}
            // circularCrop
          >
            <img
              width={180}
              height={220}
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </Box>
      </div>
    </Box>
  );
}

export default CropImage;
