import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import axios from "axios";
import { Box, Button, Paper, Grid, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";

const containerStyle = {
  width: "100%",
  height: "27rem",
};

interface mapProps {
  onConfirm: (location: any) => void;
  zonesListing: any;
}

const Map: React.FC<mapProps> = ({ onConfirm, zonesListing }) => {
  const { t } = useTranslation();

  const [location, setLocation] = useState<any>(null);
  const [marker, setMarker] = useState<any>(null);
  const [searchValue, setSearchValue] = useState<any>(null);
  const [isWaitingForPermission, setIsWaitingForPermission] = useState(true);
  const [showGetLocation, setShowGetLocation] = useState(false);
  const [zoom, setZoom] = useState(15);

  const selectedAddress = useSelector((state: any) => state.selectedAddress);
  const [mapCenter, setMapCenter] = useState<any>(
    selectedAddress
      ? {
          lat: selectedAddress.address.latitude ?? 30.033,
          lng: selectedAddress.address.longitude ?? 31.233,
        }
      : location
      ? location.location
      : { lat: 30.033, lng: 31.233 }
  );

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAf2_iJNX-BrrTVjg288Vhr7miH_aotx8E",
    libraries: ["geometry"],
  });

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          searchValue
        )}&key=AIzaSyAf2_iJNX-BrrTVjg288Vhr7miH_aotx8E`
      );

      if (response.data.status === "OK") {
        const result = response.data.results[0];
        const location = result.geometry.location;
        setLocation({
          address: result.formatted_address,
          location: location,
        });
        setMapCenter(location);
      } else {
        console.error(
          "Geocoding request failed with status:",
          response.data.status
        );
      }
    } catch (error) {
      console.error("Error during geocoding request:", error);
    }
  };

  const onLoad = React.useCallback(
    function callback(map: any) {
      if (location && searchValue) {
        map.panTo(location.location);
      }
    },
    [location]
  );

  const onUnmount = React.useCallback(function callback(map: any) {
    // Do something when the map is unmounted
  }, []);

  const handleOnClick = async (e: any) => {
    setMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    const latitude = e.latLng.lat();
    const longitude = e.latLng.lng();

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAf2_iJNX-BrrTVjg288Vhr7miH_aotx8E`
      );

      if (response.data.status === "OK") {
        const result = response.data.results[0];
        setLocation({
          address: result.formatted_address,
          location: { lat: latitude, lng: longitude },
        });
      } else {
        console.error(
          "Reverse geocoding request failed with status:",
          response.data.status
        );
      }
    } catch (error) {
      console.error("Error during reverse geocoding request:", error);
    }
  };
  useEffect(() => {
    if (!selectedAddress && !location) {
      getUserLocation();
    }
  }, []); // Empty dependency array to run the effect only once when the component mounts

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowGetLocation(true);
    }, 500);

    // Clear the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      const successCallback = async (position: any) => {
        const { latitude, longitude } = position.coords;
        setMapCenter({ lat: latitude, lng: longitude });
        try {
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAf2_iJNX-BrrTVjg288Vhr7miH_aotx8E`
          );

          if (response.data.status === "OK") {
            const result = response.data.results[0];
            setLocation({
              address: result.formatted_address,
              location: { lat: latitude, lng: longitude },
            });
            setMarker({ lat: latitude, lng: longitude });
          } else {
            console.error(
              "Reverse geocoding request failed with status:",
              response.data.status
            );
          }
        } catch (error) {
          console.error("Error during reverse geocoding request:", error);
        }
        setZoom(18);
        setIsWaitingForPermission(false);
      };

      const errorCallback = (error: any) => {
        console.error("Error getting user location:", error);
        setIsWaitingForPermission(false);
      };

      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      console.error("Geolocation is not supported by this browser.");
      setIsWaitingForPermission(false);
    }
  };

  const handleConfirm = () => {
    if (location) {
      const point = new google.maps.LatLng(
        location.location.lat,
        location.location.lng
      );
      let validZone = zonesListing.filter((zone: any) => {
        if (zone.polygon) {
          let zonePolygon = JSON.parse(zone.polygon);
          let paths = zonePolygon.map(
            (coord: any) => new google.maps.LatLng(coord.lat, coord.lng)
          );
          const polygon = new google.maps.Polygon({ paths: paths });
          return google.maps.geometry.poly.containsLocation(point, polygon);
        } else {
          return false;
        }
      })[0];
      if (!validZone) {
        onConfirm("InvalidZone");
      } else {
        location.zone = validZone.location;
        location.zoneID = validZone.id;
        onConfirm(location);
      }
    } else {
      onConfirm(location);
    }
  };
  return (
    <Box height={"100%"} paddingBottom={"4rem"}>
      <Grid container marginTop={1}>
        <Grid item xs={9}>
          <Box sx={{ display: { sm: "flex" } }}>
            <Paper
              elevation={0}
              style={{
                width: "100%",
                height: "100%",
                background: "#393744",
                boxShadow:
                  "0px 0.9695248603820801px 8.725723266601562px rgba(0, 0, 0, 0.13)",
                borderRadius: 5.82,
              }}
              sx={{
                border: "1px solid",
                p: "1px 3px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: { xs: 2, sm: 0 },
                mr: { sm: 2 },
                flex: 1,
              }}
            >
              <SearchIcon sx={{ color: "grey.600", fontSize: 15 }} />
              <InputBase
                sx={{ ml: 1, flex: 1, fontSize: 12 }}
                fullWidth
                placeholder={t("search")}
                onChange={(event: any) => {
                  setSearchValue(event.target.value);
                }}
              />
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: " 2rem",
              height: "2rem",
              borderRadius: "0.5rem",
              marginBottom: "1rem",
              marginRight: ".5rem",
              marginLeft: ".5rem",
              backgroundColor: "rgba(169, 169, 169, 0.55)",
              color: "#33BDE8",
            }}
            onClick={() => {
              handleSearch();
            }}
          >
            {t("search")}
          </Button>
        </Grid>
      </Grid>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={(e) => {
            handleOnClick(e);
          }}
        >
          {
            <>
              <Marker position={marker ? marker : mapCenter} />

              {showGetLocation && (
                <Box
                  onClick={getUserLocation}
                  sx={{
                    position: "absolute",
                    bottom: "8rem",
                    right: "0.6rem",
                    backgroundColor: "white",
                    padding: "0.5rem",
                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <LocationSearchingIcon sx={{ color: "black" }} />
                </Box>
              )}
            </>
          }
        </GoogleMap>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Button
        style={{
          width: "100%",
          height: "3rem",
          background: "#33BDE8",
          boxShadow:
            "0px -2.9085745811462402px 13.573348045349121px rgba(255, 255, 255, 0.10)",
          borderRadius: 12.6,
          marginTop: "1rem",
        }}
        onClick={() => {
          handleConfirm();
        }}
      >
        CONFIRM
      </Button>
    </Box>
  );
};

export default Map;
