import { useState, useRef, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, useTheme, Typography, Box, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import './Redeem.css'
import RewardCard from "./RewardCard";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import RewardItem from "./RewardItem";

interface RedeemProps {
    onClose: () => void;
    onRewardItemClick: (item:RewardItemType) => void;
}
interface RewardItemType {
    id: string;
    name: string;
    description: string;
    points: number;
    redemptionPerUser: number;
    multiLanguages: any;
    image: string;
}

function Redeem({ onClose, onRewardItemClick }: RedeemProps) {
    const { t, i18n } = useTranslation();

    const [selectedItem, setSelectedItem] = useState<RewardItemType>({
        id: "",
        name: "",
        description: "",
        points: 0,
        redemptionPerUser: 0,
        multiLanguages: '',
        image: './assets/image1.png'
    });
    
    const theme = useTheme();
    const themeMode = useSelector((state: any) => state.mode);
    const rewardItems = useSelector((state: any) => state.rewardItems);
    
    const [showRewardCard, setShowRewardCard] = useState(false);
    const handleRewardCard = (item: RewardItemType) => {
        onRewardItemClick(item);
    };


    
    const handleClickOutside = (event: any) => {
        if (event.target.className === 'redeem-overlay') {
            onClose();
        }
    };
    
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    
    return (
        <Box>
            <div className="redeem-overlay" >
                <Box display="flex" flexDirection="column" className="redeem-container" sx={{ backgroundColor: themeMode === 'dark' ?  "#393744" : "#D3D3D3" }} >
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginY: "1rem", marginX:'1rem' }}>

                        <ArrowBackIosNewIcon
                            sx={{ color: "#33BDE8", fontSize: "1rem" }}
                            onClick={onClose}
                        />

                        <Typography
                            className="title2"
                            style={{
                                fontSize: '16',
                                fontWeight: "500",
                                color: theme.palette.primary.main ,
                                display: "flex",
                                alignItems: "space-between",
                                textTransform: "uppercase",
                            }}
                        >
                            {t("redeem")}
                            <img style={{
                                marginLeft: "0.3rem",
                                marginRight: "0.3rem",

                                width: "24px",
                                height: "24px",
                                verticalAlign: "middle",
                            }}
                                src="./assets/gold.svg" alt="" />
                        </Typography>
                        <Box>
                            <IconButton onClick={onClose}>
                                <CloseIcon  sx={{color: theme.palette.primary.main}} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            variant="h6"
                            color={theme.palette.primary.main}
                            fontSize={"17px"}
                            fontWeight={"500"}
                            mt={'1rem'}
                            textAlign={'center'}
                        >
                            {t("Keep earning points to unlock more rewards")}
                        </Typography>
                        {rewardItems.length > 0 ? (<Typography
                            variant="h6"
                            color={theme.palette.primary.main}
                            fontSize={"17px"}
                            fontWeight={"500"}
                            textAlign={'center'}
                            mt={'1rem'}
                            mb={'1rem'}
                        >
                            {t("All these rewards could be yours")}
                        </Typography>) : (<Typography
                            variant="h6"
                            color={theme.palette.primary.main}
                            fontSize={"17px"}
                            fontWeight={"500"}
                            textAlign={'center'}
                            mt={'1rem'}
                            mb={'1rem'}
                        >
                                {t("sorry there is no reward items")}
                        </Typography>)}
                       
                        
                    </Box>
                    <Grid container sx={{ marginY: "1rem", padding:'1rem', justifyContent: "space-between" }}>

                        {rewardItems.map((item: RewardItemType) => (
                        <Grid  key={item.id} item sm={5.7} xs={5.7} onClick={() => handleRewardCard(item)}>
                                <RewardItem name={item.name} description={item.description} points={item.points} multiLanguages={item.multiLanguages} image={item.image} />
                        </Grid>
                        ))}
                       
                    </Grid>

                </Box>
            </div >
        </Box >
    )
}

export default Redeem
