import { Category } from "../models";

export const capitalization = (str: string) => {
  if (!str || typeof str !== "string") return ""; // Check if str is null, undefined, or not a string
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function formatDate(dateString: Date, i18n?: any) {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = date.toLocaleString(
    i18n ? i18n.language.slice(0, 2).toLowerCase() : "en-us",
    options
  );
  return formattedDate;
}

export function numberTranslation(number: number | string, language: string) {
  if (language === "Arabic") {
    let stringNumber = "" + number;
    let output = "";
    for (let i = 0; i < stringNumber.length; i++) {
      switch (stringNumber[i]) {
        case "0":
          output += "٠";
          break;
        case "1":
          output += "١";
          break;
        case "2":
          output += "٢";
          break;
        case "3":
          output += "٣";
          break;
        case "4":
          output += "٤";
          break;
        case "5":
          output += "٥";
          break;
        case "6":
          output += "٦";
          break;
        case "7":
          output += "٧";
          break;
        case "8":
          output += "٨";
          break;
        case "9":
          output += "٩";
          break;
        default:
          output += stringNumber[i];
          break;
      }
    }
    return output;
  }
  return number;
}

/**
 * Capitalize first letter of a given string
 *
 * @param string string: string
 *
 * @returns string
 */
export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Capitalize Sentence

 * @param string : string
 *
 * @returns string
 */
export function capitalizeSentence(string: string): string {
  let result: string = "";

  const words: string[] = string.split(" ");

  for (let word of words) {
    result +=
      result.length === 0
        ? capitalizeFirstLetter(word)
        : " " + capitalizeFirstLetter(word);
  }

  return result;
}

// Function to validate input and prevent Arabic characters
export const isValidEnglish = (value: string) => {
  const valueArr = value.split("");
  for (let i = 0; i < valueArr.length; i++) {
    const char = valueArr[i];
    const regex = /^[^\u0600-\u06FF]+$/; // Disallows Arabic characters
    if (!regex.test(char)) return false;
  }
  return true;
};
// function to get menu item from categories array
export const getMenuItemById = (itemId: string, categories: any[]) => {
  for (const category of categories) {
    const foundItem = category.menuItems.find((item) => item.id === itemId);
    if (foundItem) {
      return foundItem;
    }
  }
  return undefined;
};

export const truncateToTwoDecimals = (num: number) => {
  return Math.floor(num * 100) / 100;
};
