import { LambdaFunctions } from "../constants/Enums";
import invokeLambda from "./invokeLambda";

async function cancelOrder(orderID: string, userID: string) {
  try {
    const operationId = 40;
    const requestBody = {
      operationId: operationId,
      orderID,
      userID,
    };
    return await invokeLambda(
      LambdaFunctions.callCenterOperations,
      requestBody
    );
  } catch (error) {
    console.error("Error creating online order :", error);
    throw error;
  }
}

export default cancelOrder;
