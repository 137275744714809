import WidgetsIcon from "@mui/icons-material/Widgets";
import {
    IconButton,
    Grid,
    Box,
    Typography,
    useTheme,
    Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EmptyCart = () => {
    const theme = useTheme();
    const themeMode = useSelector((state: any) => state.mode);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const categories = useSelector(
    (state: any) => state.categoriesState.listing || []
  );


    const handleOnClick = () => {
        navigate("/");
    }

    if (!categories) return <Navigate to={"/"} />;

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
                borderRadius: '0.3rem',
                marginY: '1rem',
                padding: '3rem 5rem',
                width: '100%',
                boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)', // Box shadow
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
            >
                <img
                    src="./assets/emptyCartLogo.png"
                    alt="emptyCartLogo"
                    style={{ marginBottom: '0.5rem' }}
                />
                <Typography
                    variant="h6"
                    color="primary.main"
                    sx={{
                      fontFamily:`bold${i18n.language}`,
                        marginTop: '0.5rem',
                    }}
                >
                    {t("cartEmpty")}
                </Typography>
                <Typography
                    variant="body1"
                    color="#D3D3D3"
                    sx={{
                      fontFamily:`bold${i18n.language}`,
                        marginTop: '0.2rem',
                        marginBottom: '1.5rem', // Adjusted margin
                    }}
                >
                    {t("havenAdded")}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOnClick}
                    sx={{
                        font: "0.4rem",
                        marginTop: "0.5rem",
                        padding: "1rem",
                        width: "100%",
                        // color: "#FFFFFF",
                        backgroundColor: themeMode === "light" ? "#F07EAA" : "#33bde8",
                    }}
                >
                  {t("BackToMenu")}
                </Button>
            </Box>
        </Grid>
    )
}

export default EmptyCart;
