import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isValidEnglish } from "../../constants/helperFunction";
import listZones from "../../services/listZones";
import updateUser from "../../services/updateUser";
import { setLogin, setZonesListing } from "../../state";
import Map from "./Map";

type Address = {
  zone: any;
  zoneID: string;
  streetName: any;
  buildingNumber: any;
  floorNumber: any;
  appartmentNumber: any;
  note: any;
  phoneNumber: any;
  default: any;
  latitude: any;
  longitude: any;
  AddressLabel: any;
  firstTime: boolean;
};

function NewAddress() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const windowLocation = useLocation();
  const selectedAddress = useSelector((state: any) => state.selectedAddress);

  const { t, i18n } = useTranslation();
  const [showTextFields, setShowTextFields] = useState(
    selectedAddress ? true : false
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm<Address>({});

  const handleInput = (fieldName: keyof Address, value: any) => {
    trigger(fieldName);
    setValue(fieldName, value);
  };

  const [location, setLocation] = useState<any>(
    selectedAddress
      ? {
          location: {
            lat: selectedAddress?.address?.latitude ?? 30.033,
            lng: selectedAddress?.address?.longitude ?? 31.233,
          },
          address: selectedAddress?.address?.AddressLabel,
          zone: selectedAddress?.address?.zone,
          zoneID: selectedAddress?.address?.zoneID,
        }
      : null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [validationWarning, setValidationWarning] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [buildingNumberLastInput, setBuildingNumberLastInput] = useState(
    selectedAddress?.address?.buildingNumber
      ? selectedAddress?.address?.buildingNumber
      : ""
  );
  const [appartmentNumberLastInput, setApartmentNumberLastInput] = useState(
    selectedAddress?.address?.appartmentNumber
      ? selectedAddress?.address?.appartmentNumber
      : ""
  );
  const [floorNumberLastInput, setFloorNumberLastInput] = useState(
    selectedAddress?.address?.floorNumber
      ? selectedAddress?.address?.floorNumber
      : ""
  );
  const [streetNameLastInput, setStreetNameLastInput] = useState(
    selectedAddress?.address?.streetName
      ? selectedAddress?.address?.streetName
      : ""
  );
  const [phoneNumberLastInput, setPhoneNumberLastInput] = useState(
    selectedAddress?.address?.phoneNumber
      ? selectedAddress?.address?.phoneNumber
      : ""
  );
  const [notesLastInput, setNotesLastInput] = useState(
    selectedAddress?.address?.note ? selectedAddress.address?.note : ""
  );

  const user = useSelector((state: any) => state.user);
  const zonesListing = useSelector((state: any) => state.zonesListing);

  const handleInputBlur = (fieldName: keyof Address) => {
    trigger(fieldName);
  };

  const fetchZones = async () => {
    let zones = await listZones();
    dispatch(setZonesListing(zones));
  };
  useEffect(() => {
    if (zonesListing.length === 0) {
      fetchZones();
    }
  }, []);

  const onSubmit = async (data: Address) => {
    if (user) {
      try {
        setIsLoading(true);
        data.latitude = location.location.lat;
        data.longitude = location.location.lng;
        data.AddressLabel = location.address;
        data.zone = location.zone;
        data.zoneID = location.zoneID;
        data.firstTime = true;
        let address: any = user.address ? JSON.parse(user.address) : [];
        if (address.length === 0) {
          data.default = true;
        }
        let newUser: any = null;
        if (!selectedAddress) {
          address.push(data);
          address = JSON.stringify(address);
          newUser = await updateUser({
            userID: user.id,
            version: user._version,
            email: user.email,
            name: user.name,
            phone_number: user.phone_number,
            address: address,
            status: user.status,
            birthdate: user.birthdate,
            gender: user.gender,
            picture: user.picture,
            pictureCrop: user.pictureCrop,
            phones: user.phones,
            deleted: user.deleted,
            createdAt: user.createdAt,
            email_verified: user.email_verified,
            phone_number_verified: user.phone_number_verified,
            resource: user,
          });
        } else {
          address[selectedAddress.index] = data;
          address = JSON.stringify(address);
          newUser = await updateUser({
            userID: user.id,
            version: user._version,
            email: user.email,
            name: user.name,
            phone_number: user.phone_number,
            address: address,
            status: user.status,
            birthdate: user.birthdate,
            gender: user.gender,
            picture: user.picture,
            pictureCrop: user.pictureCrop,
            phones: user.phones,
            deleted: user.deleted,
            createdAt: user.createdAt,
            email_verified: user.email_verified,
            phone_number_verified: user.phone_number_verified,
            resource: user,
          });
        }
        dispatch(setLogin({ user: newUser }));
        navigate("/address", {
          state: { prevSlug: windowLocation.state?.prevSlug || "/" },
        });
      } catch (error) {
        setIsLoading(false);
        console.error("Error updating user:", error);
      }
    }
  };
  const onConfirm = (location: any) => {
    if (location === "InvalidZone") {
      setMessage(t("Delivery is not available in this area"));
      setValidationWarning(true);
      return;
    }
    if (!location) {
      setMessage(t("Please pin your location on the map"));
      setValidationWarning(true);
      return;
    }
    setLocation(location);
    setShowTextFields(!showTextFields);
  };

  return (
    <Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ padding: "1rem" }}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={validationWarning}
          autoHideDuration={3000}
          onClose={() => {
            setValidationWarning(false);
          }}
        >
          <Alert
            onClose={() => {
              setValidationWarning(false);
            }}
            severity="warning"
            sx={{
              position: "fixed",
              top: "16px",
              right: "16px",
            }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  setValidationWarning(false);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Snackbar>
        <Grid item sm={12} lg={12} container justifyContent={"start"}>
          <Link
            to="/address"
            state={{ prevSlug: windowLocation.state?.prevSlug || "/" }}
          >
            <Button
              sx={{
                backgroundColor: "rgba(169, 169, 169, 0.55)",
                color: "rgba(64, 64, 64, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: " 2rem",
                height: "2rem",
                borderRadius: "0.5rem",
                marginBottom: "1rem",
              }}
            >
              <ArrowBackIosNewIcon
                sx={{
                  color: "#33BDE8",
                  fontSize: "1rem",
                  transform:
                    i18n.language === "Arabic" ? "rotate(180deg)" : "none",
                }}
              />
            </Button>
          </Link>
        </Grid>
        <Grid item sm={12} lg={12} xs={12}>
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontSize: 24,
              fontWeight: "600",
              letterSpacing: 0.96,
            }}
          >
            {t("NewAddress")}
          </Typography>
        </Grid>
        {!showTextFields && (
          <Grid item xs={12}>
            <Map zonesListing={zonesListing} onConfirm={onConfirm}></Map>
          </Grid>
        )}
        {/* //the address */}
        {showTextFields && (
          <Grid>
            <Grid
              container
              item
              alignItems="center"
              sm={12}
              sx={{
                marginY: "1rem",
                backgroundColor: theme.palette.primary.dark,
                padding: "0.5rem",
                borderRadius: "0.5rem",
              }}
            >
              <Grid item sm={2} xs={2}>
                <IconButton sx={{ backgroundColor: "#33BDE8" }}>
                  <LocationOnIcon
                    sx={{ color: "black", backgroundColor: "#33BDE8" }}
                  />
                </IconButton>
              </Grid>
              <Grid item sm={7} xs={7}>
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: 14,
                  }}
                >
                  {t("Area")}
                </Typography>
                <Typography
                  sx={{
                    color: "#9D9D9D",
                    fontSize: 10,
                  }}
                >
                  {location?.address ?? selectedAddress.address?.AddressLabel}
                </Typography>
              </Grid>
              <Grid item sm={2} xs={2}>
                <Button
                  sx={{ color: "#33BDE8", fontSize: 12 }}
                  onClick={() => {
                    setShowTextFields(!showTextFields);
                  }}
                >
                  {t("change")}
                </Button>
              </Grid>
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container item sm={12} sx={{}}>
                <Grid item sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    placeholder={t("Street")}
                    defaultValue={
                      selectedAddress ? selectedAddress.address?.streetName : ""
                    }
                    fullWidth
                    onBlur={() => handleInputBlur("streetName")}
                    onChange={(e) => {
                      const isValid = isValidEnglish(e.target.value);
                      if (isValid === true) {
                        handleInput("streetName", e.target.value);
                        setStreetNameLastInput(e.target.value);
                        setMessage("");
                        setValidationWarning(false);
                      } else {
                        e.preventDefault();
                        handleInput("streetName", streetNameLastInput);
                        setValidationWarning(true);
                        setMessage(
                          t("Only English letters and numbers are allowed")
                        );
                      }
                    }}
                    inputProps={{
                      ...register("streetName", {
                        required: t("streetNameRequired"),
                      }),
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.main,
                        width: "100%",
                        margin: "0.5rem auto",
                        borderRadius: "1rem",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "#ffffff",
                      },
                    }}
                  />
                  {errors.streetName && (
                    <span style={{ color: "red", fontSize: 12 }}>
                      {errors.streetName.message?.toString()}
                    </span>
                  )}
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    placeholder={t("Building")}
                    defaultValue={
                      selectedAddress
                        ? selectedAddress.address?.buildingNumber
                        : ""
                    }
                    fullWidth
                    onChange={(e) => {
                      const isValid = isValidEnglish(e.target.value);
                      if (isValid === true) {
                        handleInput("buildingNumber", e.target.value);
                        setBuildingNumberLastInput(e.target.value);
                        setMessage("");
                        setValidationWarning(false);
                      } else {
                        e.preventDefault();
                        handleInput("buildingNumber", buildingNumberLastInput);
                        setValidationWarning(true);
                        setMessage(
                          t("Only English letters and numbers are allowed")
                        );
                      }
                    }}
                    onBlur={() => handleInputBlur("buildingNumber")}
                    inputProps={{
                      ...register("buildingNumber", {
                        required: t("buildingNumberrRequired"),
                      }),
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.main,
                        width: "100%",
                        margin: "0.5rem auto",
                        borderRadius: "1rem",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "#ffffff",
                      },
                    }}
                  />
                  {errors.buildingNumber && (
                    <span style={{ color: "red", fontSize: 12 }}>
                      {errors.buildingNumber.message?.toString()}
                    </span>
                  )}
                </Grid>

                <Grid item sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    placeholder={t("Floor")}
                    fullWidth
                    defaultValue={
                      selectedAddress
                        ? selectedAddress.address?.floorNumber
                        : ""
                    }
                    onBlur={() => handleInputBlur("floorNumber")}
                    onChange={(e) => {
                      const isValid = isValidEnglish(e.target.value);
                      if (isValid === true) {
                        handleInput("floorNumber", e.target.value);
                        setFloorNumberLastInput(e.target.value);
                        setMessage("");
                        setValidationWarning(false);
                      } else {
                        e.preventDefault();
                        handleInput("floorNumber", floorNumberLastInput);
                        setValidationWarning(true);
                        setMessage(
                          t("Only English letters and numbers are allowed")
                        );
                      }
                    }}
                    inputProps={{
                      ...register("floorNumber", {
                        required: t("floorNumberRequired"),
                      }),
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.main,
                        width: "100%",
                        margin: "0.5rem auto",
                        borderRadius: "1rem",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "#ffffff",
                      },
                    }}
                  />
                  {errors.floorNumber && (
                    <span style={{ color: "red", fontSize: 12 }}>
                      {errors.floorNumber.message?.toString()}
                    </span>
                  )}
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    placeholder={t("Appartment")}
                    fullWidth
                    defaultValue={
                      selectedAddress
                        ? selectedAddress.address?.appartmentNumber
                        : ""
                    }
                    onBlur={() => handleInputBlur("appartmentNumber")}
                    onChange={(e) => {
                      const isValid = isValidEnglish(e.target.value);
                      if (isValid === true) {
                        handleInput("appartmentNumber", e.target.value);
                        setApartmentNumberLastInput(e.target.value);
                        setMessage("");
                        setValidationWarning(false);
                      } else {
                        e.preventDefault();
                        handleInput(
                          "appartmentNumber",
                          appartmentNumberLastInput
                        );
                        setValidationWarning(true);
                        setMessage(
                          t("Only English letters and numbers are allowed")
                        );
                      }
                    }}
                    inputProps={{
                      ...register("appartmentNumber", {
                        required: t("apartmentNumberRequired"),
                      }),
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.main,
                        width: "100%",
                        margin: "0.5rem auto",
                        borderRadius: "1rem",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "#ffffff",
                      },
                    }}
                  />
                  {errors.appartmentNumber && (
                    <span style={{ color: "red", fontSize: 12 }}>
                      {errors.appartmentNumber.message?.toString()}
                    </span>
                  )}
                </Grid>

                <Grid item sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    placeholder={t("PhoneNumber")}
                    fullWidth
                    defaultValue={
                      selectedAddress
                        ? selectedAddress.address?.phoneNumber
                        : ""
                    }
                    onBlur={() => handleInputBlur("phoneNumber")}
                    onChange={(e) => {
                      const isValid = isValidEnglish(e.target.value);
                      if (isValid === true) {
                        handleInput("phoneNumber", e.target.value);
                        setPhoneNumberLastInput(e.target.value);
                        setMessage("");
                        setValidationWarning(false);
                      } else {
                        e.preventDefault();
                        handleInput("phoneNumber", phoneNumberLastInput);
                        setValidationWarning(true);
                        setMessage(
                          t("Only English letters and numbers are allowed")
                        );
                      }
                    }}
                    inputProps={{
                      ...register("phoneNumber", {
                        required: t("PhonRequired"),
                        pattern: {
                          value: /^[0-9]{11}$/,
                          message: t("InvalidPhoneFormat"),
                        },
                      }),
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.main,
                        width: "100%",
                        margin: "0.5rem auto",
                        borderRadius: "1rem",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "#ffffff",
                      },
                    }}
                  />
                  {errors.phoneNumber && (
                    <span style={{ color: "red", fontSize: 12 }}>
                      {errors.phoneNumber.message?.toString()}
                    </span>
                  )}
                </Grid>
                {/* <Grid item sm={12} xs={12}>
              <TextField

                variant="outlined"
                placeholder={t("AddressLabel")}
                fullWidth
                onBlur={() => handleInputBlur("AddressLabel")}
                inputProps={{ ...register("AddressLabel") }}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.main,
                    width: "100%",
                    margin: "0.5rem auto",
                    borderRadius: "1rem",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "#ffffff",
                  },
                }}
              />
            </Grid> */}

                <Grid item sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    placeholder={t("AdditionalDirections")}
                    fullWidth
                    defaultValue={
                      selectedAddress ? selectedAddress.address?.note ?? "" : ""
                    }
                    onBlur={() => handleInputBlur("note")}
                    onChange={(e) => {
                      // const isValid = isValidEnglish(e.target.value);
                      // if (isValid === true) {
                      handleInput("note", e.target.value);
                      setNotesLastInput(e.target.value);
                      setMessage("");
                      setValidationWarning(false);
                      // } else {
                      //   e.preventDefault();
                      //   handleInput("note", notesLastInput);
                      //   setValidationWarning(true);
                      //   setMessage(
                      //     t("Only English letters and numbers are allowed")
                      //   );
                      // }
                    }}
                    inputProps={{ ...register("note") }}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.main,
                        width: "100%",
                        margin: "0.5rem auto",
                        borderRadius: "1rem",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "#ffffff",
                      },
                    }}
                  />
                </Grid>

                <Button
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                  sx={{
                    marginY: "2rem",
                    fontSize: 22,
                    width: "100%",
                    color: "#FFFFFF",
                    background: "#33BDE8",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s",
                    borderRadius: "1rem",
                    padding: "1rem",
                    "&:hover": {
                      boxShadow: "0px 0px 5px 2px rgb(240,126,165,0.7)",
                      background: "#33BDE8",
                    },
                  }}
                >
                  {t("SaveAddress")}
                </Button>
              </Grid>
            </form>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default NewAddress;
