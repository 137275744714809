import { EndPoints, LambdaFunctions } from "../constants/Enums";
import invokeLambda from "./invokeLambda";

async function getLoyaltyItems() {
    try {
        const operationId = 25;
        const requestBody = {
            operationId
        };
        const response = await invokeLambda(LambdaFunctions.callCenterOperations, requestBody);
        return response.items;
        } catch (error) {
          console.error("Error getting Loyalty items :", error);
        }
}
      
async function createLoyaltyRedeemOtp(
    userID: string,
    loyaltyItemID: string,
    loyaltyItemRedemptionPerUser: number,
    createdAt: string,
    conceptID:string
) {
  try {
    const operationId = 26
    const requestBody = {
      operationId,
        userID,
        loyaltyItemID,
        loyaltyItemRedemptionPerUser,
        createdAt,
        conceptID
    }
    return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody);
  } catch (error) {
    console.error('Error creating reward otp :', error)
    throw error
  }
}

async function getUserLoyaltyTransactions(userID: string, pageSize: number, page: number) {
    try {
        const operationId = 27;
        
        const requestBody = {
          operationId,
            userID,
            page,
            pageSize,
        };
      return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody);
        } catch (error) {
          console.error("Error listing user loyalty transactions :", error);
        }
}
    
export {
    getLoyaltyItems,
    createLoyaltyRedeemOtp,
    getUserLoyaltyTransactions
}