import { Grid, Typography } from "@mui/material";
import {
  capitalization,
  numberTranslation,
} from "../../constants/helperFunction";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOnlineOrder } from "../../services/getOperation";

interface TransactionItemProps {
  name: string;
  points: number;
  redeemedAt: string;
  channel: string;
  type: string;
  orderID: string;
  loyaltyMultiLanguages: any;
  multiLanguages: any;
  items: string;
}

const TransactionItem = ({
  name,
  points,
  redeemedAt,
  channel,
  type,
  orderID,
  multiLanguages,
  loyaltyMultiLanguages,
  items,
}: TransactionItemProps) => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const [orderMultiLanguages, setOrderMultiLanguages] = useState<any[]>([]);

  const handleTransactionClick = async () => {
    if (type !== "reward") return;
    const fetchedOrder = await getOnlineOrder(orderID);
    navigate("/orderDetails", { state: { selectedOrder: fetchedOrder } });
  };

  const handleGetOrderItemsNames = (items: string) => {
    const orderItemsMultiLanguages = [];
    let englishNames = "";
    let arabicNames = "";
    let maxNamesLength = 0;
    const itemNamesArr = items.split(",");
    const parsedMultiLanguages = new Map(JSON.parse(multiLanguages));
    for (const itemName of itemNamesArr) {
      if (!itemName) continue;
      maxNamesLength++;
      if (maxNamesLength > 1) break;
      englishNames += `${parsedMultiLanguages?.get(`English-${itemName}`)}, `;
      arabicNames += `${parsedMultiLanguages?.get(`Arabic-${itemName}`)}, `;
    }
    if (itemNamesArr.length > 1) {
      englishNames += "...";
      arabicNames += "...";
    }
    orderItemsMultiLanguages.push([`English-name`, englishNames]);
    orderItemsMultiLanguages.push([`Arabic-name`, arabicNames]);
    setOrderMultiLanguages(orderItemsMultiLanguages);
  };
  // const handleGetOrderItemsNames = (orderedItems: any[]) => {
  //     const orderItemsMultiLanguages = [];
  //     let englishNames = '';
  //     let arabicNames = '';
  //     let maxNamesLength = 0;
  //     for (const orderItem of orderedItems) {
  //         if (!orderItem.multiLanguages) continue;
  //         maxNamesLength++;
  //         if (maxNamesLength > 1) break;
  //         const parsedMultiLanguages = new Map(JSON.parse(orderItem.multiLanguages));
  //         englishNames += `${parsedMultiLanguages?.get(`English-name`)}, `;
  //         arabicNames += `${parsedMultiLanguages?.get(`Arabic-name`) }, `;
  //     }
  //     if (orderedItems.length > 1) {
  //         englishNames += '...';
  //         arabicNames += '...';
  //     }
  //     orderItemsMultiLanguages.push([`English-name`, englishNames]);
  //     orderItemsMultiLanguages.push([`Arabic-name`, arabicNames]);
  //     setOrderMultiLanguages(orderItemsMultiLanguages);
  // }

  useEffect(() => {
    handleGetOrderItemsNames(items);
  }, []);

  return (
    <Grid
      container
      display={"flex"}
      flexDirection={"row"}
      mb={"0.5rem"}
      gap={"3rem"}
      justifyContent={"space-between"}
      alignItems={"center"}
      onClick={handleTransactionClick}
    >
      <Grid item display={"flex"} flexWrap={"wrap"} flexDirection={"column"}>
        {type === "reward" ? (
          <Typography
            maxWidth={"10rem"}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {capitalization(
              String(
                new Map(orderMultiLanguages)?.get(`${i18n.language}-name`) || []
              ) ?? name
            )}
          </Typography>
        ) : (
          <Typography>
            {capitalization(
              String(
                new Map(JSON.parse(multiLanguages))?.get(
                  `${i18n.language}-${items}`
                ) || []
              ) ?? items
            )}
          </Typography>
        )}
        {/* {type === 'reward' ? (<Typography >{capitalization(String(new Map(orderMultiLanguages)?.get(`${i18n.language}-name`) || []) ?? name) }</Typography>) : (<Typography>{capitalization(String(new Map(JSON.parse(loyaltyMultiLanguages))?.get(`${i18n.language}-name`) || []) ?? name) }</Typography>)} */}
        <Typography color={"#878787"}>
          @{" "}
          {capitalization(
            String(
              new Map(JSON.parse(multiLanguages))?.get(
                `${i18n.language}-channel`
              ) || []
            ) ?? channel
          )}{" "}
          *
          {capitalization(
            String(
              new Map(JSON.parse(multiLanguages))?.get(
                `${i18n.language}-type`
              ) || []
            ) ?? type
          )}
        </Typography>
      </Grid>

      <Grid item display={"flex"} flexDirection={"column"}>
        <Typography>{`${type === "redeem" ? "-" : "+"}${numberTranslation(
          points,
          i18n.language
        )} ${t("Points")}`}</Typography>
        <Typography color={"#878787"}>
          {" "}
          {`${new Date(redeemedAt).toLocaleString(
            i18n.language.slice(0, 2).toLowerCase(),
            { month: "long" }
          )} ${numberTranslation(
            Number(new Date(redeemedAt).getDate()),
            i18n.language
          )}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TransactionItem;
