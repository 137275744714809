import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { uploadData } from "aws-amplify/storage";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  OrderReviewRate,
  OrderReviewRateAR,
  cloudinaryURL,
} from "../../constants/Enums";
import { capitalization } from "../../constants/helperFunction";
import createReview from "../../services/createReview";
import { setOrder } from "../../state";
import "./Review.css";

interface ReviewProps {
  onClose: () => void;
}

interface ItemReview {
  itemID: string;
  rate: number;
  satisfaction: string;
  multiLanguages: string;
}

function Review({ onClose }: ReviewProps) {
  const [review, setReview] = useState("");
  const [satisfaction, setSatisfaction] = useState("");
  const [multiLanguages, setMultiLanguages] = useState<string>("");
  const [rate, setRate] = useState<number>();
  const [errorMessage, setErrorMessage] = useState("");
  const [itemReviews, setItemReviews] = useState<ItemReview[]>([]);
  const [photos, setPhotos] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const errorMessageRef = useRef<HTMLElement>(null);
  const order = useSelector((state: any) => state.currentOrder);
  const orders = useSelector((state: any) => state.orders);
  const user = useSelector((state: any) => state.user);
  const defaultImage = "assets/image1.png";
  const theme = useTheme();
  const themeMode = useSelector((state: any) => state.mode);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const onDrop: any = useCallback(async (acceptedFiles: any) => {
    for (let i = 0; i < acceptedFiles.length; i++) {
      try {
        if (i === 5) break;
        const file = acceptedFiles[i];
        setPhotos((prev: any[]) => {
          if (prev.length >= 5) return prev;
          return [...prev, file];
        });
      } catch (err: Error | any) {
        console.log(err);
      }
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function formatDateToYYYYMMDDHHMMSS(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }

  const handleIconClick = (
    iconValue: string,
    rate: number,
    multiLanguages: any[]
  ) => {
    if (loading) return;
    setRate(rate);
    setSatisfaction(iconValue);
    setMultiLanguages(JSON.stringify(multiLanguages));
  };
  const handleItemIconClick = (
    iconValue: string,
    rate: number,
    itemID: string,
    multiLanguages: any[]
  ) => {
    if (loading) return;
    setItemReviews((prev: ItemReview[]) => {
      const newItemReview: ItemReview = {
        itemID: itemID,
        rate: rate,
        satisfaction: iconValue,
        multiLanguages: JSON.stringify(multiLanguages),
      };
      const otherUnchangedItemReviews = prev.filter(
        (item: ItemReview) => item.itemID !== itemID
      );
      return [...otherUnchangedItemReviews, newItemReview];
    });
  };
  const handleReviewChange = (event: any) => {
    if (loading) return;
    setReview(event.target.value);
  };
  const handleIsSatisfactionSelected = (
    itemID: string,
    itemSatisfaction: string
  ) => {
    const itemReview = itemReviews.find(
      (item: ItemReview) => item.itemID === itemID
    );
    if (!itemReview) return false;
    return itemReview.satisfaction === itemSatisfaction;
  };
  const handleSubmitReviewClicked = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    await submitReview();
  };
  const handleUploadImage = async (photo: any) => {
    try {
      if (!photo) return "";
      const result = await uploadData({
        key: `${new Date().getTime()}-${photo.name.replace(" ", "-")}`,
        data: photo,
      }).result;
      return result.key;
    } catch (err: Error | any) {
      console.log(err);
    }
  };
  const removePhoto = (indexToRemove: number) => {
    if (loading) return;
    setPhotos((prevPhotos) =>
      prevPhotos.filter((_, index) => index !== indexToRemove)
    );
  };

  const submitReview = async () => {
    try {
      setLoading(true);
      if (itemReviews.length !== order.orderedItems.length) {
        setErrorMessage("Please rate all items.");
        setLoading(false);
        return;
      }
      if (!rate) {
        setErrorMessage("Please rate your experience.");
        setLoading(false);
        return;
      }

      // upload the photos
      const uploadedPhotosPromises = [];
      for (const photo of photos) {
        uploadedPhotosPromises.push(handleUploadImage(photo));
      }
      const uploadedPhotos = await Promise.all(uploadedPhotosPromises);

      const reviewData = {
        userID: user.id,
        orderID: order.id,
        thoughts: review || "",
        rate: rate,
        satisfaction: satisfaction,
        itemReviews: itemReviews,
        photos: uploadedPhotos || [],
        multiLanguages: multiLanguages,
      };

      const data = {
        reviewData,
        createdByID: user.id,
        createdByName: user.name,
        createdAt: new Date().toISOString(),
      };

      const response = await createReview(data);

      const updatedOrderList = orders.map((order: any) => {
        if (order.id !== reviewData.orderID) return order;
        return { ...order, review: response, reviewID: response.id };
      });
      dispatch(setOrder(updatedOrderList));

      setErrorMessage("");
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
      console.error("Error submitting review:", error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (event.target.className !== "review-overlay") return;
      onClose();
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!errorMessage || !errorMessageRef.current) return;
    errorMessageRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [errorMessage]);

  return (
    <Box>
      <div className="review-overlay">
        <Box
          display="flex"
          flexDirection="column"
          className="review-container"
          sx={{
            backgroundColor: themeMode === "dark" ? "#1F1C2B" : "#F7F7F7",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="flex-end"
            sx={{ marginY: "1rem" }}
          >
            <Typography
              className="title2"
              style={{
                fontSize: 24,
                fontWeight: "700",
                color: theme.palette.primary.main,
                textAlign: "center",
              }}
            >
              {t("HowOrder")}
            </Typography>
            <Box>
              <IconButton onClick={onClose} sx={{ backgroundColor: "#00F0FF" }}>
                <CloseIcon className="close-icon" />
              </IconButton>
            </Box>
          </Box>

          {order?.orderedItems.map((item: any) => {
            return (
              <Grid
                key={item.menuItemID}
                container
                sx={{ marginY: "1rem", justifyContent: "space-between" }}
              >
                <Grid item xs={4}>
                  <img
                    //change when image is set
                    src={
                      item.image
                        ? `${cloudinaryURL}${item.image}`
                        : defaultImage
                    }
                    alt={item.name}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "1rem",
                      border: "0.1rem solid #33BDE8 ",
                      marginRight: "0.3rem",
                      marginLeft: "0.3rem",
                    }}
                  />
                </Grid>

                <Grid item xs={8} marginTop={"1rem"}>
                  <Typography
                    sx={{
                      fontSize: 18,
                      color: theme.palette.primary.main,
                      fontWeight: "600",
                      marginX: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    {capitalization(
                      new Map(JSON.parse(item.multiLanguages))?.get(
                        `${i18n.language}-${"name"}`
                      ) ?? item.name
                    )}
                  </Typography>

                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    sx={{ marginX: "0.6rem" }}
                  >
                    <SentimentVeryDissatisfiedIcon
                      onClick={() => {
                        const multiLanguages = [
                          [
                            `English-satisfaction`,
                            OrderReviewRate.veryDisSatisfied,
                          ],
                          [
                            `Arabic-satisfaction`,
                            OrderReviewRateAR.veryDisSatisfied,
                          ],
                        ];
                        handleItemIconClick(
                          OrderReviewRate.veryDisSatisfied,
                          1,
                          item.menuItemID,
                          multiLanguages
                        );
                      }}
                      sx={{
                        fontSize: 35,
                        color: handleIsSatisfactionSelected(
                          item.menuItemID,
                          OrderReviewRate.veryDisSatisfied
                        )
                          ? "red"
                          : "inherit",
                      }}
                    />

                    <SentimentDissatisfiedIcon
                      onClick={() => {
                        const multiLanguages = [
                          [
                            `English-satisfaction`,
                            OrderReviewRate.disSatisfied,
                          ],
                          [
                            `Arabic-satisfaction`,
                            OrderReviewRateAR.disSatisfied,
                          ],
                        ];
                        handleItemIconClick(
                          OrderReviewRate.disSatisfied,
                          2,
                          item.menuItemID,
                          multiLanguages
                        );
                      }}
                      sx={{
                        fontSize: 35,

                        color: handleIsSatisfactionSelected(
                          item.menuItemID,
                          OrderReviewRate.disSatisfied
                        )
                          ? "orange"
                          : "inherit",
                      }}
                    />

                    <SentimentSatisfiedIcon
                      onClick={() => {
                        const multiLanguages = [
                          [`English-satisfaction`, OrderReviewRate.neutral],
                          [`Arabic-satisfaction`, OrderReviewRateAR.neutral],
                        ];
                        handleItemIconClick(
                          OrderReviewRate.neutral,
                          3,
                          item.menuItemID,
                          multiLanguages
                        );
                      }}
                      sx={{
                        fontSize: 35,
                        color: handleIsSatisfactionSelected(
                          item.menuItemID,
                          OrderReviewRate.neutral
                        )
                          ? "yellow"
                          : "inherit",
                      }}
                    />
                    <SentimentSatisfiedAltIcon
                      onClick={() => {
                        const multiLanguages = [
                          [`English-satisfaction`, OrderReviewRate.satisfied],
                          [`Arabic-satisfaction`, OrderReviewRateAR.satisfied],
                        ];
                        handleItemIconClick(
                          OrderReviewRate.satisfied,
                          4,
                          item.menuItemID,
                          multiLanguages
                        );
                      }}
                      sx={{
                        fontSize: 35,
                        color: handleIsSatisfactionSelected(
                          item.menuItemID,
                          OrderReviewRate.satisfied
                        )
                          ? "lightgreen"
                          : "inherit",
                      }}
                    />

                    <SentimentVerySatisfiedIcon
                      onClick={() => {
                        const multiLanguages = [
                          [
                            `English-satisfaction`,
                            OrderReviewRate.verySatisfied,
                          ],
                          [
                            `Arabic-satisfaction`,
                            OrderReviewRateAR.verySatisfied,
                          ],
                        ];
                        handleItemIconClick(
                          OrderReviewRate.verySatisfied,
                          5,
                          item.menuItemID,
                          multiLanguages
                        );
                      }}
                      sx={{
                        fontSize: 35,
                        color: handleIsSatisfactionSelected(
                          item.menuItemID,
                          OrderReviewRate.verySatisfied
                        )
                          ? "green"
                          : "inherit",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            );
          })}

          <Grid item container xs={12} marginTop={"1rem"}>
            <Typography
              sx={{
                fontSize: 18,
                color: theme.palette.primary.main,
                fontWeight: "600",
                marginX: "1rem",
                marginBottom: "1rem",
              }}
            >
              {t("Rate your experience")}
            </Typography>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              sx={{ marginX: "0.6rem" }}
              gap={1}
            >
              <SentimentVeryDissatisfiedIcon
                onClick={() => {
                  const multiLanguages = [
                    [`English-satisfaction`, OrderReviewRate.veryDisSatisfied],
                    [`Arabic-satisfaction`, OrderReviewRateAR.veryDisSatisfied],
                  ];
                  handleIconClick(
                    OrderReviewRate.veryDisSatisfied,
                    1,
                    multiLanguages
                  );
                }}
                sx={{
                  fontSize: 35,
                  color:
                    satisfaction === OrderReviewRate.veryDisSatisfied
                      ? "red"
                      : "inherit",
                }}
              />

              <SentimentDissatisfiedIcon
                onClick={() => {
                  const multiLanguages = [
                    [`English-satisfaction`, OrderReviewRate.disSatisfied],
                    [`Arabic-satisfaction`, OrderReviewRateAR.disSatisfied],
                  ];
                  handleIconClick(
                    OrderReviewRate.disSatisfied,
                    2,
                    multiLanguages
                  );
                }}
                sx={{
                  fontSize: 35,

                  color:
                    satisfaction === OrderReviewRate.disSatisfied
                      ? "orange"
                      : "inherit",
                }}
              />

              <SentimentSatisfiedIcon
                onClick={() => {
                  const multiLanguages = [
                    [`English-satisfaction`, OrderReviewRate.neutral],
                    [`Arabic-satisfaction`, OrderReviewRateAR.neutral],
                  ];
                  handleIconClick(OrderReviewRate.neutral, 3, multiLanguages);
                }}
                sx={{
                  fontSize: 35,
                  color:
                    satisfaction === OrderReviewRate.neutral
                      ? "yellow"
                      : "inherit",
                }}
              />
              <SentimentSatisfiedAltIcon
                onClick={() => {
                  const multiLanguages = [
                    [`English-satisfaction`, OrderReviewRate.satisfied],
                    [`Arabic-satisfaction`, OrderReviewRateAR.satisfied],
                  ];
                  handleIconClick(OrderReviewRate.satisfied, 4, multiLanguages);
                }}
                sx={{
                  fontSize: 35,
                  color:
                    satisfaction === OrderReviewRate.satisfied
                      ? "lightgreen"
                      : "inherit",
                }}
              />

              <SentimentVerySatisfiedIcon
                onClick={() => {
                  const multiLanguages = [
                    [`English-satisfaction`, OrderReviewRate.verySatisfied],
                    [`Arabic-satisfaction`, OrderReviewRateAR.verySatisfied],
                  ];
                  handleIconClick(
                    OrderReviewRate.verySatisfied,
                    5,
                    multiLanguages
                  );
                }}
                sx={{
                  fontSize: 35,
                  color:
                    satisfaction === OrderReviewRate.verySatisfied
                      ? "green"
                      : "inherit",
                }}
              />
            </Box>
          </Grid>

          <Box display="flex"></Box>
          <Box>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder={t("ShareThougths")}
              fullWidth
              multiline
              rows={3}
              value={review}
              onChange={handleReviewChange}
              sx={{
                "& .MuiInputBase-root": {
                  color: theme.palette.primary.main,
                  width: "100%",
                  margin: "1rem auto",
                  // borderColor: theme.palette.primary.main,
                  borderWidth: "1px",
                  borderStyle: "solid",
                },
              }}
            />
          </Box>

          <Box>
            <Box>
              <label htmlFor="capture-button">
                <Button
                  variant="contained"
                  component="span"
                  sx={{
                    width: "100%",
                    marginY: "1rem",
                    backgroundColor: theme.palette.primary.dark,
                    borderRadius: "2rem",
                    // marginY: "0.5rem",
                    color: theme.palette.primary.main,
                    display: "flex",
                    alignItems: "center",
                    borderColor: theme.palette.primary.main,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    "&:hover": {
                      backgroundColor: "#393744",
                    },
                  }}
                >
                  <CameraAltIcon
                    style={{
                      color: theme.palette.primary.main,
                      marginRight: "0.5rem",
                      width: "28px",
                      height: "28px",
                      verticalAlign: "middle",
                    }}
                  />
                  {t("AddPhoto")}
                  <input
                    style={{ display: "none" }}
                    accept="image/*"
                    id="capture-button"
                    type="file"
                    {...getInputProps()}
                  />
                </Button>
              </label>
            </Box>

            <Box
              display={"flex"}
              flexDirection={"row"}
              padding={1}
              width={"100%"}
            >
              {photos.map((photo, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    width: "20%",
                    height: "5rem",
                    marginRight: "1rem",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={URL.createObjectURL(photo)}
                    alt="Selected"
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      cursor: "pointer",
                    }}
                  >
                    <CloseIcon
                      onClick={() => removePhoto(index)}
                      style={{ color: "white" }}
                      fontSize="small"
                    />
                  </div>
                </div>
              ))}
            </Box>
          </Box>
          <Button
            onClick={handleSubmitReviewClicked}
            disabled={loading}
            sx={{
              font: "0.3rem",
              width: "100%",
              color: theme.palette.primary.main,
              backgroundColor: themeMode === "light" ? "#F07EAA" : "#33bde8",
              boxShadow: "none",
              transition: "box-shadow 0.3s",
              borderRadius: "1rem",
              padding: "1rem",
              marginTop: "2rem",
              mb: "1rem",
              "&:hover": {
                boxShadow: "0px 0px 5px 2px rgba(240, 126, 170, 0.7)",
                backgroundColor: themeMode === "light" ? "#F07EAA" : "#33bde8",
              },
            }}
          >
            {t("continue")}
            {loading && (
              <CircularProgress size={30} sx={{ ml: 2, size: "1.5rem" }} />
            )}
          </Button>
          {errorMessage && (
            <Typography ref={errorMessageRef} mb={"0.5rem"} color={"red"}>
              {errorMessage}
            </Typography>
          )}
        </Box>
      </div>
    </Box>
  );
}

export default Review;
