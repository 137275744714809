export const colorTokens = {
  primary: {
    50: "#010E16",
    100: "#FFFFFF",
    200: "#B3B3B3",
    300: "#9B9B9B",
    400: "#D9D9D9",
    500: "#33BDE8",
    600: "#4A3838",
    700: "#E2E2E2",
    800: "#010E16",
    900: "#393744",
    1000: "#1F1C2B",
  },
};
export const darkColorTokens = {
  primary: {
    50: "#D9D9D9",
    100: "#B3B3B3",
    200: "#9B9B9B",
    300: "#222222",
    400: "#181818",
    500: "#ffffff",
    600: "#1F1D2B",
    700: "#393744",
    800: "#F7F7F7",
    900: "#00F0FF",
    1000: "#1F1C2B",
  },
};

export const themeSettings = (mode: any, loading: boolean) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              dark: colorTokens.primary[900],
              main: colorTokens.primary[100],
              light: colorTokens.primary[800],
            },
            background: {
              default: colorTokens.primary[1000],
            },
            text: {
              primary: "#FFFFFF", // Font color in dark mode
            },
          }
        : {
            primary: {
              dark: darkColorTokens.primary[500],
              main: darkColorTokens.primary[400],
              light: darkColorTokens.primary[50],
            },
            background: {
              default: loading
                ? colorTokens.primary[1000]
                : darkColorTokens.primary[800],
            },
            text: {
              primary: "#000000", // Font color in light mode
            },
          }),
    },
  };
};
