import { Box, Card, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalization, numberTranslation } from "../../constants/helperFunction";
import { useSelector } from "react-redux";
import { cloudinaryURL } from "../../constants/Enums";

interface RewardItemProp {
  name: string;
  description: string;
  points: number;
  multiLanguages: any;
  image: string;
}

const RewardItem = ({
  name,
  description,
  points,
  multiLanguages,
  image
}: RewardItemProp) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const user = useSelector((state: any) => state.user);
  const themeMode = useSelector((state: any) => state.mode);

  return (
    <Box
      sx={{
        padding: "4px",
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
        borderRadius: "20px",
        backgroundColor: themeMode === "dark" ? "#1f1c2b" : "white",
      }}
    >
      <Card
        sx={{
          backgroundColor: themeMode === "dark" ? "#1f1c2b" : "white",
          width: "100%",
          height: "100%",
          borderRadius: "20px",
          boxShadow: " 0px 4px 18px 0px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{
              position: "relative",
              backgroundColor:
                (user?.points ? user.points : 0) >= points ? "white" : "grey",
              width: "100%",
              borderRadius: "20px",
              overflow: "hidden",
              border: "0.1rem solid #33BDE8",
            }}
          >
            <img
              style={{
                // objectFit: "contain",
                objectFit: "cover",
                width: "100%",
                aspectRatio: "2/1",
                opacity: user?.points ? user.points : 0 >= points ? 1 : 0.4,
              }}
              src={`${cloudinaryURL}${image}`}
              alt="item"
            />

            {(user?.points ? user.points : 0) >= points ? (
              ""
            ) : (
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  fontSize: "48px",
                }}
              >
                <img alt="lock" src="./assets/lock.png" />
              </Box>
            )}
          </Box>
          <Box sx={{ padding: "0px 3px", paddingBottom: "5px" }}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              sx={{ padding: "10px 5px" }}
            >
              <Grid
                container
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Grid item xs={5}>
                  <Typography
                    variant="h6"
                    color={theme.palette.primary.main}
                    fontSize={"13px"}
                    fontWeight={"700"}
                  >
                    {capitalization(
                      String(
                        new Map(JSON.parse(multiLanguages))?.get(
                          `${i18n.language}-name`
                        ) || []
                      ) ?? name
                    )}
                  </Typography>
                </Grid>

                {/* <Grid item xs={6} > */}
                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: "13px",
                    fontWeight: "700",
                    backgroundColor: "#F07EAA",
                    padding: "0rem 0.5rem",
                    borderRadius: "2rem",
                    height: "20px",
                  }}
                  // fontFamily={`regular${i18n.language}`}
                >
                  {`${numberTranslation(points, i18n.language)} ${t("points")}`}
                </Typography>
                {/* </Grid> */}
              </Grid>
              <Typography
                variant="h6"
                color={"#9B9B9B"}
                fontSize={"9px"}
                fontWeight={"400"}
              >
                {String(
                  new Map(JSON.parse(multiLanguages))?.get(
                    `${i18n.language}-description`
                  ) || []
                ) ?? description}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default RewardItem;
