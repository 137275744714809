import { EndPoints, LambdaFunctions } from "../constants/Enums";
import invokeLambda from "./invokeLambda";

async function createReview(data:any) {
  try {
    const { reviewData, createdByID, createdByName, createdAt } = data;
    const operationId = 9;
    const requestBody = { operationId, reviewData, createdByID, createdByName, createdAt };
    return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody);
  } catch (error) {
    console.error('Error submitting review :', error);
    throw error;
  }
}

export default createReview;
