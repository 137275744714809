import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, useTheme } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import "../sidebar/sidebar.css";

function Language() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
      document.documentElement.setAttribute(
        "dir",
        storedLanguage === "Arabic" ? "rtl" : "ltr"
      );
    }
  }, [i18n]);

  const handleChange = (language: string) => {
    localStorage.setItem("language", language);
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    document.documentElement.setAttribute(
      "dir",
      language === "Arabic" ? "rtl" : "ltr"
    );
  };

  return (
    <Box
      onClick={() =>
        handleChange(selectedLanguage === "English" ? "Arabic" : "English")
      }
      display="flex"
      flexDirection="column"
      sx={{ padding: "0", width: "100%", cursor: "pointer" }}
    >
      <IconButton
        style={{
          color: theme.palette.primary.main,
          fontSize: 20,
          padding: "1rem",
          display: "flex",
          alignItems: "center",
          fontFamily: `bold${i18n.language}`,
          justifyContent: "flex-start",
        }}
      >
        <LanguageIcon
          className={`icons ${i18n.language === "Arabic" ? "ar" : ""}`}
        />
        {t("language")}
      </IconButton>
    </Box>
  );
}

export default Language;
