import { useState, useRef, useEffect, MouseEvent } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Card,
  useTheme,
  Typography,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";
import "./Redeem.css";
import {
  capitalization,
  numberTranslation,
} from "../../constants/helperFunction";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface RedeemProps {
  onClose: () => void;
}

function Redeemed({ onClose }: RedeemProps) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const themeMode = useSelector((state: any) => state.mode);
  const overlayRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (event.target.className === "redeem-overlay") {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box>
      <div className="redeem-overlay" ref={overlayRef}>
        <Box
          display="flex"
          flexDirection="column"
          className="redeem-container"
          sx={{ backgroundColor: themeMode === 'light' ?  "#393744" : "#D3D3D3" }}
        >
          <Box
            display="flex"
            justifyContent="end"
            alignItems="center"
            sx={{ marginY: "1rem", marginX:'1rem' }}
          >
            <Box>
              <IconButton onClick={onClose}>
                <CloseIcon sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Box>
          </Box>
          <Box>
          </Box>
          <Box display="flex" justifyContent="center" sx={{ mb: "1rem" }}>
            <img src="./assets/reward.png" alt="" />
          </Box>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "500",
                color: theme.palette.primary.main,
                marginX:'2rem'
              }}
            >
              {"Thank you for participating in our loyalty program"}
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "15px",
                fontWeight: "500",
                color: '#FFFFFF',
                marginX:'2rem',
                opacity: '0.70'
              }}
            >
              {"Enjoy your redeemed offer"}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" height={'5rem'}>
            <Button
              variant="contained"
              type="submit"
              onClick={onClose}
              sx={{
                marginY: "1rem",
                fontSize: 22,
                width: "70%",
                color: "#FFFFFF",
                background:
                  "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
                boxShadow: "none",
                transition: "box-shadow 0.3s",
                borderRadius: "1rem",
                padding: "1rem",
                "&:hover": {
                  boxShadow: "0px 0px 5px 2px rgb(240,126,165,0.7)",
                  background:
                    "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
                },
              }}
            >
              {`back to home`}
            </Button>
          </Box>
        </Box>
      </div>
    </Box>
  );
}

export default Redeemed;
