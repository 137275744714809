  // Function to calculate the maximum allowed birthdate (18 years ago from the current date)
  const calculateMaxDate = () => {
    const today = new Date();
    const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    return minDate.toISOString().split('T')[0];
  };

  // Function to calculate the minimum allowed birthdate (70 years ago from the current date)
  const calculateMinDate = () => {
    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 70, today.getMonth(), today.getDate());
    return maxDate.toISOString().split('T')[0];
};
  
export {
    calculateMinDate,
    calculateMaxDate
}