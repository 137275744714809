import axios from "axios";
import { EndPoints, LambdaFunctions } from "../constants/Enums";
import invokeLambda from "./invokeLambda";

type RequestData = {
  [key: string]: any; 
};

export async function getUserOrders(userID: string, limit: number, nextToken: any) {
  const operationId = 6;

  try {
    const requestBody = { operationId, userID, limit, nextToken }; // Include userID in the request body
    return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody);
  } catch (error) {
    console.error(
      `Error fetching user orders for user ID ${userID}:`,
      error
    );
    throw error;
  }
}
