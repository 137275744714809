import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  IconButton,
  Grid,
  Typography,
  useTheme,
  Button,
  Snackbar,
  Alert,
  TextField,
  InputAdornment,
  Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { confirmResetPassword, resetPassword,confirmSignIn, ConfirmSignInInput, SignInInput, signIn, fetchUserAttributes } from 'aws-amplify/auth'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import OtpInput from 'react-otp-input'
import { useDispatch } from 'react-redux'
import { setLogin } from '../../state'
import getUser from '../../services/getUser'
import { getUserByPhoneNumber } from '../../services/getOperation'



function LoginWithOTP() {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useSelector((state: any) => state.user)
  const [message, setMessage] = useState<any>('')
  const [validationWarning, setValidationWarning] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [otp, setOtp] = useState('')
  const [otpResent, setOtpResent] = useState(false)
  const [invalidOtpCount, setInvalidOtpCount] = useState(0);  
  const dispatch = useDispatch(); 
    
  const inputStyle = {
    background: '#393744',
    boxShadow:
      '0px -2.9085745811462402px 13.573348045349121px rgba(0, 0, 0, 0.10)',
    borderRadius: 10,
    border: '1px rgba(255, 255, 255, 0.40) solid',
    fontFamily: `regular${i18n.language}`,
    color: 'white',
    fontSize: 20,
    height: '2.7rem',
    width: '2.7rem',
  }


  const onSubmit = async (e:any) => {
      try {
        e.preventDefault()
      if (!otp || otp.length !== 4) {
        setOtpResent(false)
        setMessage('Verification code is required')
        setValidationWarning(true)
        return
      }
      setLoading(true)
        const confirmSignInInput: ConfirmSignInInput = {
            challengeResponse: otp
        }
      const output = await confirmSignIn(confirmSignInInput);
      if (!output || !output.isSignedIn) {
        throw new Error(t("Invalid verification code provided, please try again."));
      }     
      navigate('/')
      localStorage.setItem("user","true")
      dispatch(setLogin({ user: "" }));
      let loggedInUser:any = await fetchUserAttributes()
      // let currentUser = await getUser(loggedInUser.sub)
      if (!loggedInUser)throw new Error(t("Invalid verification code provided, please try again."));
      let currentUser:any= await getUserByPhoneNumber(loggedInUser.phone_number.substring(2,loggedInUser.phone_number.length+1))
      currentUser = currentUser.items[0];
      dispatch(setLogin({ user: currentUser }));
      setOtpResent(true)
      setInvalidOtpCount(0)
      setValidationWarning(true)
      setLoading(false)
    } catch (error:any) {
      setOtpResent(false)
      setInvalidOtpCount((prev) => prev + 1);
      setMessage(invalidOtpCount+1 >= 3 ? t("Invalid verification code provided, please click resend to get a new OTP.") : t("Invalid verification code provided, please try again."));
      setValidationWarning(true)
      setLoading(false)
      console.error('Error submitting user details:', error)
    }
  }


  const handleResend = async () => {
    try {
        setLoading(true)
        setInvalidOtpCount(0);
      let input: SignInInput = { ...location.state.input }
        let CognitoUser = await signIn(input);
        setMessage(t("verification code has been resent successfully"))
      setOtpResent(true)
      setValidationWarning(true)
      setLoading(false)
    } catch (err:any) {
      setLoading(false)
      setOtpResent(false)
      setMessage(t("Attempt limit exceeded, please try after some time."))
      setValidationWarning(true)
    }
  }

//   useEffect(() => {
//     if (user) {
//       navigate('/login')
//     }
//   }, [user])

  return (
    <Grid
      container
      alignItems="center"
      sx={{ padding: '1rem' }}
    >
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={validationWarning}
        autoHideDuration={3000}
        onClose={() => {
          setValidationWarning(false)
        }}
      >
        <Alert
          onClose={() => {
            setValidationWarning(false)
          }}
          severity={otpResent ? 'success' : 'warning'}
          sx={{
            position: 'fixed',
            top: '16px',
            right: '16px',
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setValidationWarning(false)
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Snackbar>

      <Grid item sm={12} lg={12}>
        <Link to="/loginWithoutPassword">
          <Button
            sx={{
              backgroundColor: 'rgba(169, 169, 169, 0.55)',
              color: 'rgba(64, 64, 64, 0.7)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: ' 2rem',
              height: '2rem',
              borderRadius: '0.5rem',
              marginBottom: '1rem',
            }}
          >
            <ArrowBackIosNewIcon
              sx={{
                color: '#33BDE8',
                fontSize: '1rem',
                transform:
                  i18n.language === 'Arabic' ? 'rotate(180deg)' : 'none',
              }}
            />
          </Button>
        </Link>
      </Grid>
      <Grid item sm={12} lg={12} xs={12}>
        <Typography
          style={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: '600',
            letterSpacing: 0.96,
          }}
        >
        {t("loginWithOtp")}
              </Typography>
              
      </Grid>
      <Grid container justifyContent={'center'} alignItems={'center'} height={'70vh'}>
        <Grid width={'100%'}>
        <Typography
          mb={1}
          style={{
            textAlign: 'center',
            color: 'white',
            fontSize: 18,
            fontFamily: `regular${i18n.language}`,
            fontWeight: '400',
            letterSpacing: 0.64,
            wordWrap: 'break-word',
          }}
        >
          {t('Enter Verification Code')}
          </Typography>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          renderSeparator={<Box width={'1rem'}> </Box>}
          renderInput={(props) => <input {...props} />}
          inputStyle={inputStyle}
          />
          </Box>
        <Box style={{ textAlign: 'center', marginTop:'1rem' }}>
          <span
            style={{
              color: 'white',
              fontSize: 14,
              fontFamily: `regular${i18n.language}`,
              fontWeight: '200',
              wordWrap: 'break-word',
            }}
          >
            {t('If you didn’t receive a code')},{' '}
          </span>
          <span
            onClick={() => {
              handleResend()
            }}
            style={{
              color: loading ? 'gray' : '#33BDE8',
              fontSize: 14,
              fontFamily: 'Inter',
              fontWeight: '200',
              wordWrap: 'break-word',
            }}
          >
            {t('Resend')}
          </span>
          </Box>
           <form
          style={{ width: '100%', marginTop: '2rem' }}
          onSubmit={onSubmit}
        >
          <Button
            variant="contained"
            type="submit"
            disabled={loading || invalidOtpCount >= 3}
            sx={{
              font: '0.3rem',
              fontFamily: `bold${i18n.language}`,
              width: '100%',
              color: '#FFFFFF',
              background:
                'linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)',
              boxShadow: 'none',
              transition: 'box-shadow 0.3s',
              borderRadius: '1rem',
              padding: '1rem',
              '&:hover': {
                boxShadow: '0px 0px 5px 2px rgb(240,126,165,0.7)',
                background:
                  'linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)',
              },
            }}
          >
            {t("loginWithOtp")}
          </Button>
        </form>
        </Grid>
        
      </Grid>
    </Grid>
  )
}

export default LoginWithOTP
