import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  IconButton,
  Grid,
  Typography,
  useTheme,
  Button,
  Snackbar,
  Alert,
  TextField,
  InputAdornment,
  Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import OtpInput from 'react-otp-input'

type ResetPasswordForm = {
  newPassword: string
}

function ResetPassword() {
  const theme = useTheme()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useSelector((state: any) => state.user)
  const [message, setMessage] = useState<any>('')
  const [validationWarning, setValidationWarning] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [otp, setOtp] = useState('')
  const [otpResent, setOtpResent] = useState(false)

  const inputStyle = {
    background: '#393744',
    boxShadow:
      '0px -2.9085745811462402px 13.573348045349121px rgba(0, 0, 0, 0.10)',
    borderRadius: 10,
    border: '1px rgba(255, 255, 255, 0.40) solid',
    fontFamily: `regular${i18n.language}`,
    color: 'white',
    fontSize: 20,
    height: '2.7rem',
    width: '2.7rem',
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<ResetPasswordForm>({}) // Specify the form data type

  const onSubmit = async (data: ResetPasswordForm) => {
    try {
      if (!otp || otp.length !== 6) {
        setOtpResent(false)
        setMessage('Verification code is required')
        setValidationWarning(true)
        return
      }
      setLoading(true)
      let input: any = {
        username: location.state.username,
        newPassword: data.newPassword,
        confirmationCode: otp,
      }
      const output = await confirmResetPassword({
        username: input.username,
        confirmationCode: input.confirmationCode,
        newPassword: input.newPassword,
      })
      setMessage(t('Your Password has been successfully updated'))
      setOtpResent(true)
      setValidationWarning(true)
      setLoading(false)
      navigate('/login')
    } catch (error) {
      setOtpResent(false)
      setMessage(t('Invalid verification code provided, please try again.'))
      setValidationWarning(true)
      setLoading(false)
      console.error('Error submitting user details:', error)
    }
  }

  const handleInputBlur = (fieldName: any) => {
    trigger(fieldName)
  }
  const handlePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  const handleResend = async () => {
    try {
      setLoading(true)
      await resetPassword({ username: location.state.username })
      setMessage(t('verification code has been resent successfully'))
      setOtpResent(true)
      setValidationWarning(true)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      setOtpResent(false)
      setMessage(t('Attempt limit exceeded, please try after some time.'))
      setValidationWarning(true)
    }
  }

  useEffect(() => {
    if (user) {
      navigate('/login')
    }
  }, [user])

  return (
    <Grid container sx={{ padding: '1rem' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={validationWarning}
        autoHideDuration={3000}
        onClose={() => {
          setValidationWarning(false)
        }}
      >
        <Alert
          onClose={() => {
            setValidationWarning(false)
          }}
          severity={otpResent ? 'success' : 'warning'}
          sx={{
            position: 'fixed',
            top: '16px',
            right: '16px',
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setValidationWarning(false)
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Snackbar>

      <Grid item sm={12} lg={12}>
        <Link to="/forgetPass">
          <Button
            sx={{
              backgroundColor: 'rgba(169, 169, 169, 0.55)',
              color: 'rgba(64, 64, 64, 0.7)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: ' 2rem',
              height: '2rem',
              borderRadius: '0.5rem',
              marginBottom: '1rem',
            }}
          >
            <ArrowBackIosNewIcon
              sx={{
                color: '#33BDE8',
                fontSize: '1rem',
                transform:
                  i18n.language === 'Arabic' ? 'rotate(180deg)' : 'none',
              }}
            />
          </Button>
        </Link>
      </Grid>
      <Grid item sm={12} lg={12} xs={12}>
        <Typography
          style={{
            color: theme.palette.primary.main,
            fontSize: 24,
            fontWeight: '600',
            letterSpacing: 0.96,
          }}
        >
          {t('Reset Password')}
        </Typography>
        {/* <Typography
          style={{
            color:theme.palette.primary.main,
            fontSize: 14,
            fontWeight: "600",
            letterSpacing: 0.96,
          }}
        >
                  {`Please enter the OTP you have received on your`}
        </Typography> */}
      </Grid>

      <Grid container justifyContent={'center'} alignItems={'center'} height={'70vh'}>
        <Grid>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
            mb={6}
            gap={2}
      >
        <Typography
          style={{
            textAlign: 'center',
            color: theme.palette.primary.main,
            fontSize: 18,
            fontFamily: `regular${i18n.language}`,
            fontWeight: '400',
            letterSpacing: 0.64,
            width:"100%",
            wordWrap: 'break-word',
          }}
        >
          {t('Enter Verification Code')}
        </Typography>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<Box width={'1rem'}> </Box>}
          renderInput={(props) => <input {...props} />}
          inputStyle={inputStyle}
        />
        <Box style={{ textAlign: 'center' }}>
          <span
            style={{
              color: theme.palette.primary.main,
              fontSize: 14,
              fontFamily: `regular${i18n.language}`,
              fontWeight: '200',
              wordWrap: 'break-word',
            }}
          >
            {t('If you didn’t receive a code')},{' '}
          </span>
          <span
            onClick={() => {
              handleResend()
            }}
            style={{
              color: loading ? 'gray' : '#33BDE8',
              fontSize: 14,
              fontFamily: 'Inter',
              fontWeight: '200',
              wordWrap: 'break-word',
            }}
          >
            {t('Resend')}
          </span>
        </Box>
      </Grid>
      <Grid container>
        <form
          style={{ width: '100%' }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid item sm={12} xs={12}>
            <TextField
              variant="outlined"
              placeholder={t('new password')}
              fullWidth
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handlePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register('newPassword', {
                required: t('new password is required'),
                minLength: {
                  value: 8,
                  message: t('Password8characters'),
                },
              })}
              onBlur={() => handleInputBlur('newPassword')}
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: 'white',
                  color: 'black',
                  borderRadius: '1rem',
                  width: '100%',
                  margin: '0.5rem auto',
                  borderColor: '#ffffff',
                  fontFamily: `regular${i18n.language}`,
                },
              }}
            />
            {errors.newPassword && (
              <span style={{ color: 'red', fontSize: 12 }}>
                {errors.newPassword.message}
              </span>
            )}
          </Grid>
          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            sx={{
              font: '0.3rem',
              fontFamily: `bold${i18n.language}`,
              width: '100%',
              color: '#FFFFFF',
              background:
                'linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)',
              boxShadow: 'none',
              transition: 'box-shadow 0.3s',
              borderRadius: '1rem',
              padding: '1rem',
              '&:hover': {
                boxShadow: '0px 0px 5px 2px rgb(240,126,165,0.7)',
                background:
                  'linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)',
              },
            }}
          >
            {t('Reset Password')}
          </Button>
        </form>
          </Grid>
          </Grid>
    </Grid>
    </Grid>
  )
}

export default ResetPassword
