import {
  Typography,
  Divider,
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import MobileFriendlyOutlinedIcon from "@mui/icons-material/MobileFriendlyOutlined";
import { useEffect, useState } from "react";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { setLogin } from "../../state/index";
import {
  signInWithRedirect,
  signOut,
  getCurrentUser,
  signIn,
  SignInInput,
  fetchUserAttributes,
  signUp,
} from "aws-amplify/auth";
import { AuthUser } from "aws-amplify/auth";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import getUser from "../../services/getUser";
import CloseIcon from "@mui/icons-material/Close";
import AppleIcon from "@mui/icons-material/Apple";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserByPhoneNumber } from "../../services/getOperation";
import withAuth from "../../HOC/WithAuth";

type LoginForm = {
  phoneNumber: string;
  password: string;
};

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user);

  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<LoginForm>({}); // Specify the form data type
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationWarning, setValidationWarning] = useState<boolean>(false);
  const [message, setMessage] = useState<any>("");

  const handleGoogleLogin = async () => {
    try {
      setLoading(true);
      await signInWithRedirect({ provider: "Google" });
      localStorage.setItem("user", "true");
      dispatch(setLogin({ user: "" }));
      setLoading(false);
    } catch (error: any) {
      console.error("Error logging in with Google:", error);
      setLoading(false);
      setMessage(error.message);
      setValidationWarning(true);
    }
  };
  const handleAppleLogin = async () => {
    try {
      setLoading(true);
      await signInWithRedirect({ provider: "Apple" });
      localStorage.setItem("user", "true");
      dispatch(setLogin({ user: "" }));
      setLoading(false);
    } catch (error: any) {
      console.error("Error logging in with Apple:", error);
      setLoading(false);
      setMessage(error.message);
      setValidationWarning(true);
    }
  };

  const handleInputBlur = (fieldName: any) => {
    trigger(fieldName);
  };

  const handlePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onSubmit = async (data: LoginForm) => {
    try {
      setLoading(true);
      let input: SignInInput = {
        username: "+2" + data.phoneNumber,
        password: data.password,
      };
      // let input: SignInInput = {
      //   username: "+2" + data.phoneNumber,
      //   // password: data.password
      //   // password: ' ',
      //   options: {
      //     authFlowType: "CUSTOM_WITHOUT_SRP"
      //   }
      // }
      let user = await signIn(input);
      localStorage.setItem("user", "true");
      let loggedInUser: any = await fetchUserAttributes();
      // let currentUser = await getUser(loggedInUser.sub)
      let currentUser: any = await getUserByPhoneNumber(
        loggedInUser.phone_number.substring(
          2,
          loggedInUser?.phone_number.length + 1
        )
      );
      currentUser = currentUser.items[0];
      dispatch(setLogin({ user: currentUser }));
      navigate("/");
    } catch (error: any) {
      const errMessage =
        error.name === "UserNotFoundException"
          ? t("userNotExist")
          : error.message;
      setMessage(errMessage);
      setValidationWarning(true);
      setLoading(false);
      console.error("Error submitting user details:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="center"
        alignItems="flex-end"
        alignContent="flex-end"
        sx={{
          textAlign: "center",
          minHeight: "100vh",
          backgroundImage: `url(../assets/login.webp)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingBottom: "2rem",
        }}
      >
        <Grid item sm={12} lg={12} sx={{ direction: "ltr" }}>
          <Link to="/">
            <Button
              sx={{
                position: "fixed",
                top: "16px",
                [i18n.language === "Arabic" ? "right" : "left"]: "16px",
                backgroundColor: "rgba(169, 169, 169, 0.55)",
                color: "rgba(64, 64, 64, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: " 2rem",
                height: "2rem",
                borderRadius: "0.5rem",
                marginBottom: ".5rem",
                "&:hover": {
                  backgroundColor: "#33bde8",
                },
              }}
            >
              <ArrowBackIosNewIcon
                sx={{
                  color: "#33BDE8",
                  fontSize: "1rem",
                  transform:
                    i18n.language === "Arabic" ? "rotate(180deg)" : "none",
                  zIndex: 9999, // Set a high value for z-index to ensure it's on top
                }}
              />
            </Button>
          </Link>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={validationWarning}
          autoHideDuration={3000}
          onClose={() => {
            setValidationWarning(false);
          }}
        >
          <Alert
            onClose={() => {
              setValidationWarning(false);
            }}
            severity="warning"
            sx={{
              position: "fixed",
              top: "16px",
              right: "16px",
            }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  setValidationWarning(false);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Snackbar>
        <Typography
          style={{
            fontSize: 20,
            fontWeight: 500,
            color: "white",
            fontFamily: `bold${i18n.language}`,
          }}
        >
          {t("Login")}
        </Typography>

        <Grid container sx={{ marginX: "1.5rem" }}>
          <Grid item sm={12} xs={12}>
            <TextField
              variant="outlined"
              placeholder={t("phoneNumber")}
              fullWidth
              {...register("phoneNumber", {
                required: t("PhonRequired"),
                pattern: {
                  value: /^[0-9]{11}$/,
                  message: t("InvalidPhoneFormat"),
                },
              })}
              onBlur={() => handleInputBlur("phoneNumber")}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "rgb(255,255,255,0.7)",
                  color: "black",
                  borderRadius: "1rem",
                  width: "100%",
                  margin: "0.5rem auto",
                  borderColor: "#ffffff",
                  fontFamily: `regular${i18n.language}`,
                },
              }}
            />
            {errors.phoneNumber && (
              <span style={{ color: "red", fontSize: 12 }}>
                {errors.phoneNumber.message}
              </span>
            )}
          </Grid>
          <Grid item sm={12} xs={12}>
            <TextField
              variant="outlined"
              placeholder={t("password")}
              fullWidth
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handlePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register("password", {
                required: t("PasswordRequired"),
                minLength: {
                  value: 8,
                  message: t("Password8characters"),
                },
              })}
              onBlur={() => handleInputBlur("password")}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "rgb(255,255,255,0.7)",
                  color: "black",
                  borderRadius: "1rem",
                  width: "100%",
                  margin: "0.5rem auto",
                  borderColor: "#ffffff",
                  fontFamily: `regular${i18n.language}`,
                },
              }}
            />
            {errors.password && (
              <span style={{ color: "red", fontSize: 12 }}>
                {errors.password.message}
              </span>
            )}

            <Link
              to="/forgetPass"
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: "white",
                fontFamily: `regular${i18n.language}`,
              }}
            >
              <Typography
                style={{
                  fontFamily: `regular${i18n.language}`,
                  textAlign: "center",
                }}
              >
                {t("ForgetPassword")}
              </Typography>
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          item
          justifyContent="center"
          sm={12}
          xs={12}
          sx={{ marginY: ".5rem", marginX: "1.5rem" }}
        >
          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            sx={{
              font: "0.3rem",
              fontFamily: `bold${i18n.language}`,
              width: "100%",
              color: "#FFFFFF",
              background:
                "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
              boxShadow: "none",
              transition: "box-shadow 0.3s",
              borderRadius: "1rem",
              padding: "1rem",
              "&:hover": {
                boxShadow: "0px 0px 5px 2px rgb(240,126,165,0.7)",
                background:
                  "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
              },
            }}
          >
            {!loading ? t("Loginn") : <CircularProgress />}
          </Button>

          <Typography
            style={{
              color: "white",
              fontFamily: `regular${i18n.language}`,
            }}
          >
            {t("NotRegistered")}
            <Link
              to="/register"
              style={{
                textDecoration: "none",
                color: "#03B6C1",
                fontFamily: `regular${i18n.language}`,
                marginRight: ".5rem",
                marginLeft: ".5rem",
              }}
            >
              {t("RegisterHere")}
            </Link>
          </Typography>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              cursor: "pointer",
              color: "#03B6C1",
              fontFamily: `regular${i18n.language}`,
            }}
          >
            <Typography
              style={{
                fontFamily: `regular${i18n.language}`,
              }}
            >
              {user ? t("Go to Menu") : t("Continue as a Guest")}
            </Typography>
          </Link>
        </Grid>

        <Grid container sx={{ marginX: "1rem" }}>
          <Grid item sm={4} xs={4}>
            <Divider
              sx={{
                backgroundColor: "white",
                marginTop: "0.6rem",
                height: "0.1rem",
              }}
            />
          </Grid>
          <Grid item sm={4} xs={4}>
            <Typography
              variant="body1"
              style={{
                color: "white",
                fontFamily: `regular${i18n.language}`,
              }}
            >
              {t("OrLoginWith")}
            </Typography>
          </Grid>
          <Grid item sm={4} xs={4}>
            <Divider
              sx={{
                backgroundColor: "white",
                marginTop: "0.6rem",
                height: "0.1rem",
              }}
            />
          </Grid>
        </Grid>

        {/* //Auth sign */}
        <Grid
          container
          sx={{ marginX: "1.5rem", marginTop: "1rem", alignItems: "center" }}
        >
          <Grid item sm={4} xs={4}>
            <Link
              to="/loginWithoutPassword"
              style={{
                textDecoration: "none",
                cursor: "pointer",
                color: "#03B6C1",
                fontFamily: `regular${i18n.language}`,
              }}
            >
              <MobileFriendlyOutlinedIcon sx={{ color: "white" }} />
            </Link>
          </Grid>
          <Grid item sm={4} xs={4}>
            <Button onClick={handleGoogleLogin} disabled={loading}>
              <img src="../assets/google.svg" alt="sing with google" />
            </Button>
            {/* <button onClick={() => signOut()}>Sign Out</button> */}
          </Grid>
          <Grid item sm={4} xs={4}>
            <Button onClick={handleAppleLogin} disabled={loading}>
              <img src="../assets/Vector.svg" alt="sing with google" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default withAuth(LoginPage);
