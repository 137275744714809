import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {
  FetchUserAttributesOutput,
  signOut
} from "aws-amplify/auth";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import createUser from "../../services/createUser";
import { getUserByPhoneNumber } from "../../services/getOperation";
import { sendOtpViaSMS } from "../../services/sendOTP";
import updateUser from "../../services/updateUser";
import { setLogin, setSelectedAddress } from "../../state";
interface LoggedInUser extends FetchUserAttributesOutput {
  username: string;
  group: string;
}
type Props = {
  open: boolean;
  group: string;
  loggedInUser: LoggedInUser;
  currentPhoneNumber?: string;
  onClose?: () => void;
  onSubmitOpenOtp?: () => void;
  onSubmitSetCheckCode?: (checkCode: string) => void;
  onDataChange?: (data: any) => void;
};

type RegisterForm = {
  phoneNumber: string;
};
function PhoneNumber({
  open,
  group,
  loggedInUser,
  currentPhoneNumber,
  onClose,
  onSubmitOpenOtp,
  onSubmitSetCheckCode,
  onDataChange
}: Props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  // const [openModel, setOpenModel] = useState(open);
  const [validationWarning, setValidationWarning] = useState<boolean>(false);
  const [message, setMessage] = useState<any>("");
  const [success, setSuccess] = useState(true);
  const [loading, setLoading] = useState(false);
  const [checkCode, setCheckCode] = useState<any>(null);
  const [openOTP, setOpenOTP] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<RegisterForm>({});

  const handleInputBlur = (fieldName: any) => {
    trigger(fieldName);
  };

  const onSubmit = async (data: RegisterForm) => {
    setLoading(true);
    onDataChange(data);
    setData(data);
    let isUser: any = await getUserByPhoneNumber(data.phoneNumber);
    isUser = isUser?.items?.[0];
    if (
      isUser &&
      isUser.status === "registered" &&
      isUser.cognitoUsername !== loggedInUser.username
    ) {
      setMessage(t("User with this phone number already exist"));
      setSuccess(false);
      setValidationWarning(true);
      setLoading(false);
      return;
    }
    try {
      const { instanceId, checkCode } = await sendOtpViaSMS(data.phoneNumber);
      // setOpenModel(false);
      // setOpenOTP(true);
      onSubmitOpenOtp();
      onSubmitSetCheckCode(checkCode);
      setLoading(false);
    } catch (error: any) {
      setSuccess(false);
      setMessage(t("Error sending OTP"));
      setValidationWarning(true);
      setLoading(false);
      handleClose();
    } finally {
      setLoading(false);
      onClose?.();
      onSubmitOpenOtp();
    }
  };

  const handleSuccessfulOTP = async () => {
    try {
      setLoading(true);
      let currentUser: any = await getUserByPhoneNumber(data.phoneNumber);
      currentUser = currentUser.items[0];
      if (currentUser) {
        if (!currentUser.status) {
          dispatch(setLogin({ user: currentUser }));
          let newUser = await updateUser({
            userID: currentUser.id,
            version: currentUser._version,
            email: currentUser.email || loggedInUser.email,
            name: currentUser.group ? currentUser.name : loggedInUser.name,
            phone_number: currentUser.phone_number,
            address: currentUser.address,
            status: "registered",
            birthdate: currentUser.birthdate,
            gender: currentUser.gender,
            picture: currentUser.picture,
            pictureCrop: currentUser.pictureCrop,
            phones: currentUser.phones,
            deleted: currentUser.deleted,
            createdAt: currentUser.createdAt,
            email_verified: true,
            phone_number_verified: true,
            group: loggedInUser.group,
            resource: currentUser,
            cognitoUsername: loggedInUser.username,
            updated: "true",
          });

          dispatch(setLogin({ user: newUser }));
        } else if (
          currentUser.status &&
          currentUser.cognitoUsername === loggedInUser.username
        ) {
          currentUser.group = loggedInUser.group;
          dispatch(setLogin({ user: currentUser }));
          let newUser = await updateUser({
            userID: currentUser.id,
            version: currentUser._version,
            email: currentUser.email,
            name: currentUser.group ? currentUser.name : loggedInUser.name,
            phone_number: currentUser.phone_number,
            address: currentUser.address,
            status: "registered",
            birthdate: currentUser.birthdate,
            gender: currentUser.gender,
            picture: currentUser.picture,
            pictureCrop: currentUser.pictureCrop,
            phones: currentUser.phones,
            deleted: currentUser.deleted,
            createdAt: currentUser.createdAt,
            email_verified: true,
            phone_number_verified: true,
            group: loggedInUser.group,
            resource: currentUser,
            cognitoUsername: loggedInUser.username,
            updated: "true",
          });

          dispatch(setLogin({ user: newUser }));
        } else {
          localStorage.removeItem("user");
          dispatch(setLogin({ user: null }));
          await signOut();
          setSuccess(false);
          setMessage(t("User with this phone number already exist"));
          setValidationWarning(true);
          return;
        }
      } else {
        let newUser = await createUser(
          loggedInUser,
          loggedInUser.group,
          "+2" + data.phoneNumber,
          true,
          true,
          { cognitoUsername: loggedInUser.username, updated: "true" }
        );

        newUser.group = loggedInUser.group;
        dispatch(setLogin({ user: newUser }));
      }
      // update user phone number - not working - replaced with a triggered lamda
      // try {
      //   await updateUserAttributes({
      //     userAttributes: {
      //       phone_number: "+2" + data.phoneNumber,
      //     },
      //   });
      //   // await updateUserAttribute({
      //   //     userAttribute: {
      //   //         attributeKey:"phone_number",
      //   //         value:"+2"+data.phoneNumber
      //   //     }
      //   // });
      // } catch (error: any) {
      //   console.log("error updating user attributes: ", error.message);
      //   setSuccess(false);
      //   setMessage(error.message);
      //   setValidationWarning(true);
      // }
    } catch (error: any) {
      setLoading(false);
      setSuccess(false);
      setMessage(error.message);
      setValidationWarning(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = async () => {
    setLoading(true);
    dispatch(setLogin({ user: null }));
    // updateOfflineCart([] , 0);
    dispatch(setSelectedAddress(null));
    localStorage.removeItem("user");
    await signOut();
    onClose?.();
    // setOpenModel(false);
    setLoading(false);
  };

  useEffect(() => {
    if (!currentPhoneNumber) return;
    onSubmit({ phoneNumber: currentPhoneNumber });
  }, [currentPhoneNumber]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={validationWarning}
        autoHideDuration={3000}
        onClose={() => {
          setValidationWarning(false);
        }}
      >
        <Alert
          onClose={() => {
            setValidationWarning(false);
          }}
          severity={success ? "success" : "error"}
          sx={{
            position: "fixed",
            top: "16px",
            right: "16px",
          }}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setValidationWarning(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {message}
        </Alert>
      </Snackbar>
      {/* <OTP
        open={openOTP}
        phoneNumber={
          currentPhoneNumber ? currentPhoneNumber : data?.phoneNumber
        }
        checkCode={checkCode}
        handleSuccessfulOTP={handleSuccessfulOTP}
        key={checkCode}
        onClose={() => {
          setOpenOTP(false);
        }}
      ></OTP> */}
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          alignItems: "flex-start",
        }}
        open={open}
      >
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            zIndex: 0,
            cursor: "pointer",
          }}
        />
        <Box
          sx={{
            position: "relative",
            top: "50%",
            width: "90%",
            transform: "translateY(-50%)",
            background: "black",
            padding: ".5rem",
            borderRadius: 5,
          }}
        >
          <Grid item xs={12}>
            <Typography
              style={{
                textAlign: "center",
                color: "white",
                fontSize: 16,
                fontFamily: `regular${i18n.language}`,
                fontWeight: "400",
                letterSpacing: 0.64,
                wordWrap: "break-word",
              }}
            >
              {t("Enter your Phone Number")}
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item sm={12} xs={12}>
              <TextField
                variant="outlined"
                placeholder={t("phoneNumber")}
                type="tel"
                fullWidth
                {...register("phoneNumber", {
                  required: t("PhonRequired"),
                  pattern: {
                    value: /^[0-9]{11}$/,
                    message: t("InvalidPhoneFormat"),
                  },
                })}
                onKeyDown={(e) => {
                  //for number input
                  const illegalChars = ["e", "-", "+", "E"];
                  if (illegalChars.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onBlur={() => handleInputBlur("phoneNumber")}
                // onWheel={(e) => {
                //   // @ts-ignore
                //   e.target.blur();
                // }}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "rgb(255,255,255,0.7)",
                    color: "black",
                    borderRadius: "1rem",
                    width: "100%",
                    margin: "1rem auto",
                    borderColor: "#ffffff",
                    fontFamily: `regular${i18n.language}`,
                  },
                }}
              />
              {errors.phoneNumber && (
                <span style={{ color: "red", fontSize: 12 }}>
                  {errors.phoneNumber.message?.toString()}
                </span>
              )}
            </Grid>
            <Grid container columnSpacing={"1rem"}>
              <Grid item xs={6}>
                <Button
                  style={{
                    width: "100%",
                    height: "3rem",
                    marginTop: "1rem",
                    background: "#F07EAA",
                    boxShadow:
                      "0px -2.9085745811462402px 13.573348045349121px rgba(255, 255, 255, 0.10)",
                    borderRadius: 12.6,
                  }}
                  onClick={() => {
                    handleClose();
                  }}
                  disabled={loading}
                >
                  {t("CANCEL")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                  type="submit"
                  style={{
                    width: "100%",
                    height: "3rem",
                    marginTop: "1rem",
                    background:
                      "linear-gradient(270deg, #F07EA5 0%, #33BDE8 59%, #33BDE8 73%)",
                    boxShadow:
                      "0px -2.9085745811462402px 13.573348045349121px rgba(255, 255, 255, 0.10)",
                    borderRadius: 12.6,
                  }}
                  loading={loading}
                >
                  {t("SEND")}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Backdrop>
    </>
  );
}
export default PhoneNumber;
