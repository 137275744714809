import { LambdaFunctions } from "../constants/Enums";
import invokeLambda from "./invokeLambda";

type RequestData = {
  [key: string]: any;
};

async function fetchData(operationId: number, requestData: RequestData = {}) {
  try {
    const requestBody = { operationId, ...requestData };
    return await invokeLambda(
      LambdaFunctions.callCenterOperations,
      requestBody
    );
  } catch (error) {
    console.error(
      `Error fetching data for operation ID ${operationId}:`,
      error
    );
    throw error;
  }
}

export async function getCategoryData(conceptID: string) {
  const operationId = 1;
  const requestData = { conceptID };
  return await fetchData(operationId, requestData);
}

export async function getCart() {
  const operationId = 2;
  return await fetchData(operationId);
}

export async function getConcept(conceptID?: string) {
  const operationId = 11;
  return await fetchData(operationId, { conceptID });
}

export async function getConcepts() {
  // Prod
  const operationId = 31;
  // localhost testing
  // const operationId = 42;
  return await fetchData(operationId);
}

export async function getMainCategories() {
  const operationId = 7;
  return await fetchData(operationId);
}

export async function getMenuItems() {
  const operationId = 3;
  return await fetchData(operationId);
}

export async function getSubCategories() {
  const operationId = 8;
  return await fetchData(operationId);
}
export async function getUserByPhoneNumber(phoneNumber: string) {
  const operationId = 24;
  const userAttributes = {
    phone_number: phoneNumber,
  };
  return await fetchData(operationId, { userAttributes });
}
export async function getOnlineOrder(orderID: string) {
  const operationID = 32;
  return await fetchData(operationID, { orderID });
}
export async function userExistsByPhoneNumber(phoneNumber: string) {
  const operationId = 38;
  const userAttributes = {
    phone_number: phoneNumber,
  };
  return (await fetchData(operationId, { userAttributes })) as {
    isExistingUser: boolean;
  };
}

// export async function getUserOrders() {
//   const operationId = 6;
//   return await fetchData(operationId);
// }
