import {
  Divider,
  Grid,
  Box,
  Typography,
  useTheme,
  Button,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getUserOrders } from "../../services/getUserOrders";
import { setCurrentOrder, setOrder } from "../../state/index";
import {
  capitalization,
  numberTranslation,
  formatDate,
} from "../../constants/helperFunction";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";
import { OrderStatus, cloudinaryURL } from "../../constants/Enums";
import CottageIcon from "@mui/icons-material/Cottage";

function OrderDetails() {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedOrder = location.state?.selectedOrder || {};
  const theme = useTheme();
  const orders = useSelector((state: any) => state.orders);
  const defaultImage = "assets/image1.png";

  function roundNumbers(number: any): any {
    return number.toFixed(2);
  }

  const handleTrackOrder = () => {
    dispatch(setCurrentOrder(selectedOrder));
    navigate("/ordertrack");
  };
  function handleOrderStatus(status: any): any {
    switch (status) {
      case OrderStatus.pending:
        return t("pending");
      case OrderStatus.cancelled:
        return t("cancelled");
      case OrderStatus.updated:
        return t("updated");
      case OrderStatus.sentToKitchen:
        return t("sentToKitchen");
      case OrderStatus.outForDelivery:
        return t("outForDelivery");
      case OrderStatus.delivered:
        return t("Delivered");
      case OrderStatus.deliveredAndSettled:
        return t("Delivered");
      default:
        return t("sentToKitchen");
    }
  }
  if (!selectedOrder || !selectedOrder.id) {
    return <Navigate to="/orders" />;
  }
  return (
    <Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: "1rem" }}
      >
        <Grid item sm={12} lg={12}>
          <Link to="/orders">
            <Button
              sx={{
                backgroundColor: "rgba(169, 169, 169, 0.55)",
                color: "rgba(64, 64, 64, 0.7)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: " 2rem",
                height: "2rem",
                borderRadius: "0.5rem",
                marginBottom: "1rem",
              }}
            >
              <ArrowBackIosNewIcon
                sx={{
                  color: "#33BDE8",
                  fontSize: "1rem",
                  transform:
                    i18n.language === "Arabic" ? "rotate(180deg)" : "none",
                }}
              />
            </Button>
          </Link>
        </Grid>
        <Grid item sm={12} lg={12} xs={12}>
          <Typography
            style={{
              color: theme.palette.primary.main,
              fontSize: 24,
              fontWeight: "600",
              letterSpacing: 0.96,
              fontFamily: `bold${i18n.language}`,
            }}
          >
            {t("OrderDetails")}
          </Typography>
        </Grid>

        <Grid container>
          <Grid container>
            <Grid
              container
              key={selectedOrder.id}
              sx={{
                backgroundColor: theme.palette.primary.dark,
                borderRadius: "0.3rem",
                marginY: "1rem",
                padding: "0.5rem",
                justifyContent: "space-around",
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)", // Box shadow
              }}
            >
              <Grid container item sm={12} xs={12}>
                <Grid item xs={8}>
                  <Typography
                    sx={{
                      color: selectedOrder.status ? "#33BDE8" : "#FF69B4",
                    }}
                  >
                    {handleOrderStatus(selectedOrder.status)}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#B3B3B3",
                      fontSize: 14,
                    }}
                  >
                    {formatDate(selectedOrder.createdAt, i18n)}
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={
                      selectedOrder?.orderTime.trim().toLowerCase() === "now"
                        ? "row"
                        : "column"
                    }
                    gap={
                      selectedOrder?.orderTime.trim().toLowerCase() === "now"
                        ? 1
                        : 0
                    }
                  >
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 14,
                      }}
                    >
                      {t("orderTime")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 14,
                      }}
                    >
                      {selectedOrder?.orderTime.trim().toLowerCase() === "now"
                        ? t("now")
                        : formatDate(new Date(selectedOrder?.orderTime), i18n)}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "#B3B3B3",
                      fontSize: 14,
                      fontFamily: `regular${i18n.language}`,
                    }}
                  >
                    {t("OrderID")}
                    {capitalization(selectedOrder.id.substring(0, 8))}
                  </Typography>
                  {selectedOrder.checkNumber && (
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 14,
                        fontFamily: `regular${i18n.language}`,
                      }}
                    >
                      {t("orderNumber")}
                      {capitalization(selectedOrder.checkNumber)}
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      color: "#B3B3B3",
                      fontSize: 14,
                      fontFamily: `regular${i18n.language}`,
                    }}
                  >
                    {t("orderType")}:&nbsp;
                    {selectedOrder.orderType === "delivery"
                      ? t("delivery")
                      : t("Pick-up")}
                  </Typography>
                </Grid>
                {selectedOrder.status !== OrderStatus.delivered &&
                  selectedOrder.status !== OrderStatus.cancelled && (
                    <Grid item container justifyContent={"center"} xs={4}>
                      <Button
                        sx={{ color: "#B3B3B3" }}
                        onClick={() => {
                          handleTrackOrder();
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#33BDE8",
                            fontFamily: `regular${i18n.language}`,
                          }}
                        >
                          {t("TrackOrder")}
                        </Typography>
                      </Button>
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <Divider
                    sx={{ borderColor: "#B3B3B3", borderWidth: "1px" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {selectedOrder.orderedItems.map((menuItem: any) => (
                    <Grid
                      key={`${menuItem.id}${Math.random()}`}
                      container
                      sx={{
                        marginTop: "1rem",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* the image */}
                      <Grid item sm={4} xs={4}>
                        <img
                          src={
                            `${cloudinaryURL}${menuItem.image}` || defaultImage
                          }
                          alt={menuItem.name}
                          style={{
                            width: "90%",
                            borderRadius: "1rem",
                            border: "0.1rem solid #33BDE8 ",
                          }}
                        />
                      </Grid>
                      {/* the info */}
                      <Grid
                        item
                        sm={8}
                        xs={8}
                        container
                        alignContent={"center"}
                        sx={{ paddingLeft: "0.5rem" }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              fontFamily: `regular${i18n.language}`,
                              color: theme.palette.primary.main,
                              fontWeight: 400,
                            }}
                          >
                            {capitalization(
                              new Map(JSON.parse(menuItem.multiLanguages))?.get(
                                `${i18n.language}-${"name"}`
                              ) ?? menuItem.name
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              color: "#B3B3B3",
                              fontSize: 15,
                              fontFamily: `regular${i18n.language}`,
                            }}
                          >
                            {numberTranslation(
                              roundNumbers(menuItem.price),
                              i18n.language
                            )}{" "}
                            {t("EGP")} x {menuItem.quantity}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {menuItem.choiceNames.map(
                          (choiceName: string, choiceIndex: number) => {
                            // Parse the JSON string into an object
                            const choiceGroupItem = JSON.parse(choiceName);

                            // Extract the choiceGroup name and choices names
                            const {
                              choiceGroupName,
                              choiceGroupMultiLanguages,
                              choices,
                            } = choiceGroupItem;

                            // Construct the display string for the choiceGroup and its choices
                            const choiceGroupDisplay = capitalization(
                              new Map(
                                JSON.parse(choiceGroupMultiLanguages)
                              )?.get(`${i18n.language}-name`) ?? choiceGroupName
                            );

                            const choiceDisplay = choices
                              .map((choice: any) =>
                                capitalization(
                                  new Map(
                                    JSON.parse(choice.multiLanguages)
                                  )?.get(`${i18n.language}-name`) ?? choice.name
                                )
                              )
                              .join(", ");

                            return (
                              <span key={choiceIndex}>
                                <span
                                  style={{
                                    color: "#33BDE8",
                                    fontSize: "1rem",
                                    fontWeight: "600",
                                  }}
                                >
                                  {choiceGroupDisplay}
                                </span>
                                : {choiceDisplay}
                                {choiceIndex < menuItem.choiceNames.length - 1
                                  ? " "
                                  : ""}
                              </span>
                            );
                          }
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider
                          sx={{ borderColor: "#B3B3B3", borderWidth: "1px" }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              {selectedOrder.orderAddress &&
                (selectedOrder.orderType === "delivery" ? (
                  <Grid
                    container
                    item
                    alignItems="center"
                    sm={12}
                    sx={{
                      backgroundColor: theme.palette.primary.dark,
                      padding: "0.5rem",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <Grid item sm={2} xs={2}>
                      <IconButton sx={{ backgroundColor: "#33BDE8" }}>
                        <LocationOnIcon
                          sx={{ color: "black", backgroundColor: "#33BDE8" }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item sm={7} xs={7}>
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontSize: 14,
                        }}
                      >
                        {JSON.parse(selectedOrder.orderAddress).streetName +
                          "," +
                          JSON.parse(selectedOrder.orderAddress).buildingNumber}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#9D9D9D",
                          fontSize: 10,
                        }}
                      >
                        {JSON.parse(selectedOrder.orderAddress).AddressLabel}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : selectedOrder.orderType === "pickup" ? (
                  <Grid
                    container
                    item
                    alignItems="center"
                    sm={12}
                    sx={{
                      backgroundColor: theme.palette.primary.dark,
                      padding: "0.5rem",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <Grid item sm={2} xs={2}>
                      <IconButton sx={{ backgroundColor: "#33BDE8" }}>
                        <CottageIcon
                          sx={{ color: "black", backgroundColor: "#33BDE8" }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item sm={7} xs={7}>
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontSize: 14,
                        }}
                      >
                        {selectedOrder.orderAddress}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                ))}
              {selectedOrder.specialRequest && (
                <>
                  <Grid item xs={12}>
                    <Divider
                      sx={{ borderColor: "#B3B3B3", borderWidth: "1px" }}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12} flexDirection="column">
                    <Grid
                      container
                      sx={{
                        borderRadius: "0.3rem",
                        marginTop: ".5rem",
                        display: "flex",
                        width: "100%",
                        flexDirection: "column",
                      }}
                    >
                      <Typography color={"#B3B3B3"} fontWeight={"bold"}>
                        {t("orderNotes")}
                      </Typography>
                      <Box>
                        <Typography color={"#B3B3B3"} fontWeight={"medium"}>
                          {selectedOrder.specialRequest}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
              {/* order summary */}
              <Grid item xs={12}>
                <Divider sx={{ borderColor: "#B3B3B3", borderWidth: "1px" }} />
              </Grid>
              <Grid item sm={12} xs={12} flexDirection="column">
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    borderRadius: "0.3rem",
                    marginTop: ".5rem",
                  }}
                >
                  {selectedOrder.orderType === "delivery" && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Typography
                        sx={{
                          color: "#B3B3B3",
                          fontSize: 18,
                          fontFamily: `regular${i18n.language}`,
                        }}
                      >
                        {t("Delivery")}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#B3B3B3",
                          fontSize: 18,
                        }}
                      >
                        {numberTranslation(
                          selectedOrder.deliveryFee,
                          i18n.language
                        )}{" "}
                        {t("EGP")}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 18,
                        fontWeight: 300,
                        fontFamily: `regular${i18n.language}`,
                      }}
                    >
                      {t("sub-tax-total")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 18,
                        fontWeight: 300,
                        fontFamily: `regular${i18n.language}`,
                      }}
                    >
                      {numberTranslation(14, i18n.language)}%
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 18,
                        fontWeight: 300,
                        fontFamily: `regular${i18n.language}`,
                      }}
                    >
                      {t("Total")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#B3B3B3",
                        fontSize: 18,
                        fontWeight: 300,
                        fontFamily: `regular${i18n.language}`,
                      }}
                    >
                      {numberTranslation(
                        selectedOrder.totalAmount,
                        i18n.language
                      )}{" "}
                      {t("EGP")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrderDetails;
