import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/joy";
import { Alert, Card, IconButton, Snackbar, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cloudinaryURL } from "../../constants/Enums";
import {
  capitalization,
  numberTranslation,
} from "../../constants/helperFunction";
import { MenuItem } from "../../models";
import { setSelectedItem } from "../../state";
import CounterComponent from "../countercomponent/CounterComponent";

interface FoodCardProps {
  image: string;
  name: string;
  description: string;
  newItem: Boolean;
  prices: { price: number }[];
  item: MenuItem;
  categoryID: string;
}

const FoodCard = memo(
  ({
    image,
    name,
    description,
    newItem,
    prices,
    item,
    categoryID,
  }: FoodCardProps) => {
    const [warning, setWarning] = useState(false);
    const [message, setMessage] = useState("");

    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const defaultImage = "assets/logo.svg";
    const price = prices.length > 0 ? prices[0].price : 0;
    let isOutOfStock =
      item.outOfStock || (item.hasLimitedQuantity && item.quantity === 0);

    const navigateToMenuItem = () => {
      if (isOutOfStock) {
        setWarning(true);
        setMessage(t("OutOfStockWarning", { name }));
        return;
      }
      dispatch(setSelectedItem(item));
      navigate(`/menu/${categoryID}/${item.id}`);
    };

    const handleWarningClose = () => {
      setWarning(false);
    };

    return (
      <Box
        sx={{
          padding: "4px",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={warning}
          autoHideDuration={3000}
          onClose={handleWarningClose}
        >
          <Alert
            onClose={handleWarningClose}
            severity="warning"
            sx={{
              position: "fixed",
              top: "16px",
              right: "16px",
            }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleWarningClose}
              >
                <CloseIcon
                  fontSize="small"
                  fontFamily={`regular${i18n.language}`}
                />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Snackbar>
        <Card
          sx={{
            backgroundColor: theme.palette.primary.dark,
            width: "100%",
            height: "100%",
            borderRadius: "20px",
            boxShadow: " 0px 4px 18px 0px rgba(0, 0, 0, 0.12)",
          }}
        >
          {/*main container*/}
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            {/*image container*/}
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{
                position: "relative",
                backgroundColor: "white",
                width: "100%",
                borderRadius: "20px",
                overflow: "hidden",
                border: "0.1rem solid #33BDE8",
              }}
            >
              <img
                style={{
                  // objectFit: "contain",
                  objectFit: "cover",
                  width: "100%",
                  aspectRatio: "2/1",
                }}
                onClick={navigateToMenuItem}
                src={`${cloudinaryURL}${image}` || defaultImage}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.src = defaultImage; // Fallback to default image on error loading API image
                }}
                alt={name}
              />
              {/* image overlay */}
              {(newItem || isOutOfStock) && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: isOutOfStock ? "#ff000078" : "#33BDE8",
                    color: isOutOfStock ? "white" : "#23222F",
                    padding: "5px 18px",
                    borderRadius: "0px 14.543px",
                    fontSize: "13.573px",
                    fontWeight: "600",
                  }}
                  onClick={navigateToMenuItem}
                >
                  {isOutOfStock ? "Not available" : "New"}
                </Typography>
              )}
            </Box>
            {/*info container*/}
            <Box sx={{ padding: "0px 3px", paddingBottom: "5px" }}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                sx={{ padding: "10px 5px" }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  onClick={navigateToMenuItem}
                >
                  <Typography
                    variant="h6"
                    color={theme.palette.primary.main}
                    fontSize={"13px"}
                    fontWeight={"700"}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {capitalization(name)}
                  </Typography>
                </Box>
                <Typography
                  variant="h6"
                  color={"#9B9B9B"}
                  fontSize={"9px"}
                  fontWeight={"400"}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {description === "" || description === " "
                    ? "."
                    : description}
                </Typography>
              </Box>

              {/*price container*/}
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                sx={{ marginRight: ".5rem", marginTop: "auto" }}
              >
                {price !== 0 ? (
                  <Typography
                    variant="h6"
                    color={theme.palette.primary.main}
                    fontSize={"13px"}
                    fontWeight={"700"}
                    paddingLeft={".5rem"}
                  >
                    {numberTranslation(price, i18n.language)} {t("EGP")}
                  </Typography>
                ) : (
                  <Typography
                    color={"#33BDE8"}
                    fontSize={"10px"}
                    fontWeight={"700"}
                    paddingLeft={".5rem"}
                  >
                    {t("Price on selection")}
                  </Typography>
                )}
                <CounterComponent
                  itemID={item.id}
                  categoryID={categoryID}
                  item={item}
                  setMessage={(message: string) => setMessage(message)}
                  openSnack={() => setWarning(true)}
                />
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  }
);

export default FoodCard;
