import { EndPoints ,LambdaFunctions,account} from "../constants/Enums";
import axios from "axios";
import invokeLambda from "./invokeLambda";

async function getUser(
    userID:any,
) {
  try {
    const operationId = 18;
    const requestBody = {
      operationId,
      userID,
    };
    return await invokeLambda(LambdaFunctions.callCenterOperations, requestBody);
  } catch (error) {
    console.error("Error getting user :", error);
    throw error;
  }
}

export default getUser;
