import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
import StarIcon from "@mui/icons-material/Star";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import { generateClient } from "aws-amplify/api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { OnlineOrder } from "../../API";
import Navbar from "../../components/Nav/Navbar";
import MySkeleton from "../../components/Skeleton/OrderHistorySkeleton";
import { OrderStatus, cloudinaryURL } from "../../constants/Enums";
import {
  capitalization,
  formatDate
} from "../../constants/helperFunction";
import { onUpdateOnlineOrder } from "../../graphql/subscriptions";
import cancelOrder from "../../services/cancelOrder";
import { getUserOrders } from "../../services/getUserOrders";
import {
  setCurrentOrder,
  setOrder,
  setOrdersNextToken,
} from "../../state/index";
import Review from "../Review/Review";
const API = generateClient();

function OrderHistory() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [showReview, setShowReview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelling, setIsCancelling] = useState("");
  const [cancellation, setCancellation] = useState(false);
  const orders = useSelector((state: any) => state.orders);
  const user = useSelector((state: any) => state.user);
  const ordersNextToken = useSelector((state: any) => state.ordersNextToken);
  // const userID = user.id;

  const extractItemName = ({ item }: { item: { orderedItems: any[] } }) => {
    const subItems = item.orderedItems;
    const seperator = i18n.language === "Arabic" ? "،" : ",";
    const name: string =
      new Map(JSON.parse(subItems[0]?.multiLanguages))?.get(
        `${i18n.language}-${"name"}`
      ) ?? subItems[0].name;

    if (subItems.length > 1) {
      return `${name?.trim()}${seperator} ...`;
    } else {
      return name?.trim();
    }
  };
  const defaultImage = "assets/image1.png";
  const handleCancelOrder = async (item: any) => {
    try {
      setIsCancelling(item.id);
      const status = await cancelOrder(item.id, user.id);
    } catch (error) {
      console.error(
        "OrderHistory.tsx",
        " :: OrderHistory() :: Error ❌ : ",
        error
      );
    } finally {
      setIsCancelling("");
    }
  };
  const handleReviewClick = (item: any) => {
    dispatch(setCurrentOrder(item));
    setShowReview(true);
  };
  const closeReviewForm = () => {
    setShowReview(false);
  };

  const handleOrderItemClick = (order: any) => {
    navigate("/orderDetails", { state: { selectedOrder: order } });
  };

  const handleReorderClick = (order: any) => {
    navigate("/cart", { state: { CartData: order } });
  };
  const fetchData = async () => {
    try {
      if (orders && orders.length > 0) return;
      setIsLoading(true);
      const response = await getUserOrders(user.id, 50, ordersNextToken);
      dispatch(setOrder(response.items));
      dispatch(setOrdersNextToken(response.nextToken));
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching getOdersData:", error);
    }
  };

  const fetchMoreOrders = async () => {
    const fetchedOrders = await getUserOrders(user.id, 50, ordersNextToken);
    dispatch(setOrdersNextToken(fetchedOrders.nextToken));
    dispatch(setOrder([...orders, ...fetchedOrders.items]));
  };

  useEffect(() => {
    if (localStorage.getItem("user") && !user) {
      setIsLoading(true);
      return;
    } else if (user) {
      fetchData();
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user) return;
    navigate("/");
  }, []);

  //subscription to listen to order updates and immediately update the order list
  useEffect(() => {
    const subscription = API.graphql({ query: onUpdateOnlineOrder });

    if ("subscribe" in subscription) {
      const orderSubscription = subscription.subscribe({
        next: (event: any) => {
          const updatedOrder = event.data.onUpdateOnlineOrder;
          const existingOrderIndex = orders.findIndex(
            (order: OnlineOrder) => order?.id === updatedOrder?.id
          );
          if (existingOrderIndex !== -1) {
            const updatedOrderList = [...orders];
            updatedOrderList[existingOrderIndex] = updatedOrder;
            dispatch(setOrder(updatedOrderList));
          }
        },
        error: (error: any) => {
          console.error("Subscription error:", error);
        },
      });

      return () => {
        if ("unsubscribe" in orderSubscription) {
          orderSubscription.unsubscribe();
        }
      };
    }
  }, [orders]);

  function handleOrderStatus(status: any): any {
    switch (status) {
      case OrderStatus.pending:
        return t("pending");
      case OrderStatus.review:
        return t("pending");
      case OrderStatus.cancelled:
        return t("cancelled");
      case OrderStatus.failed:
        return t("cancelled");
      case OrderStatus.updated:
        return t("updated");
      case OrderStatus.sentToKitchen:
        return t("sentToKitchen");
      case OrderStatus.outForDelivery:
        return t("outForDelivery");
      case OrderStatus.delivered:
        return t("Delivered");
      case OrderStatus.deliveredAndSettled:
        return t("Delivered");
      case OrderStatus.readyForPickup:
        return t("readyForPickup");
      case OrderStatus.settled:
        return t("pickedUp");
      // case OrderStatus.failed:
      //   return t("failed");
      default:
        return t("pending");
    }
  }

  return (
    <Grid>
      {isLoading ? (
        <MySkeleton />
      ) : (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "1rem" }}
        >
          <Grid item sm={12} lg={12} sx={{ direction: "ltr" }}>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={cancellation}
              autoHideDuration={3000}
              onClose={() => {
                setCancellation(false);
              }}
            >
              <Alert
                onClose={() => {
                  setCancellation(false);
                }}
                severity={"error"}
                sx={{
                  position: "fixed",
                  top: "16px",
                  right: "16px",
                }}
                action={
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                      setCancellation(false);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                Failed to Cancell Order
              </Alert>
            </Snackbar>
            <Link to="/">
              <Button
                sx={{
                  backgroundColor: "rgba(169, 169, 169, 0.55)",
                  color: "rgba(64, 64, 64, 0.7)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: " 2rem",
                  height: "2rem",
                  borderRadius: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                <ArrowBackIosNewIcon
                  sx={{
                    color: "#33BDE8",
                    fontSize: "1rem",
                    transform:
                      i18n.language === "Arabic" ? "rotate(180deg)" : "none",
                  }}
                />
              </Button>
            </Link>
          </Grid>
          <Grid item sm={12} lg={12} xs={12}>
            <Typography
              style={{
                fontSize: 24,
                fontWeight: "600",
                fontFamily: `bold${i18n.language}`,
                letterSpacing: 0.96,
              }}
            >
              {t("MyOrders")}
            </Typography>
          </Grid>

          <Grid container>
            <Grid container mb={5}>
              <InfiniteScroll
                dataLength={orders?.length || 0}
                next={fetchMoreOrders}
                style={{ display: "flex", flexDirection: "column" }} //To put endMessage and loader to the top.
                inverse={false} //
                hasMore={ordersNextToken && ordersNextToken.length > 0}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
                scrollThreshold={0.8}
              >
                {orders && orders?.length > 0 ? (
                  <Grid container>
                    {/* Render orders */}
                    {orders?.map((item: any, index: any) => {
                      return (
                        <Grid
                          container
                          key={item.id}
                          sx={{
                            backgroundColor: theme.palette.primary.dark,
                            borderRadius: "0.75rem",
                            marginY: "1rem",
                            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)", // Box shadow
                            position: "relative", // Ensure relative positioning for the pinned icon
                            overflow: "hidden",
                          }}
                        >
                          {/* the image */}
                          <Grid
                            item
                            sm={3}
                            xs={3}
                            onClick={() => handleOrderItemClick(item)}
                            sx={{
                              padding: "0.5rem",
                            }}
                          >
                            <Box
                              style={{
                                textDecoration: "none",
                                color: "#33BDE8",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={
                                  `${cloudinaryURL}${item?.orderedItems[0]?.image}` ||
                                  defaultImage
                                }
                                alt={item.name}
                                style={{
                                  width: "90%",
                                  borderRadius: "1rem",
                                  border: "0.1rem solid #33BDE8 ",
                                  aspectRatio: "1/1",
                                  objectFit: "cover",
                                }}
                              />
                            </Box>
                          </Grid>
                          {/* the info */}
                          <Grid
                            item
                            xs={9}
                            sx={{
                              padding: "1rem",
                              pr: i18n.language === "Arabic" ? 1 : 2,
                              pl: i18n.language === "Arabic" ? 2 : 1,
                            }}
                            onClick={() => handleOrderItemClick(item)}
                          >
                            <Typography
                              style={{
                                fontSize: 20,
                                color: theme.palette.primary.main,
                                fontWeight: 700,
                                fontFamily: `bold${i18n.language}`,
                                marginTop: 10,
                              }}
                              textTransform="capitalize"
                            >
                              {extractItemName({
                                item,
                              })}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#B3B3B3",
                                fontSize: 16,
                                fontFamily: "Roboto",
                              }}
                            >
                              {formatDate(item.createdAt, i18n)}
                            </Typography>
                            <Grid container justifyContent="space-between">
                              <Grid item sm={6}>
                                <Typography
                                  sx={{
                                    color: "#B3B3B3",
                                    fontSize: 16,
                                    fontFamily: `regular${i18n.language}`,
                                  }}
                                >
                                  {t("OrderID")}{" "}
                                  {capitalization(item.id.substring(0, 8))}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* The pinned icon */}

                          <Box
                            sx={{
                              position: "absolute",
                              top: "0",
                              maxWidth: "100%",
                              right: i18n.language === "Arabic" ? "auto" : "0",
                              left: i18n.language === "Arabic" ? "0" : "auto",
                              backgroundColor:
                                item.status ===
                                  OrderStatus.deliveredAndSettled ||
                                item.status === OrderStatus.settled ||
                                item.status === OrderStatus.delivered
                                  ? "#33BDE8"
                                  : "#FF69B4",
                              color: "#1F1D2B",
                              borderTopRightRadius:
                                i18n.language === "Arabic" ? 0 : ".75rem",
                              borderBottomLeftRadius:
                                i18n.language === "Arabic" ? 0 : ".75rem",
                              borderBottomRightRadius:
                                i18n.language === "Arabic" ? ".75rem" : 0,
                              borderTopLeftRadius:
                                i18n.language === "Arabic" ? ".75rem" : 0,
                              padding: "0.3rem 1rem ",
                              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                            }}
                          >
                            <Typography
                              sx={{ overflowWrap: "break-word" }}
                              fontWeight="bold"
                              fontSize={{
                                xs: 12,
                                sm: 14,
                                md: 16,
                              }}
                              textTransform="capitalize"
                            >
                              {handleOrderStatus(item.status)}
                            </Typography>
                          </Box>

                          {/* The pinned btn */}

                          <Box
                            sx={{
                              position: "absolute",
                              top: "50%",
                              right: i18n.language === "Arabic" ? "auto" : "0",
                              left: i18n.language === "Arabic" ? "0" : "auto",
                              bottom: "0",
                              color: "#33BDE8",
                              zIndex: 1,
                            }}
                            onClick={() => handleOrderItemClick(item)}
                          >
                            <ArrowForwardIosIcon
                              sx={{
                                transform:
                                  i18n.language === "Arabic"
                                    ? "rotate(180deg)"
                                    : "none",
                              }}
                            />
                          </Box>
                          {item.status === OrderStatus.review && (
                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ marginTop: "1rem", mb: "10px" }}
                            >
                              <Box display="flex">
                                <Box display="flex" alignItems="center">
                                  <LoadingButton
                                    variant="contained"
                                    type="submit"
                                    loading={isCancelling === item.id}
                                    disabled={Boolean(isCancelling)}
                                    color="error"
                                    sx={{
                                      textDecoration: "none",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      handleCancelOrder(item);
                                    }}
                                  >
                                    <CancelIcon
                                      sx={{
                                        marginRight:
                                          i18n.language === "Arabic"
                                            ? "0"
                                            : ".3rem",
                                        marginLeft:
                                          i18n.language === "Arabic"
                                            ? ".3rem"
                                            : "0",
                                      }}
                                    ></CancelIcon>
                                    <Typography>{t("cancelOrder")}</Typography>{" "}
                                  </LoadingButton>
                                </Box>
                              </Box>
                            </Grid>
                          )}
                          {(item.status === OrderStatus.deliveredAndSettled ||
                            item.status === OrderStatus.settled) && (
                            <Grid
                              item
                              sm={12}
                              xs={12}
                              sx={{ marginTop: "1rem" }}
                            >
                              <Box display="flex">
                                <Box display="flex" alignItems="center">
                                  <Button
                                    onClick={() => {
                                      handleReorderClick(item);
                                    }}
                                    style={{
                                      textDecoration: "none",
                                      color: "#33BDE8",
                                      cursor: "pointer",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <RefreshIcon
                                      sx={{
                                        marginRight: ".3rem",
                                        marginLeft: ".3rem",
                                      }}
                                    ></RefreshIcon>
                                    <Typography>{t("Reorder")}</Typography>
                                  </Button>
                                </Box>
                                <Box sx={{ marginLeft: "1rem" }}>
                                  <Button
                                    onClick={() => {
                                      if (item.review) return;
                                      handleReviewClick(item);
                                    }}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <StarIcon
                                      sx={{
                                        color: "#FFD600",
                                        marginRight: ".3rem",
                                        marginLeft: ".3rem",
                                      }}
                                    ></StarIcon>
                                    <Typography>
                                      {item.review
                                        ? (new Map(
                                            JSON.parse(
                                              item.review.multiLanguages
                                            )
                                          ).get(
                                            `${i18n.language}-satisfaction`
                                          ) as string)
                                        : t("RateOrder")}
                                    </Typography>
                                  </Button>
                                </Box>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                ) : (
                  // Display a message when no orders are present
                  <Grid
                    sx={{
                      backgroundColor: theme.palette.primary.dark,
                      borderRadius: "0.3rem",
                      marginY: "1rem",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "3rem 5rem",
                      width: "100%",
                      boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)", // Box shadow
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        style={{
                          textAlign: "center",
                          color: theme.palette.primary.main,
                          fontSize: 18,
                          fontFamily: `bold${i18n.language}`,
                          marginTop: "1rem",
                        }}
                      >
                        <WidgetsIcon
                          sx={{ color: theme.palette.primary.main }}
                        />{" "}
                        {t("SorryNoOrders")}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </InfiniteScroll>
            </Grid>
            {/* </Link> */}
          </Grid>
        </Grid>
      )}
      {!showReview && <Navbar />}
      {showReview && (
        <div className="overlay">
          <Review onClose={closeReviewForm} />
        </div>
      )}
    </Grid>
  );
}

export default OrderHistory;
