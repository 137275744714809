import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  IconButton,
  Grid,
  Typography,
  useTheme,
  Button,
  Snackbar,
  Alert,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { resetPassword } from "aws-amplify/auth";

type ForgetPasswordForm = {
  phoneNumber: string;
  password: string;
};

function ForgetPassword() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user);
  const [message, setMessage] = useState<any>("");
  const [validationWarning, setValidationWarning] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

   const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<ForgetPasswordForm>({}); // Specify the form data type

   const onSubmit = async (data: ForgetPasswordForm) => {
    try {
      setLoading(true)
      let input: any = {
        username: "+2" + data.phoneNumber,
      }
      const output = await resetPassword({ username: input.username });
      navigate("/resetPass", { state: { username: input.username, codeDeliveryDetails: output.nextStep.codeDeliveryDetails } }); 
    } catch (error: any) {
      const errMessage = error.name === "UserNotFoundException" ? t("userNotExist") : t("Attempt limit exceeded, please try after some time.");
      setMessage(errMessage);
      setValidationWarning(true)
      setLoading(false)
      console.error("Error submitting user details:", error);
    }
  };

   const handleInputBlur = (fieldName: any) => {
    trigger(fieldName);
  };

  useEffect(() => { 
    if (user) {
      navigate('/profile');
    }
  }, [user]);
  
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ padding: "1rem" }}
    >
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={validationWarning}
            autoHideDuration={3000}
            onClose={()=>{ setValidationWarning(false)}}
          >
            <Alert
            onClose={()=>{ setValidationWarning(false)}}
            severity="warning"
              sx={{
                position: "fixed",
                top: "16px",
                right: "16px",
              }}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={()=>{setValidationWarning(false)}}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              {message}
            </Alert>
      </Snackbar>
      
      <Grid item sm={12} lg={12}>
        <Link to="/login">
          <Button
            sx={{
              backgroundColor: "rgba(169, 169, 169, 0.55)",
              color: "rgba(64, 64, 64, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: " 2rem",
              height: "2rem",
              borderRadius: "0.5rem",
              marginBottom: "1rem",
            }}
          >
            <ArrowBackIosNewIcon
              sx={{ color: "#33BDE8", fontSize: "1rem",transform: i18n.language === 'Arabic' ? 'rotate(180deg)' : 'none' }}
            />
          </Button>
        </Link>
      </Grid>
      <Grid item sm={12} lg={12} xs={12}>
        <Typography
          style={{
            color:theme.palette.primary.main,
            fontSize: 24,
            fontWeight: "600",
            letterSpacing: 0.96,
          }}
        >
        {t("Forget Password")}
        </Typography>
      </Grid>
       <Grid container sx={{ marginX: "1.5rem", justifyContent:'center', alignItems:'center', height:'70vh' }} >
          <form style={{width:'100%'}} onSubmit={handleSubmit(onSubmit)}>
          <Grid item sm={12} xs={12} mb={2}>
            <TextField
              variant="outlined"
              placeholder={t("phoneNumber")}
              fullWidth
              {...register("phoneNumber", {
                required: t("PhonRequired"),
                pattern: {
                  value: /^[0-9]{11}$/,
                  message: t("InvalidPhoneFormat"),
                },
              })}
              onBlur={() => handleInputBlur("phoneNumber")}
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                  color: "black",
                  borderRadius: "1rem",
                  width: "100%",
                  margin: "0.5rem auto",
                  borderColor: "#ffffff",
                  fontFamily: `regular${i18n.language}`
                },
              }}
            />
            {errors.phoneNumber && (
              <span style={{ color: "red",fontSize:12 }}>{errors.phoneNumber.message}</span>
            )}
        </Grid>
         <Button
            variant="contained"
            type="submit"
            disabled={loading}
            sx={{
              font: "0.3rem",
              fontFamily: `bold${i18n.language}`,
              width: "100%",
              color: "#FFFFFF",
              background:
                "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
              boxShadow: "none",
              transition: "box-shadow 0.3s",
              borderRadius: "1rem",
              padding: "1rem",
              "&:hover": {
                boxShadow: "0px 0px 5px 2px rgb(240,126,165,0.7)",
                background:
                  "linear-gradient(90deg, rgba(240,126,165,1) 16%, rgba(51,189,232,1) 70%)",
              },
            }}
          >
           {t("Forget Password")}
          </Button>
    </form>
        </Grid>
    </Grid>
  )
}

export default ForgetPassword
