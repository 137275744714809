import { Grid } from "@mui/material";
import { useEffect, useRef } from 'react';

const SplashScreen = () => {
    const videoRef:any = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        videoRef.current.defaultMuted = true;
       // videoRef.current.play()
    }, []); 

    return (
        <Grid
            item
            container
            sm={12}
            justifyContent="center"
            alignItems="flex-end"
            sx={{
                height: "100vh",
            }}
        >
            <video
                ref={videoRef}
                autoPlay
                muted
                width={"100%"}
                height={"100%"}
                playsInline
                loop
            >
                <source src={"./assets/v1.mp4"} type="video/mp4" />
            </video>
        </Grid>
    );
};

export default SplashScreen;
