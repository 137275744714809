import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { InputAdornment, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface SearchInputProps {
  handleSearch: any;
}

const SearchInput = ({ handleSearch }: SearchInputProps) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  const handleClearSearch = () => {
    setSearchValue("");
    handleSearch(""); // Clear the search by passing an empty string
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      p={1}
      sx={{
        width: "100%",
        borderRadius: "5px",
      }}
    >
      <TextField
        id="outlined-basic"
        variant="outlined"
        placeholder={t("Search")}
        fullWidth
        value={searchValue}
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.main,
            borderRadius: "1rem",
            width: "100%",
            margin: "0",
            borderColor: theme.palette.primary.main,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          },
          "& input": {
            "&::placeholder": {
              color: theme.palette.primary.main,
            },
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#CFCFCF" ,marginX:"0.3rem" }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchValue && (
                <IconButton
                aria-label="clear search"
                  onClick={handleClearSearch}
                  size="large"
                >
                  <ClearIcon sx={{ color: "#CFCFCF" }} />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          const { value } = e.target;
          setSearchValue(value);
          handleSearch(value);
        }}
        />
    </Box>
  );
};

export default SearchInput;
